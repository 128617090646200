import * as React from "react";
import { Flex, Box } from "reflexbox";

// Markets
import nftkey from "../../assets/market/nftkey.png";
import land from "../../assets/market/land.png";
import scv from "../../assets/market/scv.png";
import pixel from "../../assets/market/pixelmart.png";
import tofu from "../../assets/market/tofu.png";
import luqiud from "../../assets/market/liquid.png";

// Pepes
import jailPepe1 from "../../assets/jailPepes/1.png";
import jailPepe2 from "../../assets/jailPepes/2.png";

// Styles
import Styles from "./styles";

const DistributionEnd: React.FC = () => (
  <Styles.Container>
    <Styles.Title>Initial PEPE Offering is Over</Styles.Title>
    <Flex
      flexWrap="wrap"
      width="100%"
      pt={["60px", "60px", "40px", "40px"]}
      alignItems="center"
    >
      <Box width={[1, 1, 2 / 3, 2 / 3]}>
        <Styles.MarketplaceLabel>
          Get Your Neural Pepe on the Marketplace
        </Styles.MarketplaceLabel>
        <Styles.Markets>
          <Styles.Link
            href="https://nftkey.app/collections/neuralpepe/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.Market src={nftkey} alt="image" />
          </Styles.Link>

          <Styles.Link
            href="https://pixelmart.app/projects/neural-pepe"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.Market src={pixel} alt="image" />
          </Styles.Link>

          <Styles.Link
            href="https://tofunft.com/ru/collection/neuralpepe-v2/items"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.Market src={tofu} alt="image" />
          </Styles.Link>

          <Styles.Link
            href="https://liquidcollectibles.io/collection/0x012f90e777bdb2b4ca132f0f6eb9e7959075e9b2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.Market src={luqiud} alt="image" />
          </Styles.Link>

          <Styles.Link
            href="https://treasureland.market/collection/0x012f90E777bdb2B4CA132f0f6EB9e7959075E9b2?chain_id=56"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.Market src={land} alt="image" />
          </Styles.Link>
        </Styles.Markets>
      </Box>
      <Box width={[1, 1, 1 / 3, 1 / 3]}>
        <Styles.JailRow>
          <Styles.JailHeading>
            <Styles.PepeJail src={jailPepe1} alt="pepe" />
            <Styles.JailTitle>Neural Jail</Styles.JailTitle>
            <Styles.PepeJail2 src={jailPepe2} alt="pepe" />
          </Styles.JailHeading>
          <Styles.SoonButton
            href="https://neuralfrens.com/jail"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.SoonButtonTitle>explore</Styles.SoonButtonTitle>
          </Styles.SoonButton>
          <Styles.JailText>
            All NPs unsold during IPO have been transported to Neural Jail. It
            is possible to rescue NPs from jail by paying 1.5 BNB. Or you can
            swap your own NP for a jailed one in exchange for 1000 AI.
          </Styles.JailText>
        </Styles.JailRow>
      </Box>
    </Flex>
  </Styles.Container>
);

export default DistributionEnd;
