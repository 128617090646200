/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useWallet } from "bscuw";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";

// Styles
import Styles from "./styles";

const About: React.FC = () => {
    return (
        <Styles.Wrapper>
            <Navbar />
            <Styles.Main>
                <Container>
                    <Styles.Row>
                        <Styles.Pepe1 />
                        <Styles.Pepe2 />
                        <Styles.Pepe3 />
                        <Styles.Pepe4 />
                        <Styles.Pepe5 />
                        <Styles.Pepe6 />
                        <Styles.Pepe7 />
                        <Styles.Pepe8 />

                        <Styles.TitleRow>
                            <Styles.Title>ABOUT</Styles.Title>
                            <Styles.Title2>ABOUT</Styles.Title2>
                        </Styles.TitleRow>

                        <Styles.Description>
                            Neural Pepe is an NFT art collectible project based
                            on Binance Smart Chain and the first project in the
                            Neural Meme family. It consists of 7,777 unique
                            tokenized images of Pepe the Frog generated with a
                            neural network. By holding a Neural Pepe, you
                            accumulate k̶e̶k̶s̶ the AI token on a daily basis, which
                            allows you to choose a name for your Pepe and vote
                            for the next Neural Meme collection.
                            <br />
                            <br />
                            <br />
                            Neural Pepes were generated using the StyleGAN2
                            generative adversarial network. The dataset
                            consisted of over 7,000 different Pepe the Frog
                            arts. The final collection includes models of
                            different iterations to make the result more
                            diverse. Each Neural Pepe NFT has a set of traits of
                            different rarity. All traits were assigned using
                            custom-made classifiers and then double-checked
                            manually..
                        </Styles.Description>

                        <Styles.Link
                            href="https://docs.neuralpepe.com/how-it-works"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <Styles.Button>
                                <Styles.ButtonTitle>
                                    read docs
                                </Styles.ButtonTitle>
                            </Styles.Button>
                        </Styles.Link>
                    </Styles.Row>
                </Container>
            </Styles.Main>
            <Footer />
        </Styles.Wrapper>
    );
};

export default About;
