/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Flex, Box } from "reflexbox";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useWallet } from "bscuw";
import dayjs from "dayjs";
import queryString from "query-string";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Pepes from "../../components/Pepes";
import Footer from "../../components/Footer";

// Modals
import ConnectWalletModal from "../../modals/ConnectWallet";
import MarketplaceModal from "../../modals/Marketplace";

// Blockchain
import {
    getPepeOwner,
    getPepeName,
    getTotalSupply,
} from "../../blockchain/utils/index";

// Api
import {
    getPepeNamingHistory,
    IPepeNamingHistory,
    getPepeInfo,
    IPepeInfo,
    IPepeAttribute,
    getNextPepe,
    getPrevPepe,
} from "../../utils/api";

// Styles
import Styles from "./styles";

interface IUseParams {
    pepeIndex: string;
}

interface IUseLocation {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: any;
}

const Pepe: React.FC = () => {
    const { pepeIndex } = useParams<IUseParams>();
    const history = useHistory();
    const location = useLocation<IUseLocation>();

    const { status } = useWallet();

    const [pepesIndex] = pepeIndex.split("?");

    const [ownerAddress, setOwnerAddress] = React.useState<string>("");
    const [namingHistory, setNamingHistory] = React.useState<
        IPepeNamingHistory[]
    >([]);
    const [pepeName, setPepeName] = React.useState<string | null>(null);
    const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
    const [activeModal, setActiveModal] = React.useState<
        null | "connectWallet" | "marketplace"
    >(null);
    const [pepeInfo, setPepeInfo] = React.useState<null | IPepeInfo>(null);
    const [supply, setSupply] = React.useState<number>(0);
    const [arrowSpinner, setArrowSpinner] = React.useState<
        null | "left" | "right"
    >(null);

    const isWalletConnected = status === "connected";

    React.useEffect(() => {
        onGetTotalSupply();
    }, []);

    React.useEffect(() => {
        onGetPepeOwner();
        onGetPepeInfo();
    }, [pepesIndex]);

    React.useEffect(() => {
        if (isWalletConnected && activeModal === "connectWallet") {
            setActiveModal(null);
        }
    }, [isWalletConnected, activeModal]);

    React.useEffect(() => {
        if (ownerAddress?.length) {
            onGetPepeName();
            onGetPepeNamingHistory();
        }
    }, [ownerAddress, pepesIndex]);

    const onGetTotalSupply = async (): Promise<void> => {
        const data = await getTotalSupply();
        setSupply(data);
    };

    const onGetPepeNamingHistory = async () => {
        setNamingHistory([]);

        const history = await getPepeNamingHistory(Number(pepesIndex));
        setNamingHistory(history);
    };

    const onGetPepeOwner = async () => {
        const data = await getPepeOwner(Number(pepesIndex));
        if (data) {
            setOwnerAddress(data);
        } else {
            setIsNotFound(true);
        }
    };

    const onGetPepeName = async () => {
        setPepeName("");
        const data = await getPepeName(Number(pepesIndex));
        setPepeName(data);
    };

    const onGetPepeInfo = async () => {
        const data = await getPepeInfo(pepesIndex);

        if (data) {
            setPepeInfo(data);
        }
    };

    const getAttrubete = (key: string): string | null | undefined => {
        if (pepeInfo) {
            return pepeInfo.attributes.find(
                (item: IPepeAttribute) => item.trait_type === key
            )?.value;
        }
        return null;
    };

    const openMarketplace = (link: string): void => {
        window.open(`${link}${pepesIndex}`, "_blank")?.focus();
    };

    const setPrevious = async () => {
        if (location.search) {
            setArrowSpinner("right");
            const parseLocation = queryString.parse(location.search);
            const data = await getPrevPepe(pepesIndex, parseLocation);

            setArrowSpinner(null);

            if (data) {
                return history.push(`/pepe/${data}${location.search}`, {
                    from: "pepe",
                });
            }
        }

        const prevIndex =
            Number(pepesIndex) === 0 ? supply - 1 : Number(pepesIndex) - 1;
        return history.push(`/pepe/${prevIndex}`, {
            from: "pepe",
        });
    };

    const setNext = async () => {
        if (location.search) {
            setArrowSpinner("right");
            const parseLocation = queryString.parse(location.search);
            const data = await getNextPepe(pepesIndex, parseLocation);

            setArrowSpinner(null);

            if (data) {
                return history.push(`/pepe/${data}${location.search}`, {
                    from: "pepe",
                });
            }
        }

        const nextIndex =
            Number(pepesIndex) === supply - 1 ? 0 : Number(pepesIndex) + 1;
        return history.push(`/pepe/${nextIndex}`, {
            from: "pepe",
        });
    };

    const onBack = () => {
        // @ts-ignore
        const getFromPage = location.state?.from;

        if (getFromPage === "explore-all" || getFromPage === "pepe") {
            history.goBack();
        } else {
            history.push("/explore-all");
        }
    };

    return (
        <Styles.Wrapper>
            <Navbar />
            <Styles.Row>
                <Container>
                    {isNotFound ? (
                        <Styles.PepeCardRow>
                            <Styles.PepeCard>
                                <Styles.NotFoundRow>
                                    <Styles.NotFoundLabel>
                                        NEURAL PEPE <br /> not found
                                    </Styles.NotFoundLabel>
                                </Styles.NotFoundRow>
                            </Styles.PepeCard>
                        </Styles.PepeCardRow>
                    ) : (
                        <Styles.Content>
                            <Styles.PepeArrow
                                direction="left"
                                onClick={setPrevious}
                            />
                            <Styles.PepeCardRow>
                                <Styles.PepeCard>
                                    <Styles.Heading>
                                        <Styles.Title>NEURAL PEPE</Styles.Title>
                                        <Styles.Owner>
                                            owned by:{" "}
                                            {ownerAddress || "??????????????"}
                                        </Styles.Owner>
                                    </Styles.Heading>
                                    <Flex flexWrap="wrap" width="100%">
                                        <Box
                                            width={[1, 1, 1 / 3, 1 / 3]}
                                            pr={[0, 0, 45, 45]}
                                        >
                                            <Styles.PepesCoverRow>
                                                <Pepes
                                                    width={216}
                                                    height={216}
                                                    number={Number(pepesIndex)}
                                                />
                                                {pepeInfo?.image_ipfs ? (
                                                    <Styles.PepesLink
                                                        href={
                                                            pepeInfo?.image_ipfs
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View on IPFS
                                                    </Styles.PepesLink>
                                                ) : null}
                                            </Styles.PepesCoverRow>
                                        </Box>
                                        <Box
                                            width={[1, 1, 2 / 3, 2 / 3]}
                                            pl={[0, 0, 45, 45]}
                                        >
                                            <Styles.PepeNameRow>
                                                <Styles.PepeNameLabel>
                                                    Name:
                                                </Styles.PepeNameLabel>
                                                <Styles.PepeName>
                                                    {pepeName === null
                                                        ? "?????????"
                                                        : pepeName}
                                                </Styles.PepeName>
                                            </Styles.PepeNameRow>

                                            <Styles.TraitsLabel>
                                                Traits
                                            </Styles.TraitsLabel>

                                            <Flex
                                                width="100%"
                                                flexWrap="wrap"
                                                pt={28}
                                            >
                                                <Box
                                                    width={[1, 1, 1 / 3, 1 / 3]}
                                                    pr={[0, 0, 15, 15]}
                                                    pb={16}
                                                >
                                                    <Styles.TraitsList>
                                                        <Styles.ListTitle
                                                            length={
                                                                pepeInfo
                                                                    ? getAttrubete(
                                                                          "iteration"
                                                                      )?.length
                                                                    : 0
                                                            }
                                                        >
                                                            {pepeInfo
                                                                ? getAttrubete(
                                                                      "iteration"
                                                                  )
                                                                : "?????"}
                                                        </Styles.ListTitle>
                                                        <Styles.ListText>
                                                            Iteration
                                                        </Styles.ListText>
                                                    </Styles.TraitsList>
                                                </Box>
                                                <Box
                                                    width={[1, 1, 1 / 3, 1 / 3]}
                                                    pl={[0, 0, 15, 15]}
                                                    pr={[0, 0, 15, 15]}
                                                    pb={16}
                                                >
                                                    <Styles.TraitsList>
                                                        <Styles.ListTitle
                                                            length={
                                                                pepeInfo
                                                                    ? getAttrubete(
                                                                          "eyes"
                                                                      )?.length
                                                                    : 0
                                                            }
                                                        >
                                                            {pepeInfo
                                                                ? getAttrubete(
                                                                      "eyes"
                                                                  )
                                                                : "?????"}
                                                        </Styles.ListTitle>
                                                        <Styles.ListText>
                                                            Eyes
                                                        </Styles.ListText>
                                                    </Styles.TraitsList>
                                                </Box>
                                                <Box
                                                    width={[1, 1, 1 / 3, 1 / 3]}
                                                    pl={[0, 0, 15, 15]}
                                                    pb={16}
                                                >
                                                    <Styles.TraitsList>
                                                        <Styles.ListTitle
                                                            length={
                                                                pepeInfo
                                                                    ? getAttrubete(
                                                                          "color"
                                                                      )?.length
                                                                    : 0
                                                            }
                                                        >
                                                            {pepeInfo
                                                                ? getAttrubete(
                                                                      "color"
                                                                  )
                                                                : "?????"}
                                                        </Styles.ListTitle>
                                                        <Styles.ListText>
                                                            Color
                                                        </Styles.ListText>
                                                    </Styles.TraitsList>
                                                </Box>
                                                <Box
                                                    width={[1, 1, 1 / 3, 1 / 3]}
                                                    pr={[0, 0, 15, 15]}
                                                    pb={[16, 16, 0, 0]}
                                                >
                                                    <Styles.TraitsList>
                                                        <Styles.ListTitle
                                                            length={
                                                                pepeInfo
                                                                    ? getAttrubete(
                                                                          "background_color"
                                                                      )?.length
                                                                    : 0
                                                            }
                                                        >
                                                            {pepeInfo
                                                                ? getAttrubete(
                                                                      "background_color"
                                                                  )
                                                                : "?????"}
                                                        </Styles.ListTitle>
                                                        <Styles.ListText>
                                                            Background
                                                        </Styles.ListText>
                                                    </Styles.TraitsList>
                                                </Box>
                                                <Box
                                                    width={[1, 1, 1 / 3, 1 / 3]}
                                                    pr={[0, 0, 15, 15]}
                                                    pl={[0, 0, 15, 15]}
                                                    pb={[16, 16, 0, 0]}
                                                >
                                                    <Styles.TraitsList>
                                                        <Styles.ListTitle
                                                            length={
                                                                pepeInfo
                                                                    ? getAttrubete(
                                                                          "special"
                                                                      )?.length
                                                                    : 0
                                                            }
                                                        >
                                                            {pepeInfo
                                                                ? getAttrubete(
                                                                      "special"
                                                                  )
                                                                : "?????"}
                                                        </Styles.ListTitle>
                                                        <Styles.ListText>
                                                            Special
                                                        </Styles.ListText>
                                                    </Styles.TraitsList>
                                                </Box>
                                                <Box
                                                    width={[1, 1, 1 / 3, 1 / 3]}
                                                    pl={[0, 0, 15, 15]}
                                                >
                                                    <Styles.TraitsList>
                                                        <Styles.ListTitle
                                                            length={
                                                                pepeInfo
                                                                    ? getAttrubete(
                                                                          "kek"
                                                                      )?.length
                                                                    : 0
                                                            }
                                                        >
                                                            {pepeInfo
                                                                ? getAttrubete(
                                                                      "kek"
                                                                  )
                                                                : "?????"}
                                                        </Styles.ListTitle>
                                                        <Styles.ListText>
                                                            Kek
                                                        </Styles.ListText>
                                                    </Styles.TraitsList>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Styles.PepeCard>
                            </Styles.PepeCardRow>
                            {arrowSpinner === "right" ? (
                                <Styles.Spinner direction="right" />
                            ) : (
                                <Styles.PepeArrow
                                    direction="right"
                                    onClick={setNext}
                                />
                            )}
                        </Styles.Content>
                    )}

                    {namingHistory.length ? (
                        <Styles.NamingHistoryRow>
                            <Styles.NamingHistoryLabel>
                                Naming history
                            </Styles.NamingHistoryLabel>

                            <Styles.NamingHistoryBlock>
                                {namingHistory.map(
                                    (history: IPepeNamingHistory) => {
                                        const { name, created, is_minted } =
                                            history;

                                        return (
                                            <Styles.ChangeList key={name}>
                                                <Styles.LatestName>
                                                    {name}
                                                </Styles.LatestName>
                                                <Styles.HistoryInfo>
                                                    <Styles.ChangeDate>
                                                        {dayjs(created).format(
                                                            "YYYY-MM-DD"
                                                        )}
                                                    </Styles.ChangeDate>
                                                    {is_minted ? (
                                                        <Styles.ChangeDate>
                                                            (minted)
                                                        </Styles.ChangeDate>
                                                    ) : null}
                                                </Styles.HistoryInfo>
                                            </Styles.ChangeList>
                                        );
                                    }
                                )}
                            </Styles.NamingHistoryBlock>
                        </Styles.NamingHistoryRow>
                    ) : null}

                    <Styles.Actions>
                        <Styles.Button onClick={onBack}>
                            <Styles.ButtonTitle>back</Styles.ButtonTitle>
                        </Styles.Button>
                        <Styles.Button
                            onClick={() => setActiveModal("marketplace")}
                        >
                            <Styles.ButtonTitle>
                                view on marketplace
                            </Styles.ButtonTitle>
                        </Styles.Button>
                    </Styles.Actions>
                </Container>
            </Styles.Row>
            <Footer />

            <ConnectWalletModal
                isOpen={activeModal === "connectWallet"}
                onClose={() => setActiveModal(null)}
            />
            <MarketplaceModal
                isOpen={activeModal === "marketplace"}
                onClose={() => setActiveModal(null)}
                openMarketplace={openMarketplace}
            />
        </Styles.Wrapper>
    );
};

export default Pepe;
