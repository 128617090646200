import styled from "styled-components";

import spinner from "../../assets/spinner.svg";

const Wrapper = styled.div`
  padding: 40px 0 0 0;

  @media (min-width: 1024px) {
    padding: 60px 170px 0 170px;
  }
`;

const Container = styled.div`
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  padding: 10px 5px;

  @media (min-width: 1024px) {
    padding: 9px;
  }
`;

const Row = styled.div`
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  padding: 20px 25px;

  @media (min-width: 1024px) {
    padding: 25px 35px 35px 35px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeadingTitle = styled.h3`
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);
  white-space: pre;

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 37px;
  }
`;

const WalletBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin: 0 0 0 40px;
`;

const WalletLogo = styled.div`
  background-color: #ffffff;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 13px;

  @media (min-width: 1024px) {
    width: 43px;
    min-width: 43px;
    height: 43px;
    border-radius: 22px;
  }
`;

const WalletAddress = styled.p`
  margin: 0 0 0 10px;
  font-size: 10px;
  line-height: 12px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-overflow: ellipsis;
  overflow: hidden;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 0 16px;
  }
`;

const PepesNameRow = styled.div`
  display: flex;
  margin: 18px 0 0 0;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
`;

const NameLabel = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const PepesName = styled.h3`
  margin: 0 0 0 16px;
  color: #ff9292;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  font-size: 15px;
  line-height: 19px;

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 45px;
  }
`;

const TraitsRow = styled.div`
  margin: 31px 0 0 0;
`;

const Traits = styled.h1`
  margin: 0;
  font-size: 35px;
  line-height: 43px;
  text-align: center;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const TraitsBlock = styled.div`
  border: 2px solid #868686;
  box-sizing: border-box;
  filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
  padding: 9px 19px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  @media (min-width: 1024px) {
    padding: 17px 0;
    flex-direction: column;
  }
`;

const TraitsValue = styled.h3`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  flex: 1;
  text-align: center;
`;

const TraitsTitle = styled.p`
  margin: 2px 0 0 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  flex: 1;
  text-align: center;
`;

const RenamePepeTitle = styled.h3`
  margin: 30px 30px 0 30px;
  text-align: center;
  font-size: 23px;
  line-height: 29px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);
`;

const CurrentPepeName = styled.p`
  margin: 16px 0 0 0;
  font-size: 14px;
  line-height: 17px;
  color: #b4bdb2;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const PepeActiveName = styled.p`
  margin: 9px 0 0 0;
  font-size: 23px;
  line-height: 29px;
  color: #ff6464;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const RenameForm = styled.div`
  margin: 18px 0 0 0;
`;

const NewNameLabel = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const RenameInput = styled.input`
  margin: 18px 0 0 0;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  height: 55px;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  color: #d5ffca;
  outline: none;
  padding: 0 22px;

  ::placeholder {
    color: #d5ffca;
    opacity: 1;
  }
`;

const ErrorRenameText = styled.p`
  margin: 9px 0 0 0;
  font-size: 13px;
  line-height: 16px;
  color: #ff3c5f;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-align: center;
`;

const RenameButton = styled.div`
  margin: 35px 0 0 0;
  background: rgba(110, 215, 108, 0.72);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const RenameButtonLabel = styled.p`
  text-align: center;
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const ClaimTitle = styled.h1`
  font-size: 22px;
  line-height: 80px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: block;
  margin: 0;
  width: 100%;
  text-align: center;
`;

const Earned = styled.h1`
  margin: 0 0 20px 0;
  width: 100%;
  text-align: center;
  font-size: 47px;
  line-height: 80px;
  text-align: center;
  color: #ff5c5c;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const ClaimStatsList = styled.div`
  margin: 70px 0 0 0;
  width: 100%;
`;

const ClaimStats = styled.p`
  margin: 0 0 6px 0;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const ClaimStateValue = styled.span`
  color: #ff5c5c;
`;

const HarvestForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  position: relative;
`;

const HarvestErrorLabel = styled.p`
  margin: 0 0 0 22px;
  font-size: 18px;
  line-height: 22px;
  color: #ff5c5c;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  position: absolute;
  top: 70px;
`;

const Spinner = styled.div`
  width: 22px;
  height: 22px;
  margin: 0 auto;
  background-image: url(${spinner});
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 1024px) {
    width: 45px;
    height: 45px;
  }
`;

const PepesCoverRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PepesLink = styled.a`
  margin: 20px 0 0 0;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TransferTitle = styled.h3`
  margin: 30px 0 0 0;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  width: 100%;

  @media (min-width: 1024px) {
    margin: 48px 0 0 0;
    font-size: 36px;
    line-height: 45px;
  }
`;

const Styles = {
  Wrapper,
  Container,
  Row,
  Heading,
  HeadingTitle,
  WalletBlock,
  WalletLogo,
  WalletAddress,
  PepesNameRow,
  NameLabel,
  PepesName,
  TraitsRow,
  Traits,
  TraitsBlock,
  TraitsValue,
  TraitsTitle,
  RenamePepeTitle,
  CurrentPepeName,
  PepeActiveName,
  RenameForm,
  NewNameLabel,
  RenameInput,
  ErrorRenameText,
  RenameButton,
  RenameButtonLabel,
  ClaimTitle,
  Earned,
  ClaimStatsList,
  ClaimStats,
  ClaimStateValue,
  HarvestForm,
  HarvestErrorLabel,
  Spinner,
  PepesCoverRow,
  PepesLink,
  TransferTitle,
};

export default Styles;
