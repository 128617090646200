import * as React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

// Logos
import nftkeyLogo from "../../assets/other/nftkey.svg";

// Styles
import Styles from "./styles";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    openMarketplace: (link: string) => void;
}

interface IMarketplace {
    name: string;
    link: string;
    logo: string;
}

const MarketplaceModal: React.FC<Props> = (props) => {
    const { isOpen, onClose, openMarketplace } = props;

    const marketplaces: IMarketplace[] = [
        {
            name: "nftkey",
            link: "https://nftkey.app/collections/neuralpepe/token-details/?tokenId=",
            logo: nftkeyLogo,
        },
    ];

    return (
        <Modal open={isOpen} onClose={onClose} center showCloseIcon={false}>
            <Styles.Wrapper>
                <Styles.Heading>
                    <Styles.Title>CHOOSE MARKETPLACE</Styles.Title>
                    <Styles.CloseIcon onClick={onClose}>X</Styles.CloseIcon>
                </Styles.Heading>

                <Styles.Body>
                    {marketplaces.map((marketplace: IMarketplace) => {
                        const { name, logo, link } = marketplace;

                        return (
                            <Styles.Marketplace
                                key={name}
                                onClick={() => openMarketplace(link)}
                            >
                                <Styles.MarketplaceLogo src={logo} alt="logo" />
                            </Styles.Marketplace>
                        );
                    })}

                    <Styles.SoonButton>
                        <Styles.SoonButtonLabel>
                            coming soon
                        </Styles.SoonButtonLabel>
                    </Styles.SoonButton>
                </Styles.Body>
            </Styles.Wrapper>
        </Modal>
    );
};

export default MarketplaceModal;
