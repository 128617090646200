import styled from "styled-components";

import sign from "../../assets/other/sign.png";

const Wrapper = styled.section`
  margin: 98px 0 0 0;

  @media (min-width: 1024px) {
    margin: 103px 0 0 0;
  }
`;

const PepesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Pepes = styled.img`
  width: 202px;
  height: 202px;
`;

const PepeNumber = styled.p`
  margin: 16px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #d5ffca;
  transform: rotate(-2.15deg);
`;

const Quote = styled.q`
  margin: 35px 80px 0 80px;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: #d5ffca;
  width: auto;
  display: table;

  @media (min-width: 1024px) {
    margin: 20px 80px 0 80px;
    font-size: 30px;
    line-height: 44px;

    &::before,
    &::after {
      font-size: 48px;
      line-height: 40px;
    }
  }
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
  color: #3b97ff;
`;

const QuoteRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const QuoteIcon = styled.div`
  width: 22px;
  height: 22px;
  margin: 0 10px 0 0;
  background-image: url(${sign});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Styles = {
  Wrapper,
  PepesBlock,
  Pepes,
  PepeNumber,
  Quote,
  Text,
  QuoteRow,
  QuoteIcon,
};

export default Styles;
