import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import UpdatesCard from "../../components/UpdatesCard";

// Assets
import preloaderImage from "../../assets/gif/preloader.gif";

// Api
import { getUpdates, IUpdate } from "../../utils/api";

// Hooks
import usePrevious from "../../hooks/usePrevious";

// Styles
import Styles from "./styles";

interface IFilter {
  title: string;
  key: string;
}

const filters: IFilter[] = [
  {
    title: "Generation",
    key: "generation",
  },
  {
    title: "Renaming",
    key: "renaming",
  },
  {
    title: "Sales",
    key: "sales",
  },
  {
    title: "Listing",
    key: "listing",
  },
  {
    title: "TOP-15 market sales",
    key: "top-sales",
  },
];

const LIMIT = 20;

const Updated: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isFiltersVisible, setFiltersVisible] = React.useState<boolean>(false);
  const [updates, setUpdates] = React.useState<IUpdate[]>([]);
  const [isCanLoadMore, setCanLoadMore] = React.useState<boolean>(true);
  const [activeFilter, setActiveFilter] = React.useState<string | null>(null);
  const [total, setTotal] = React.useState<number>(0);
  const [offset, setOffset] = React.useState<number>(0);
  const [isLoadingMore, setLoadingMore] = React.useState<boolean>(false);
  const [isInitialInit, setInitialInit] = React.useState<boolean>(false);

  const prevActiveFilter = usePrevious(activeFilter);

  React.useEffect(() => {
    loadUpdates();
  }, []);

  React.useEffect(() => {
    if (activeFilter || prevActiveFilter) {
      setOffset(0);
      loadUpdates();
    }
  }, [activeFilter, prevActiveFilter]);

  const loadUpdates = async (): Promise<void> => {
    setIsLoading(true);
    setUpdates([]);

    const data = await getUpdates(0, activeFilter);

    setIsLoading(false);

    if (!isInitialInit) {
      setInitialInit(true);
    }

    if (data) {
      setUpdates(data.list);
      setTotal(data.total);
    }
  };

  const toggleFilters = (): void => {
    document.body.style.overflow = isFiltersVisible ? "scroll" : "hidden";

    if (!isFiltersVisible) {
      window.scrollTo(0, 0);
    }

    setFiltersVisible(!isFiltersVisible);
  };

  const onResetFilters = (): void => {
    setActiveFilter(null);
  };

  const onShowMore = async (): Promise<void> => {
    setLoadingMore(true);

    const data = await getUpdates(offset + LIMIT, activeFilter);

    setLoadingMore(false);

    if (data?.list.length) {
      setUpdates([...updates, ...data.list]);
      setOffset(offset + LIMIT);
    } else {
      setCanLoadMore(false);
    }
  };

  const renderTelegramBlock = () => (
    <Styles.TelegramBlock>
      <Styles.TelegramBlockRow
        href="https://t.me/neuraltrades"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Styles.TelegramBlockIcon />
        <Styles.TelegramBlockText>
          Follow NP updates in Telegram channel
        </Styles.TelegramBlockText>
      </Styles.TelegramBlockRow>
    </Styles.TelegramBlock>
  );

  return (
    <Styles.Wrapper>
      <Navbar />
      <Styles.Main>
        <Styles.Pepe1 />
        <Styles.Pepe2 />
        <Styles.Pepe3 />
        <Styles.Pepe4 />
        <Styles.Pepe5 />
        <Styles.Pepe6 />
        <Styles.Pepe7 />
        <Styles.Heading>
          <Container>
            <Styles.HeadingRow>
              <Styles.Title>UPDATES</Styles.Title>
              <Styles.FiltersIcon onClick={toggleFilters} />
            </Styles.HeadingRow>
          </Container>
        </Styles.Heading>
        <Styles.MobileFilters isVisible={isFiltersVisible}>
          <Styles.MobileFiltersHeading>
            <Styles.MobileFiltersTitle>Categories</Styles.MobileFiltersTitle>
            <Styles.MobileFiltersClose onClick={toggleFilters}>
              X
            </Styles.MobileFiltersClose>
          </Styles.MobileFiltersHeading>

          <Flex width="100%" flexWrap="wrap" pt={44}>
            {filters.map((filter: IFilter, index: number) => {
              const { title, key } = filter;

              const isActive = key === activeFilter;
              const isLast = index === filters.length - 1;

              return (
                <Box
                  key={key}
                  pt={index >= 2 ? 10 : 0}
                  width={isLast ? 1 : 1 / 2}
                  pr={index % 2 === 0 ? 10 : 0}
                  pl={index % 2 !== 0 ? 10 : 0}
                  pb={isLast ? 0 : 10}
                >
                  <Styles.CategoryCard
                    onClick={() => setActiveFilter(key)}
                    isActive={isActive}
                  >
                    <Styles.Category>{title}</Styles.Category>
                  </Styles.CategoryCard>
                </Box>
              );
            })}
          </Flex>
        </Styles.MobileFilters>
        <Container>
          <Styles.Pepe8 />
          <Styles.Pepe9 />
          <Styles.Pepe10 />
          <Styles.Pepe11 />
          {isLoading && !isInitialInit ? (
            <Styles.Loader src={preloaderImage} alt="loader" />
          ) : (
            <>
              <Flex flexWrap="wrap" width="100%" pt={[27, 27, 36, 36]}>
                <Box width={[1, 1, 2 / 3, 2 / 3]} pr={[0, 0, 25, 25]}>
                  {isLoading && isInitialInit ? (
                    <Styles.Loader src={preloaderImage} alt="loader" />
                  ) : null}
                  {!isLoading ? (
                    <Styles.Group>
                      <Styles.GroupTitle>
                        {total} events found
                      </Styles.GroupTitle>

                      {updates.length ? (
                        <Styles.UpdatesList>
                          {updates.map((update: IUpdate) => {
                            const { pepeNumber, type, date, value, tx } =
                              update;

                            return (
                              <UpdatesCard
                                key={`${pepeNumber}/${type}/${tx}`}
                                pepeNumber={pepeNumber}
                                type={type}
                                date={date}
                                value={value}
                                tx={tx}
                              />
                            );
                          })}
                        </Styles.UpdatesList>
                      ) : null}
                      {!isLoading &&
                      updates.length &&
                      isCanLoadMore &&
                      !isLoadingMore &&
                      activeFilter !== "top-sales" ? (
                        <Styles.ShowMore onClick={onShowMore}>
                          show more...
                        </Styles.ShowMore>
                      ) : null}
                      {isLoadingMore ? <Styles.Spinner /> : null}
                    </Styles.Group>
                  ) : null}
                </Box>
                <Box width={[0, 0, 1 / 3, 1 / 3]} pl={[0, 0, 25, 25]}>
                  <Styles.Group>
                    <Styles.GroupTitle>FILTERS</Styles.GroupTitle>
                  </Styles.Group>

                  <Styles.DesktopFilters>
                    {filters.map((filter: IFilter) => {
                      const { title, key } = filter;

                      const isActive = key === activeFilter;

                      return (
                        <Styles.CategoryCard
                          key={key}
                          onClick={() => setActiveFilter(key)}
                          isActive={isActive}
                        >
                          <Styles.Category>{title}</Styles.Category>
                        </Styles.CategoryCard>
                      );
                    })}
                    {activeFilter !== null ? (
                      <Styles.ResetFilters onClick={onResetFilters}>
                        Reset filters
                      </Styles.ResetFilters>
                    ) : null}
                  </Styles.DesktopFilters>

                  <Styles.DesktopTgRow>
                    {renderTelegramBlock()}
                  </Styles.DesktopTgRow>
                </Box>
              </Flex>
              <Styles.MobileTgRow>{renderTelegramBlock()}</Styles.MobileTgRow>
            </>
          )}
        </Container>
      </Styles.Main>
      <Footer />
    </Styles.Wrapper>
  );
};

export default Updated;
