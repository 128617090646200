/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Flex, Box } from "reflexbox";
import { useWallet } from "bscuw";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import NeuralPepeCard from "../../components/NeuralPepeCard";
import Pepes from "../../components/Pepes";
import ConnectWallet from "../../components/ConnectWallet";

// Modals
import ConnectWalletModal from "../../modals/ConnectWallet";

// Blockchain
import {
    getAIBalance,
    getAiOnPepe,
    getPepeIndex,
    getPepeBalance,
} from "../../blockchain/utils";

// Styles
import Styles from "./styles";

const MyCollection: React.FC = () => {
    const [pepeAmount, setPepeAmount] = React.useState<null | number>(null);
    const [aiToClaim, setAiToClaim] = React.useState<null | number>(null);
    const [aiInWallet, setAiInWallet] = React.useState<null | number>(null);
    const [selectedPepe, setSelectedPepe] = React.useState<number | null>(null);
    const [pepesIndex, setPepesIndex] = React.useState<number[]>([]);
    const [activeModal, setActiveModal] = React.useState<
        null | "connectWallet"
    >(null);

    const { status, account, ethereum } = useWallet();
    const isWalletConnected = status === "connected";

    React.useEffect(() => {
        if (account !== null) {
            onGetPepeAmount();
            onGetAIBalance();
        }
    }, [account]);

    React.useEffect(() => {
        if (isWalletConnected && activeModal === "connectWallet") {
            setActiveModal(null);
        }
    }, [isWalletConnected, activeModal]);

    React.useEffect(() => {
        if (Number(pepeAmount) > 0 && !pepesIndex.length) {
            getAllPepesIndex();
        }
    }, [pepeAmount, pepesIndex]);

    React.useEffect(() => {
        if (pepeAmount === 0) {
            setAiToClaim(0);
        }
    }, [pepeAmount]);

    React.useEffect(() => {
        if (pepesIndex.length && selectedPepe === null) {
            setSelectedPepe(pepesIndex[0]);
        }
    }, [pepesIndex, selectedPepe]);

    const onConnectWallet = (): void => {
        setActiveModal("connectWallet");
    };

    const getAllPepesIndex = async () => {
        if (pepeAmount !== null && Number(pepeAmount) > 0) {
            let data: number[] = [];
            let claim: number[] = [];

            for (let i = 0; i < pepeAmount; i++) {
                const pepeIndex = await getPepeIndex(i, account, ethereum);
                if (pepeIndex) {
                    data.push(pepeIndex);
                }
            }
            setPepesIndex(data);

            for (const pepeIndex of data) {
                const onGetAiOnPepe = await getAiOnPepe(pepeIndex, ethereum);
                claim.push(onGetAiOnPepe);
            }

            const parseTotalClaimed = claim.reduce(
                (a: number, b: number) => a + b,
                0
            );
            setAiToClaim(+Number(parseTotalClaimed).toFixed(4));
        }
    };

    const onGetPepeAmount = async (): Promise<void> => {
        const data = await getPepeBalance(account, ethereum);
        setPepeAmount(Number(data));
    };

    const onGetAIBalance = async (): Promise<void> => {
        const aiTokenBalance = await getAIBalance(account, ethereum);
        setAiInWallet(aiTokenBalance);
    };

    const handlePepeClick = (pepeIndex: number | null): void => {
        if (pepeIndex !== selectedPepe) {
            setSelectedPepe(pepeIndex);
        }
        document.getElementById("pepe-card")?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const setTotalAiEarned = (amount: number) => {
        setAiToClaim(+Number(amount).toFixed(4));
    };

    const onRemovePepe = (index: number, aiEarned: number) => {
        const newPepesList = pepesIndex.filter(
            (item: number) => item !== index
        );

        setPepesIndex(newPepesList);
        setPepeAmount(Number(pepeAmount) - 1);
        setSelectedPepe(newPepesList[0]);

        if (aiToClaim) {
            setAiToClaim(+Number(aiToClaim - aiEarned).toFixed(4));
        }
    };

    const setPrevious = () => {
        if (selectedPepe) {
            const getCurrentIndex = pepesIndex.indexOf(selectedPepe);
            setSelectedPepe(pepesIndex.slice(getCurrentIndex - 1)[0]);
        }
    };

    const setNext = () => {
        if (selectedPepe) {
            const getCurrentIndex = pepesIndex.indexOf(selectedPepe);

            if (getCurrentIndex + 2 > pepesIndex.length) {
                setSelectedPepe(pepesIndex[0]);
            } else {
                setSelectedPepe(pepesIndex[getCurrentIndex + 1]);
            }
        }
    };

    return (
        <Styles.Wrapper>
            <Navbar />
            <Styles.Row>
                <Container>
                    <Flex width="100%" flexWrap="wrap">
                        <Box width={[1, 1, 1 / 3, 1 / 3]} pr={[0, 0, 54, 54]}>
                            <Button label="My collection" isActive />
                            <Button
                                label="Explore all"
                                mt={30}
                                link="/explore-all"
                            />
                        </Box>
                        <Box
                            width={[1, 1, 2 / 3, 2 / 3]}
                            pl={[0, 0, 54, 54]}
                            pt={[24, 24, 0, 0]}
                        >
                            {isWalletConnected ? (
                                <Styles.StatsBlock>
                                    <Styles.StatsList>
                                        <Styles.StatsTitle>
                                            {pepeAmount === null
                                                ? "???"
                                                : pepeAmount}
                                        </Styles.StatsTitle>
                                        <Styles.StatsText>
                                            Pepe amount
                                        </Styles.StatsText>
                                    </Styles.StatsList>
                                    <Styles.StatsList>
                                        {aiToClaim === null ? (
                                            <Styles.Spinner />
                                        ) : (
                                            <Styles.StatsTitle>
                                                {aiToClaim}
                                            </Styles.StatsTitle>
                                        )}
                                        <Styles.StatsText>
                                            $AI to claim
                                        </Styles.StatsText>
                                    </Styles.StatsList>
                                    <Styles.StatsList>
                                        <Styles.StatsTitle>
                                            {aiInWallet === null
                                                ? "???"
                                                : aiInWallet}
                                        </Styles.StatsTitle>
                                        <Styles.StatsText>
                                            $AI in wallet
                                        </Styles.StatsText>
                                    </Styles.StatsList>
                                </Styles.StatsBlock>
                            ) : (
                                <ConnectWallet onConnect={onConnectWallet} />
                            )}
                        </Box>
                    </Flex>

                    {isWalletConnected ? (
                        <>
                            {selectedPepe ? (
                                <Styles.NeuralPepeCardRow>
                                    {pepesIndex.length > 2 ? (
                                        <Styles.PepeArrow
                                            direction="left"
                                            onClick={setPrevious}
                                        />
                                    ) : null}

                                    <NeuralPepeCard
                                        pepeNumber={selectedPepe}
                                        aiInWallet={aiInWallet}
                                        aiToClaim={aiToClaim}
                                        pepesIndex={pepesIndex}
                                        setTotalAiEarned={setTotalAiEarned}
                                        onRemovePepe={onRemovePepe}
                                    />
                                    {pepesIndex.length > 2 ? (
                                        <Styles.PepeArrow
                                            direction="right"
                                            onClick={setNext}
                                        />
                                    ) : null}
                                </Styles.NeuralPepeCardRow>
                            ) : null}

                            {pepeAmount !== null && pepeAmount > 0 ? (
                                <Styles.CollectionSection>
                                    <Styles.CollectionTitle>
                                        Your collection
                                    </Styles.CollectionTitle>

                                    <Flex
                                        width="100%"
                                        flexWrap="wrap"
                                        pt={[40, 40, 20, 20]}
                                    >
                                        {Array(pepeAmount)
                                            .fill("pepe")
                                            .map((i: string, index: number) => (
                                                <Box
                                                    key={`${i}/${index}`}
                                                    width={[
                                                        1 / 2,
                                                        1 / 2,
                                                        1 / 6,
                                                        1 / 6,
                                                    ]}
                                                    pb={[30, 30, 40, 40]}
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <Pepes
                                                        width={131}
                                                        height={131}
                                                        number={
                                                            pepesIndex[index]
                                                        }
                                                        handleClick={
                                                            handlePepeClick
                                                        }
                                                        isLoading={
                                                            !pepesIndex[index]
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                    </Flex>
                                </Styles.CollectionSection>
                            ) : null}
                        </>
                    ) : null}
                </Container>
            </Styles.Row>
            <Footer />

            <ConnectWalletModal
                isOpen={activeModal === "connectWallet"}
                onClose={() => setActiveModal(null)}
            />
        </Styles.Wrapper>
    );
};

export default MyCollection;
