import styled from "styled-components";

import pepe1 from "../../assets/about/pepe1.png";
import pepe2 from "../../assets/about/pepe2.png";
import pepe3 from "../../assets/about/pepe3.png";
import pepe4 from "../../assets/about/pepe4.png";

const Wrapper = styled.main`
  background-color: #00130d;
`;

const Main = styled.main`
  padding: 90px 0 0 0;

  @media (min-width: 1024px) {
    border-top: 1px solid #ffffff;
    padding: 64px 0 40px 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 50px;
  line-height: 43px;
  color: #d5ffca;
`;

const Description = styled.p`
  margin: 90px 40px 0 40px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #d5ffca;

  @media (min-width: 1024px) {
    margin: 110px 0 0 0;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Button = styled.div`
  margin: 65px auto 0 auto;
  width: 226px;
  height: 49px;
  border: 5px solid rgba(255, 255, 255, 0.74);
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    margin: 60px auto 0 auto;
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
`;

const Pepe1 = styled.div`
  width: 47px;
  height: 47px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -90px;
  left: 10px;
`;

const Pepe2 = styled.div`
  width: 52px;
  height: 52px;
  background-image: url(${pepe2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 30px;
  top: -65px;
`;

const Pepe3 = styled.div`
  width: 47px;
  height: 47px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 50px;
  top: 60px;
`;

const Pepe4 = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${pepe3});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 40px;
  top: 70px;
`;

const Pepe5 = styled.div`
  width: 47px;
  height: 47px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 270px;
`;

const Pepe6 = styled.div`
  width: 127px;
  height: 127px;
  background-image: url(${pepe4});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -40px;
  top: 250px;
`;

const Pepe7 = styled.div`
  width: 47px;
  height: 47px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 460px;
`;

const Pepe8 = styled.div`
  width: 47px;
  height: 47px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: -80px;
`;

const TitleRow = styled.div``;

const Title2 = styled(Title)`
  display: none;

  @media (min-width: 1024px) {
    margin: 0 0 0 15px;
    font-size: 88px;
    line-height: 43px;
    color: rgba(213, 255, 202, 0.3);
    position: absolute;
    left: 170px;
    top: -10px;
    display: block;
  }
`;

const Link = styled.a`
  width: 100%;
  text-decoration: none;
`;

const Styles = {
  Wrapper,
  Main,
  Row,
  Title,
  Description,
  Button,
  ButtonTitle,
  Pepe1,
  Pepe2,
  Pepe3,
  Pepe4,
  Pepe5,
  Pepe6,
  Pepe7,
  Pepe8,
  TitleRow,
  Title2,
  Link,
};

export default Styles;
