import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// Config
import socials, { ISocial } from "../../config/socials";

// Styles
import Styles from "./styles";

const Footer: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Flex width="100%" flexWrap="wrap">
          <Box width={[1, 1, 1 / 3, 1 / 3]}>
            <Styles.NameRow>
              <Styles.Name>NEURAL PEPE</Styles.Name>
              <Styles.BinanceLogo />
            </Styles.NameRow>
            <Styles.DividerLine />
            <Styles.Description>
              The first AI-(de)generated collectibles on BSC. <br /> Only 7777
              neural pepes will ever exist
            </Styles.Description>
          </Box>
          <Box width={[1, 1, 2 / 3, 2 / 3]}>
            <Styles.Nav>
              <Styles.NavRow>
                <Styles.RouterLink to="/about" activeClassName="active">
                  About
                </Styles.RouterLink>
                <Styles.Link
                  href="https://docs.neuralpepe.com/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </Styles.Link>
                <Styles.Link
                  href="https://docs.neuralpepe.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </Styles.Link>
              </Styles.NavRow>
              <Styles.NavRow>
                <Styles.Link
                  href="https://bscscan.com/address/0x012f90E777bdb2B4CA132f0f6EB9e7959075E9b2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Neural Pepe NFT contract
                </Styles.Link>
                <Styles.Link
                  href="https://bscscan.com/address/0x7b617145ECE988b864Cd12f67476C24903f605a9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AI token smart contract
                </Styles.Link>
              </Styles.NavRow>
            </Styles.Nav>

            <Styles.Socials>
              {socials.map((social: ISocial) => {
                const { logo, url } = social;

                return (
                  <Styles.SocialLink
                    key={url}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.SocialLogo src={logo} />
                  </Styles.SocialLink>
                );
              })}
            </Styles.Socials>
          </Box>
        </Flex>

        <Styles.VoiLogoRow>
          <Styles.VoiLogo />
        </Styles.VoiLogoRow>
      </Container>
    </Styles.Wrapper>
  );
};

export default Footer;
