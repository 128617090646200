import styled from "styled-components";

import spinner from "../../assets/spinner.svg";
import jailGrid from "../../assets/jailGrid.png";

type TContainerProps = {
    width: number;
    height: number;
    isLoading?: boolean;
    disabled?: boolean;
    desktopSize?: number;
};

type TLoaderProps = {
    width: number;
    height: number;
};

type TPepeNumberProps = {
    length: number;
    onlyNumber?: boolean;
};

type TAvatarProps = {
    width: number;
    height: number;
    desktopSize?: number;
};

const Container = styled.div`
    width: ${({ width }: TContainerProps) => `${width}px`};
    height: ${({ height }: TContainerProps) => `${height}px`};
    border: 3px solid #00ca08;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    overflow: hidden;
    position: relative;

    &:hover {
        cursor: ${({ isLoading, disabled }: TContainerProps) =>
            isLoading || disabled ? "default" : "pointer"};
        background: ${({ isLoading, disabled }: TContainerProps) =>
            isLoading || disabled
                ? "none"
                : "linear-gradient(0deg, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86))"};

        p {
            display: ${({ isLoading, disabled }: TContainerProps) =>
                isLoading || disabled ? "none" : "block"};
        }

        img {
            display: ${({ disabled }: TContainerProps) =>
                disabled ? "block" : "none"};
        }

        .jail-grid {
            display: none;
        }
    }

    @media (min-width: 1024px) {
        width: ${({ desktopSize, width }: TContainerProps) =>
            `${desktopSize || width}px`};
        height: ${({ desktopSize, height }: TContainerProps) =>
            `${desktopSize || height}px`};
    }
`;

const Spinner = styled.div`
    width: ${({ width }: TLoaderProps) => `${width * 0.8}px`};
    height: ${({ height }: TLoaderProps) => `${height * 0.8}px`};
    background-image: url(${spinner});
    background-repeat: no-repeat;
    background-size: contain;
`;

const PepeNumber = styled.p`
    font-size: ${({ length, onlyNumber }: TPepeNumberProps) =>
        onlyNumber || length > 14 ? "16px" : "24px"};
    line-height: ${({ length, onlyNumber }: TPepeNumberProps) =>
        onlyNumber || length > 14 ? "20px" : "30px"};
    text-align: center;
    color: #ff9292;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    margin: 0 10px;
    display: none;
    word-break: break-all;
`;

const Avatar = styled.img`
    width: ${({ width }: TAvatarProps) => `${width}px`};
    height: ${({ height }: TAvatarProps) => `${height}px`};

    @media (min-width: 1024px) {
        width: ${({ desktopSize, width }: TAvatarProps) =>
            `${desktopSize || width}px`};
        height: ${({ desktopSize, height }: TAvatarProps) =>
            `${desktopSize || height}px`};
    }
`;

const JailGrid = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${jailGrid});
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
`;

const Styles = {
    Container,
    Spinner,
    PepeNumber,
    Avatar,
    JailGrid,
};

export default Styles;
