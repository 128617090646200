import * as React from "react";

import notFoundImage from "../../assets/gif/notFound.gif";

// Styles
import Styles from "./styles";

const NotFound: React.FC = () => (
  <Styles.Wrapper>
    <Styles.Title>NEURAL PEPE</Styles.Title>
    <Styles.Loader src={notFoundImage} alt="not found" />
  </Styles.Wrapper>
);

export default NotFound;
