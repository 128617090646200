import * as React from "react";

// Components
import Container from "../Container";

// Styles
import Styles from "./styles";

const Roadmap: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Styles.TitleRow>
          <Styles.Title>Roadmap</Styles.Title>
          <Styles.BlurTitle>Roadmap</Styles.BlurTitle>
        </Styles.TitleRow>

        <Styles.Link
          href="https://docs.neuralpepe.com/schedule"
          target="_blank"
          rel="noopener noreferrer"
        >
          click to open
        </Styles.Link>

        <Styles.Roadmap>
          <Styles.RoadmapBlock position={1}>
            <Styles.RoadmapDate>12.04</Styles.RoadmapDate>
            <Styles.RoadmapText>
              Initial Pepe Offering on Binance Smart Chain
            </Styles.RoadmapText>
          </Styles.RoadmapBlock>
          <Styles.Line1 />
          <Styles.RoadmapBlock position={2}>
            <Styles.RoadmapDate>27.04</Styles.RoadmapDate>
            <Styles.RoadmapText>Neural Pepe reveal event</Styles.RoadmapText>
          </Styles.RoadmapBlock>
          <Styles.Line2 />
          <Styles.RoadmapBlock position={3}>
            <Styles.RoadmapDate>10.05</Styles.RoadmapDate>
            <Styles.RoadmapText>$AI Governance Launch (DAO)</Styles.RoadmapText>
          </Styles.RoadmapBlock>
          <Styles.Line3 />
          <Styles.RoadmapBlock position={4}>
            <Styles.RoadmapDate>17.05</Styles.RoadmapDate>
            <Styles.RoadmapText>Challenges with NP</Styles.RoadmapText>
          </Styles.RoadmapBlock>
          <Styles.Line4 />

          <Styles.RoadmapBlock position={5}>
            <Styles.RoadmapDate>July</Styles.RoadmapDate>
            <Styles.RoadmapText>AI Generator</Styles.RoadmapText>
          </Styles.RoadmapBlock>
          <Styles.Line5 />

          <Styles.RoadmapBlock position={6}>
            <Styles.RoadmapDate>TBA</Styles.RoadmapDate>
            <Styles.RoadmapText>
              New Neural Meme collection and mechanics
            </Styles.RoadmapText>
          </Styles.RoadmapBlock>
        </Styles.Roadmap>
      </Container>
    </Styles.Wrapper>
  );
};

export default Roadmap;
