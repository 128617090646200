import styled from "styled-components";

const Wrapper = styled.main`
  background-color: #00130d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  border-top: 1px solid #ffffff;
  padding: 30px 0;
  flex: 1;
`;

const Loader = styled.img`
  width: 40%;
  height: 40%;
`;

const LoadMore = styled.p`
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin: 20px 0 40px 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const NotFound = styled.p`
  margin: 0;
  font-size: 36px;
  line-height: 40px;
  color: #d5ffca;
`;

const Tab = styled.div`
  width: 100%;
  padding: 100px 0 0 0;
`;

const TabText = styled.p`
  margin: 0;
  text-align: center;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const TabButton = styled.a`
  text-decoration: none;
  width: 311px;
  height: 62px;
  background: #1a2722;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0 auto;
`;

const TabButtonText = styled.p`
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Styles = {
  Wrapper,
  Row,
  Loader,
  LoadMore,
  NotFound,
  Tab,
  TabText,
  TabButton,
  TabButtonText,
};

export default Styles;
