import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// Assets
import bscscan from "../../assets/tools/bscscan.png";
import bogtools from "../../assets/tools/bogtools.png";
import dexguru from "../../assets/tools/dexguru.png";
import bot from "../../assets/tools/bot.png";

// Utils
import { TStats } from "../../utils/api";

// Styles
import Styles from "./styles";

interface Props {
  stats: TStats;
}

const StatsSection: React.FC<Props> = (props) => {
  const {
    stats: { liquidity, aiPrice, marketCap, supply },
  } = props;

  const price = (price: number, toFixed = 2) => {
    return Number(price)
      .toFixed(toFixed)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <Styles.Wrapper>
      <Container>
        <Flex flexWrap="wrap" width="100%">
          <Box
            width={[1, 1, 1 / 2, 1 / 2]}
            pr={[0, 0, 30, 30]}
            pb={[0, 0, 30, 30]}
          >
            <Styles.Card>
              <Styles.AILogo />
              <Styles.CardTitle>BEP-20 utility token</Styles.CardTitle>
            </Styles.Card>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 2]}
            pl={[0, 0, 30, 30]}
            pt={[33, 33, 0, 0]}
            pb={[0, 0, 30, 30]}
          >
            <Styles.Card>
              <Styles.ApeSwapBlock>
                <Styles.ApeSwapLogo />
                <Styles.ApeSwapBlockRow>
                  <Styles.ApeSwapName>ApeSwap</Styles.ApeSwapName>
                  <Styles.LiquidityLabel>
                    AI/BNB liquidity
                  </Styles.LiquidityLabel>
                  <Styles.Liquidity>${price(liquidity)}</Styles.Liquidity>
                </Styles.ApeSwapBlockRow>
              </Styles.ApeSwapBlock>

              <Styles.ApeFooter>
                <Styles.ApeFooterInfo>
                  <Styles.ApeDesktopLiquidityLabel>
                    AI/BNB liquidity
                  </Styles.ApeDesktopLiquidityLabel>
                  <Styles.ApeDesktopLiquidity>
                    ${price(liquidity)}
                  </Styles.ApeDesktopLiquidity>
                </Styles.ApeFooterInfo>
                <Styles.ApeButtonLink
                  href="https://app.apeswap.finance/swap?inputCurrency=0x7b617145ECE988b864Cd12f67476C24903f605a9&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styles.ApeButtonTitle>Trade $AI</Styles.ApeButtonTitle>
                </Styles.ApeButtonLink>
              </Styles.ApeFooter>
            </Styles.Card>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 2]}
            pr={[0, 0, 30, 30]}
            pt={[33, 33, 30, 30]}
          >
            <Styles.Card>
              <Styles.CardList>
                <Styles.CardListTitle>
                  <Styles.CardListTitleRed>$AI</Styles.CardListTitleRed> price:
                </Styles.CardListTitle>
                <Styles.CardListValue>{aiPrice} $</Styles.CardListValue>
              </Styles.CardList>
              <Styles.CardList>
                <Styles.CardListTitle>
                  <Styles.CardListTitleRed>$AI</Styles.CardListTitleRed> market
                  cap:
                </Styles.CardListTitle>
                <Styles.CardListValue>
                  {price(marketCap, 0)} $
                </Styles.CardListValue>
              </Styles.CardList>
              <Styles.CardList>
                <Styles.CardListTitle>
                  <Styles.CardListTitleRed>$AI</Styles.CardListTitleRed> supply:
                </Styles.CardListTitle>
                <Styles.CardListValue>{price(supply, 0)}</Styles.CardListValue>
              </Styles.CardList>
            </Styles.Card>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 2]}
            pl={[0, 0, 30, 30]}
            pt={[33, 33, 30, 30]}
          >
            <Styles.Card>
              <Styles.Utility>
                <Styles.CardListTitleRed>$AI</Styles.CardListTitleRed> Utility:
              </Styles.Utility>

              <Styles.CardText>Neural Pepe Renaming</Styles.CardText>
              <Styles.CardText>Governance</Styles.CardText>
              <Styles.CardText>Payment for Neural Meme NFTs</Styles.CardText>
            </Styles.Card>
          </Box>
          <Box width={1} pt={[33, 33, 60, 60]}>
            <Styles.Tools>
              <Styles.ToolsLabel>Charts and Tools:</Styles.ToolsLabel>

              <Flex
                flexWrap="wrap"
                width={["100%", "100%", "auto", "auto"]}
                pt={[20, 20, 0, 0]}
                flex={["auto", "auto", 1, 1]}
              >
                <Box
                  width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]}
                  display="flex"
                  justifyContent="center"
                  pr={[0, 0, 30, 30]}
                >
                  <Styles.Tool
                    href="https://bscscan.com/token/0x7b617145ECE988b864Cd12f67476C24903f605a9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.ToolLogo src={bscscan} />
                    <Styles.ToolName>
                      $AI <br />
                      txns
                    </Styles.ToolName>
                  </Styles.Tool>
                </Box>
                <Box
                  width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]}
                  display="flex"
                  justifyContent="center"
                  pr={[0, 0, 30, 30]}
                  pl={[0, 0, 30, 30]}
                >
                  <Styles.Tool
                    href="https://charts.bogged.finance/?token=0x7b617145ECE988b864Cd12f67476C24903f605a9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.ToolLogo src={bogtools} />
                    <Styles.ToolName>
                      $AI <br />
                      chart
                    </Styles.ToolName>
                  </Styles.Tool>
                </Box>
                <Box
                  width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]}
                  display="flex"
                  justifyContent="center"
                  pt={[15, 15, 0, 0]}
                  pr={[0, 0, 30, 30]}
                  pl={[0, 0, 30, 30]}
                >
                  <Styles.Tool
                    href="https://dex.guru/token/0x7b617145ECE988b864Cd12f67476C24903f605a9-bsc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.ToolLogo src={dexguru} />
                    <Styles.ToolName>
                      $AI <br />
                      chart
                    </Styles.ToolName>
                  </Styles.Tool>
                </Box>
                <Box
                  width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]}
                  display="flex"
                  justifyContent="center"
                  pt={[15, 15, 0, 0]}
                  pl={[0, 0, 30, 30]}
                >
                  <Styles.Tool
                    href="https://t.me/Neuralpepe_bot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.ToolLogo src={bot} />
                    <Styles.ToolName>
                      $NP <br />
                      bot
                    </Styles.ToolName>
                  </Styles.Tool>
                </Box>
              </Flex>
            </Styles.Tools>
          </Box>
        </Flex>
      </Container>
    </Styles.Wrapper>
  );
};

export default StatsSection;
