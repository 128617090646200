import styled from "styled-components";

const Wrapper = styled.div`
  width: calc(100% - 30px);
  background: linear-gradient(
      180deg,
      rgba(12, 131, 9, 0.1) 56.77%,
      #234422 100%
    ),
    rgba(0, 0, 0, 0.96);
  border: 8px solid #ffffff;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;

  @media (min-width: 1024px) {
    width: 430px;
    padding: 25px 40px 50px 40px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const CloseIcon = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 36px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  transform: rotate(-179.16deg);

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 48px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Body = styled.div`
  margin: 30px 0 0 0;
`;

const Marketplace = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 12px 0;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const MarketplaceLogo = styled.img`
  width: 53px;
  height: 53px;
`;

const SoonButton = styled.div`
  margin: 30px 0 0 0;
  width: 100%;
  height: 77px;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SoonButtonLabel = styled.p`
  font-size: 24px;
  line-height: 25px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0.5;
`;

const Styles = {
  Wrapper,
  Heading,
  Title,
  CloseIcon,
  Body,
  Marketplace,
  MarketplaceLogo,
  SoonButton,
  SoonButtonLabel,
};

export default Styles;
