import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Wrapper = styled.section`
  margin: 52px 0 0 0;

  @media (min-width: 1024px) {
    margin: 113px 0 0 0;
  }
`;

const TitleRow = styled.div`
  position: relative;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #9dea8a;
  text-transform: uppercase;
`;

const BlurTitle = styled(Title)`
  position: absolute;
  font-size: 50px;
  line-height: 62px;
  letter-spacing: 0.1em;
  color: rgba(157, 234, 138, 0.4);
  filter: blur(4px);
  right: 250px;
`;

const List = styled.div``;

const ListTitle = styled.h3`
  margin: 0;
  font-size: 96px;
  line-height: 119px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #d5ffca;
`;

const ListText = styled.p`
  margin: 30px 0 0 0;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  color: #d5ffca;
`;

const Button = styled(NavLink)`
  width: 350px;
  height: 57px;
  border: 5px solid rgba(254, 253, 249, 0.43);
  border-radius: 45px;
  margin: 60px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    border: 5px solid #ffffff;

    p {
      color: #ffffff;
    }
  }
`;

const ButtonLabel = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
`;

const Styles = {
  Wrapper,
  TitleRow,
  Title,
  BlurTitle,
  List,
  ListTitle,
  ListText,
  Button,
  ButtonLabel,
};

export default Styles;
