import styled from "styled-components";

import aiLogo from "../../assets/other/ai.svg";
import apeLogo from "../../assets/other/ape.svg";

const Wrapper = styled.section`
  padding: 50px 0 0 0;

  @media (min-width: 1024px) {
    padding: 100px 0 0 0;
  }
`;

const Card = styled.div`
  padding: 20px 0;
  height: 207px;
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Tools = styled.div`
  padding: 20px 25px 15px 25px;
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 13px 50px;
  }
`;

const ToolsLabel = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 33px;
    line-height: 41px;
    flex: 1;
    text-align: left;
  }
`;

const Tool = styled.a`
  text-decoration: none;

  @media (min-width: 1024px) {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &:hover {
    p {
      display: block;
    }

    img {
      opacity: 1;
    }
  }
`;

const ToolLogo = styled.img`
  width: 47px;
  height: 47px;
  opacity: 0.8;
`;

const ToolName = styled.p`
  margin: 4px 0 0 0;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;

  @media (min-width: 1024px) {
    margin: 0;
    position: absolute;
    top: 70px;
    display: none;
  }
`;

const AILogo = styled.div`
  width: 152px;
  height: 96px;
  background-image: url(${aiLogo});
  background-repeat: no-repeat;
  background-size: contain;
`;

const CardTitle = styled.p`
  margin: 20px 0 0 0;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;
`;

const ApeSwapBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ApeSwapLogo = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${apeLogo});
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 1024px) {
    width: 70px;
    height: 68px;
  }
`;

const ApeSwapName = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 50px;
    color: #d5ffca;
  }
`;

const Liquidity = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 40px;

  &:not(:first-child) {
    margin: 40px 0 0 0;
  }

  @media (min-width: 1024px) {
    &:not(:first-child) {
      margin: 20px 0 0 0;
    }
  }
`;

const CardListTitle = styled.p`
  margin: 0;
  flex: 1;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const CardListTitleRed = styled.span`
  color: #e85c5b;
`;

const CardListValue = styled.p`
  flex: 1;
  text-align: center;
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Utility = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  color: #d5ffca;
`;

const CardText = styled.p`
  margin: 10px 0 0 0;
  font-size: 16px;
  line-height: 35px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 35px;
  }
`;

const ApeSwapBlockRow = styled.div`
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

const LiquidityLabel = styled.p`
  margin: 5px 0 0 0;
  font-size: 10px;
  line-height: 12px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ApeButtonLink = styled.a`
  width: 173px;
  height: 70px;
  background: #006d4b;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 20px 0 0 0;

  &:hover {
    filter: drop-shadow(2px 4px 7px rgba(255, 214, 0, 0.55));
  }

  @media (min-width: 1024px) {
    margin: 0 0 0 20px;
  }
`;

const ApeButtonTitle = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
`;

const ApeFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1024px) {
    margin: 20px 0 0 0;
  }
`;

const ApeFooterInfo = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ApeDesktopLiquidityLabel = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;
`;

const ApeDesktopLiquidity = styled.p`
  margin: 0;
  font-size: 40px;
  line-height: 50px;
  color: #d5ffca;
`;

const Styles = {
  Wrapper,
  Card,
  Tools,
  ToolsLabel,
  Tool,
  ToolLogo,
  ToolName,
  AILogo,
  CardTitle,
  ApeSwapBlock,
  ApeSwapLogo,
  ApeSwapName,
  Liquidity,
  CardList,
  CardListTitle,
  CardListTitleRed,
  CardListValue,
  Utility,
  CardText,
  ApeSwapBlockRow,
  LiquidityLabel,
  ApeButtonLink,
  ApeButtonTitle,
  ApeFooter,
  ApeFooterInfo,
  ApeDesktopLiquidityLabel,
  ApeDesktopLiquidity,
};

export default Styles;
