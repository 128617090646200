import styled from "styled-components";

import arrow from "../../assets/other/filtersArrow.svg";
import filtersX from "../../assets/icons/filtersX.svg";

type TRowProps = {
  isVisible: boolean;
};

const Container = styled.div``;

const Row = styled.div`
  margin: 50px 0 0 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px;
  display: ${({ isVisible }: TRowProps) => (isVisible ? "block" : "none")};

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Group = styled.div`
  margin: 0 0 15px 0;
`;

const GroupLabel = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const SelectRow = styled.div`
  margin: 12px 0 0 50px;
  position: relative;
`;

const Select = styled.select`
  background: rgba(255, 255, 255, 0.31);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  height: 45px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 20px;
  letter-spacing: 0.2em;
  color: #000000;
  padding: 0 0 0 40px;
  font-family: "Righteous", cursive;

  &:hover {
    cursor: pointer;
  }

  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 16px;
`;

const Button = styled.div`
  margin: 45px 0 0 0;
  background: rgba(46, 104, 0, 0.75);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 11px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: rgba(63, 142, 0, 0.75);
    border: 1px solid #d5ffca;
  }
`;

const ButtonLabel = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  -webkit-text-stroke: 1px #000000;
`;

const ResetAll = styled.p`
  margin: 20px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MobileTitle = styled.h3`
  margin: 40px 0 0 0;
  text-align: center;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MobileAction = styled.a`
  margin: 25px 0 0 0;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: block;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const OnlyMePepes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0 0 0;
`;

const CheckBox = styled.div`
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.31);
  border: 1px solid #d5ffca;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const OnlyMyPepesText = styled.p`
  margin: 0 0 0 20px;
  flex: 1;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const CheckBoxX = styled.div`
  width: 26px;
  height: 26px;
  background-image: url(${filtersX});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Styles = {
  Container,
  Row,
  Group,
  GroupLabel,
  SelectRow,
  Select,
  Button,
  ButtonLabel,
  ResetAll,
  MobileTitle,
  MobileAction,
  OnlyMePepes,
  CheckBox,
  OnlyMyPepesText,
  CheckBoxX,
};

export default Styles;
