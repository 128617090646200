// @ts-ignore
import { ethers } from "ethers";
import web3 from "web3";

import testnetAi, {
  address as testnetAiAddress,
  productionAddress as productionAiAddress,
} from "../contracts/testnetAi";
import testnetNp, {
  address as testnetNpAddress,
  productionAddress as productionNpAddress,
} from "../contracts/testnetNp";

export const isTestNet = false;

const bscUrl = isTestNet
  ? "https://data-seed-prebsc-1-s1.binance.org:8545/"
  : "https://bsc-dataseed.binance.org/";

const AIAddress = isTestNet ? testnetAiAddress : productionAiAddress;
const NPAddress = isTestNet ? testnetNpAddress : productionNpAddress;

export const getAIBalance = async (
  address: string | null,
  activeProvider: any
): Promise<number> => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(AIAddress, testnetAi, provider);
    const balanceOf = await contract.balanceOf(address);
    return +Number(ethers.utils.formatEther(balanceOf)).toFixed(4);
  } catch {
    return 0;
  }
};

export const getNFTPrice = async (): Promise<number> => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(bscUrl);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    const data = await contract.getNFTPrice();

    const parse = +ethers.utils.formatEther(data);
    return +Number(parse).toFixed(6).toString();
  } catch {
    return 0;
  }
};

export const getTotalSupply = async (): Promise<number> => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(bscUrl);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    const data = await contract.totalSupply();
    return +ethers.utils.formatUnits(data, 0);
  } catch {
    return 0;
  }
};

export const getPepeOwner = async (index: number): Promise<string | null> => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(bscUrl);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    return await contract.ownerOf(index);
  } catch {
    return null;
  }
};

export const getPepeName = async (index: number): Promise<string> => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(bscUrl);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    const name = await contract.tokenNameByIndex(index);
    if (name.length) {
      return name;
    }
    return `Neural pepe #${index}`;
  } catch {
    return `Neural pepe #${index}`;
  }
};

export const getPepeBalance = async (
  address: string | null,
  activeProvider: any
) => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    const balanceOf = await contract.balanceOf(address);
    const parse = ethers.utils.formatEther(balanceOf);
    return web3.utils.toWei(parse, "ether");
  } catch {
    return 0;
  }
};

export const getPepeIndex = async (
  index: number | undefined,
  address: string | null,
  activeProvider: any
) => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    const tokenOfOwnerByIndex = await contract.tokenOfOwnerByIndex(
      address,
      index
    );
    const parse = ethers.utils.formatEther(tokenOfOwnerByIndex);
    return Number(web3.utils.toWei(parse, "ether"));
  } catch {
    return 0;
  }
};

export const getAiOnPepe = async (index: number, activeProvider: any) => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(AIAddress, testnetAi, provider);
    const accumulated = await contract.accumulated(index);
    return +ethers.utils.formatUnits(accumulated);
  } catch {
    return 0;
  }
};

export const changeName = async (
  tokenId: number | null,
  name: string,
  activeProvider: any
) => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NPAddress, testnetNp, signer);
    const signTransactions = contract.connect(signer);
    return await signTransactions.changeName(tokenId, name);
  } catch {
    return null;
  }
};

export const validateName = async (
  name: string,
  activeProvider: any
): Promise<boolean> => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    return await contract.validateName(name);
  } catch {
    return false;
  }
};

export const tokenByIndex = async (tokenId: number, activeProvider: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    return await contract.tokenByIndex(tokenId);
  } catch {
    return null;
  }
};

export const claim = async (
  tokenId: number | number[],
  activeProvider: any,
  isArray: boolean
) => {
  try {
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(AIAddress, testnetAi, signer);
    const signTransactions = contract.connect(signer);

    return await signTransactions.claim(isArray ? tokenId : [tokenId]);
  } catch {
    return null;
  }
};

export const checkReservedName = async (
  name: string,
  activeProvider: any
): Promise<boolean> => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    return await contract.isNameReserved(name);
  } catch {
    return false;
  }
};

export const getTotalAiSupply = async (
  activeProvider: any
): Promise<number> => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(AIAddress, testnetAi, provider);
    const data = await contract.totalSupply();
    return +Number(ethers.utils.formatEther(data)).toFixed(4);
  } catch {
    return 0;
  }
};

export const getAiPerDay = async (activeProvider: any): Promise<number> => {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(AIAddress, testnetAi, provider);
    const data = await contract.emissionPerDay();
    return +ethers.utils.formatUnits(data);
  } catch {
    return 0;
  }
};

export const transfer = async (
  from: string,
  to: string,
  index: number,
  activeProvider: any
) => {
  try {
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NPAddress, testnetNp, signer);
    const signTransactions = contract.connect(signer);

    return await signTransactions.transferFrom(from, to, index);
  } catch {
    return null;
  }
};

export const isApprovedAi = async (
  address: string,
  activeProvider: any
): Promise<boolean> => {
  try {
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(AIAddress, testnetAi, provider);
    const approved = await contract.allowance(address, NPAddress);
    const priceAi = await getNameChangePrice(activeProvider);

    return (
      +Number(ethers.utils.formatEther(approved)).toFixed(4) >= +Number(priceAi)
    );
  } catch {
    return false;
  }
};

export const getNameChangePrice = async (
  activeProvider: any
): Promise<number> => {
  try {
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const contract = new ethers.Contract(NPAddress, testnetNp, provider);
    const data = await contract.NAME_CHANGE_PRICE();
    return +ethers.utils.formatUnits(data);
  } catch {
    return 420;
  }
};

export const approve = async (activeProvider: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(activeProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(AIAddress, testnetAi, signer);
    const signTransactions = contract.connect(signer);

    return await signTransactions.approveMax(NPAddress);
  } catch {
    return null;
  }
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getTransaction = async (
  hash: string
): Promise<ethers.providers.TransactionResponse> => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://bsc-dataseed1.defibit.io/"
  );

  const data = await provider.getTransaction(hash);

  if (!data || data?.confirmations < 4) {
    await delay(1000);
    return await getTransaction(hash);
  }

  return data;
};
