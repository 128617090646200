/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Flex, Box } from "reflexbox";
import { useWallet } from "bscuw";

// Components
import Pepes from "../Pepes";
import Button from "../Button";

// Blockchain
import {
  getPepeName,
  validateName,
  changeName,
  getAiOnPepe,
  claim,
  checkReservedName,
  getTotalAiSupply,
  getAiPerDay,
  transfer,
  isApprovedAi,
  getNameChangePrice,
  approve,
  getTransaction,
} from "../../blockchain/utils/index";

// Api
import {
  setNewPepeName as setNewPepeNameMethod,
  getPepeInfo,
  IPepeInfo,
  IPepeAttribute,
} from "../../utils/api";

// Styles
import Styles from "./styles";

interface Props {
  pepeNumber: number;
  aiInWallet: number | null;
  aiToClaim: number | null;
  pepesIndex: number[];
  setTotalAiEarned: Function;
  onRemovePepe: (index: number, aiEarned: number) => void;
}

const NeuralPepeCard: React.FC<Props> = (props) => {
  const {
    pepeNumber,
    aiInWallet,
    aiToClaim,
    pepesIndex,
    setTotalAiEarned,
    onRemovePepe,
  } = props;

  const [currentState, setCurrentState] = React.useState<
    "view" | "rename" | "claim" | "transfer"
  >("view");

  const [newPepeName, setNewPepeName] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);
  const [pepeName, setPepeName] = React.useState<string | null>(null);
  const [aiEarned, setAiEarned] = React.useState<number>(0);
  const [totalAi, setTotalAi] = React.useState<number>(0);
  const [isClaimFailed, setClaimFailed] = React.useState<boolean>(false);
  const [aiPerDay, setAiPerDay] = React.useState<number>(0);
  const [isClaimAllFailed, setClaimAllFailed] = React.useState<boolean>(false);
  const [pepeInfo, setPepeInfo] = React.useState<IPepeInfo | null>(null);
  const [transferAddress, settransferAddress] = React.useState<string>("");
  const [sendErrorMessage, setSendErrorMessga] = React.useState<null | string>(
    null
  );
  const [isApproved, setIsApproved] = React.useState<boolean>(true);
  const [isRenameLoading, setRenameLoading] = React.useState<boolean>(true);

  const { account, ethereum } = useWallet();

  React.useEffect(() => {
    onGetPepeName();
    getAiAmount();
    setCurrentState("view");
    onGetTotalAi();
    onGetAiPerDay();
    onGetPepeInfo();
    checkApproved();
  }, [pepeNumber]);

  const checkApproved = async () => {
    const checkIsApproved = await isApprovedAi(`${account}`, ethereum);

    setRenameLoading(false)
    setIsApproved(checkIsApproved);
  };

  const onGetPepeInfo = async () => {
    setPepeInfo(null);
    const data = await getPepeInfo(pepeNumber);

    if (data) {
      setPepeInfo(data);
    }
  };

  const onGetPepeName = async () => {
    const data = await getPepeName(Number(pepeNumber));
    setPepeName(data);
  };

  const onGetAiPerDay = async () => {
    const data = await getAiPerDay(ethereum);
    setAiPerDay(data);
  };

  const onGetTotalAi = async () => {
    const data = await getTotalAiSupply(ethereum);
    setTotalAi(data);
  };

  const getAiAmount = async () => {
    const data = await getAiOnPepe(pepeNumber, ethereum);
    setAiEarned(data);
  };

  const approvePepesAi = async (): Promise<void> => {
    const request = await approve(ethereum);

    if (request?.hash) {
      const data = await getTransaction(request.hash);

      if (data) {
        setIsApproved(true);
        await onRename(true);
      } else {
        setRenameLoading(false);
        setErrorMessage("Unknown error");
      }
    } else {
      setRenameLoading(false);
      setErrorMessage("Unknown error");
    }
  };

  const onRename = async (isApprove?: boolean): Promise<void> => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setRenameLoading(true);
    const checkValid = await validateName(newPepeName, ethereum);

    if (checkValid) {
      const checkReserved = await checkReservedName(newPepeName, ethereum);

      if (checkReserved) {
        setRenameLoading(false);
        return setErrorMessage("!!! pepe with this name already exist");
      }

      if (!isApproved && !isApprove) {
        return approvePepesAi();
      }

      const approveAi = await changeName(pepeNumber, newPepeName, ethereum);

      if (approveAi?.hash) {
        setPepeName(newPepeName);
        setNewPepeName("");
        setRenameLoading(false);
        await setNewPepeNameMethod(pepeNumber, newPepeName, approveAi.hash);
      }
    } else {
      setErrorMessage("Invalid name");
      setRenameLoading(false);
    }
  };

  const getAttrubete = (key: string): string | null | undefined => {
    if (pepeInfo) {
      return pepeInfo.attributes.find(
        (item: IPepeAttribute) => item.trait_type === key
      )?.value;
    }
    return null;
  };

  const ontransfer = async () => {
    if (sendErrorMessage) {
      setSendErrorMessga(null);
    }

    if (transferAddress.length && account) {
      const validateAddress = new RegExp("^(0x)[0-9A-Fa-f]{40}$").test(
        transferAddress
      );

      if (validateAddress) {
        const data = await transfer(
          account,
          transferAddress,
          pepeNumber,
          ethereum
        );

        if (data?.hash) {
          onRemovePepe(pepeNumber, aiEarned);
        } else {
          setSendErrorMessga("Unknown error");
        }
      } else {
        setSendErrorMessga("Invalid address");
      }
    }
  };

  const renderView = () => (
    <Flex width="100%" flexWrap="wrap" pt={28}>
      <Box
        width={[1, 1, 1 / 3, 1 / 3]}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pr={[0, 0, 45, 45]}
      >
        <Styles.PepesCoverRow>
          <Pepes width={216} height={216} number={pepeNumber} disabled />

          {pepeInfo?.image_ipfs ? (
            <Styles.PepesLink
              href={pepeInfo?.image_ipfs}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on IPFS
            </Styles.PepesLink>
          ) : null}
        </Styles.PepesCoverRow>
      </Box>
      <Box width={[1, 1, 2 / 3, 2 / 3]} pl={[0, 0, 45, 45]}>
        <Styles.PepesNameRow>
          <Styles.NameLabel>Name:</Styles.NameLabel>
          <Styles.PepesName>{pepeName}</Styles.PepesName>
        </Styles.PepesNameRow>

        <Styles.TraitsRow>
          <Styles.Traits>Traits</Styles.Traits>

          <Flex width="100%" flexWrap="wrap" pt={28}>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pr={[0, 0, 15, 15]}
              pb={[12, 12, 15, 15]}
            >
              <Styles.TraitsBlock>
                <Styles.TraitsValue>
                  {pepeInfo ? getAttrubete("iteration") : "?????"}
                </Styles.TraitsValue>
                <Styles.TraitsTitle>Iteration</Styles.TraitsTitle>
              </Styles.TraitsBlock>
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pl={[0, 0, 15, 15]}
              pr={[0, 0, 15, 15]}
              pb={[12, 12, 15, 15]}
            >
              <Styles.TraitsBlock>
                <Styles.TraitsValue>
                  {pepeInfo ? getAttrubete("eyes") : "?????"}
                </Styles.TraitsValue>
                <Styles.TraitsTitle>Eyes</Styles.TraitsTitle>
              </Styles.TraitsBlock>
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pl={[0, 0, 15, 15]}
              pb={[12, 12, 15, 15]}
            >
              <Styles.TraitsBlock>
                <Styles.TraitsValue>
                  {pepeInfo ? getAttrubete("color") : "?????"}
                </Styles.TraitsValue>
                <Styles.TraitsTitle>Color</Styles.TraitsTitle>
              </Styles.TraitsBlock>
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pr={[0, 0, 15, 15]}
              pb={[12, 12, 0, 0]}
            >
              <Styles.TraitsBlock>
                <Styles.TraitsValue>
                  {pepeInfo ? getAttrubete("background_color") : "?????"}
                </Styles.TraitsValue>
                <Styles.TraitsTitle>Background</Styles.TraitsTitle>
              </Styles.TraitsBlock>
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pl={[0, 0, 15, 15]}
              pr={[0, 0, 15, 15]}
              pb={[12, 12, 0, 0]}
            >
              <Styles.TraitsBlock>
                <Styles.TraitsValue>
                  {pepeInfo ? getAttrubete("special") : "?????"}
                </Styles.TraitsValue>
                <Styles.TraitsTitle>Special</Styles.TraitsTitle>
              </Styles.TraitsBlock>
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pl={[0, 0, 15, 15]}
              pb={[12, 12, 0, 0]}
            >
              <Styles.TraitsBlock>
                <Styles.TraitsValue>
                  {pepeInfo ? getAttrubete("kek") : "?????"}
                </Styles.TraitsValue>
                <Styles.TraitsTitle>Kek</Styles.TraitsTitle>
              </Styles.TraitsBlock>
            </Box>
          </Flex>
        </Styles.TraitsRow>
      </Box>
    </Flex>
  );

  const onHarvest = async () => {
    if (isClaimFailed) {
      setClaimFailed(false);
    }
    const tryClaim = await claim(pepeNumber, ethereum, false);
    if (tryClaim?.hash) {
      setAiEarned(0);
    } else {
      setClaimFailed(true);
    }
  };

  const onHarvestAll = async () => {
    if (isClaimAllFailed) {
      setClaimAllFailed(false);
    }
    const tryClaim = await claim(
      pepesIndex.map((i: number) => i),
      ethereum,
      true
    );
    if (tryClaim?.hash) {
      setTotalAiEarned(0);
    } else {
      setClaimAllFailed(true);
    }
  };

  const renderRename = () => (
    <>
      <Styles.RenamePepeTitle>
        RENAME YOUR NEURAL PEPE NFT
      </Styles.RenamePepeTitle>
      <Flex width="100%" flexWrap="wrap" pt={28}>
        <Box
          width={[1, 1, 1 / 3, 1 / 3]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          pr={[0, 0, 45, 45]}
        >
          <Pepes width={216} height={216} number={pepeNumber} disabled />
        </Box>
        <Box width={[1, 1, 2 / 3, 2 / 3]} pl={[0, 0, 45, 45]}>
          <Styles.CurrentPepeName>Current name</Styles.CurrentPepeName>
          <Styles.PepeActiveName>{pepeName}</Styles.PepeActiveName>

          <Styles.RenameForm>
            <Styles.NewNameLabel>NEW NAME:</Styles.NewNameLabel>
            <Styles.RenameInput
              value={newPepeName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewPepeName(e.target.value)
              }
              placeholder="enter new name of your pepe"
            />
            {errorMessage ? (
              <Styles.ErrorRenameText>{errorMessage}</Styles.ErrorRenameText>
            ) : null}
          </Styles.RenameForm>

          <Styles.RenameButton
            onClick={() => {
              if (!isRenameLoading) {
                onRename();
              }
            }}
          >
            {isRenameLoading ? (
              <Styles.Spinner />
            ) : (
              <Styles.RenameButtonLabel>
                {isApproved ? "RENAME FOR 420 $AI" : "APPROVE $AI FOR RENAME"}
              </Styles.RenameButtonLabel>
            )}
          </Styles.RenameButton>
        </Box>
      </Flex>
    </>
  );

  const renderClaim = () => (
    <Flex width="100%" flexWrap="wrap" pt={28}>
      <Box width={[1, 1, 1 / 2, 1 / 2]} pr={[0, 0, 40, 40]}>
        <Styles.ClaimTitle>$AI EARNED by {pepeNumber} pepe:</Styles.ClaimTitle>
        <Styles.Earned>{Number(aiEarned).toFixed(4)} $AI</Styles.Earned>

        <Styles.HarvestForm>
          <Button label="Harvest" isMobileBig onClick={onHarvest} />
          {isClaimFailed ? (
            <Styles.HarvestErrorLabel>FAILED</Styles.HarvestErrorLabel>
          ) : null}
        </Styles.HarvestForm>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]}>
        <Styles.ClaimTitle>$AI EARNED in total:</Styles.ClaimTitle>
        {aiToClaim === null ? (
          <Styles.Spinner />
        ) : (
          <Styles.Earned>{aiToClaim}</Styles.Earned>
        )}

        <Styles.HarvestForm>
          <Button label="Harvest all" isMobileBig onClick={onHarvestAll} />
          {isClaimAllFailed ? (
            <Styles.HarvestErrorLabel>FAILED</Styles.HarvestErrorLabel>
          ) : null}
        </Styles.HarvestForm>
      </Box>
      <Box width={1} pl={[0, 0, 40, 40]}>
        <Styles.ClaimStatsList>
          <Styles.ClaimStats>
            YOUR $AI BALANCE:{" "}
            <Styles.ClaimStateValue>{aiInWallet || 0}</Styles.ClaimStateValue>
          </Styles.ClaimStats>
          <Styles.ClaimStats>
            NEW $AI PER DAY:{" "}
            <Styles.ClaimStateValue>{aiPerDay}</Styles.ClaimStateValue>
          </Styles.ClaimStats>
          <Styles.ClaimStats>
            TOTAL $AI SUPPLY:{" "}
            <Styles.ClaimStateValue>{totalAi}</Styles.ClaimStateValue>
          </Styles.ClaimStats>
        </Styles.ClaimStatsList>
      </Box>
    </Flex>
  );

  const rendertransfer = () => (
    <Flex width="100%" flexWrap="wrap">
      <Box width={1} pb={[40]}>
        <Styles.TransferTitle>SEND YOUR NEURAL PEPE NFT</Styles.TransferTitle>
      </Box>
      <Box
        width={[1, 1, 1 / 3, 1 / 3]}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pr={[0, 0, 45, 45]}
      >
        <Styles.PepesCoverRow>
          <Pepes width={216} height={216} number={pepeNumber} disabled />
        </Styles.PepesCoverRow>
      </Box>
      <Box width={[1, 1, 2 / 3, 2 / 3]} pl={[0, 0, 45, 45]}>
        <Styles.CurrentPepeName>NAME</Styles.CurrentPepeName>
        <Styles.PepeActiveName>{pepeName}</Styles.PepeActiveName>

        <Styles.RenameForm>
          <Styles.NewNameLabel>ENTER ADDRESS:</Styles.NewNameLabel>
          <Styles.RenameInput
            value={transferAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              settransferAddress(e.target.value)
            }
            placeholder="enter BSC address  where you want to send pepe"
          />
          {sendErrorMessage ? (
            <Styles.ErrorRenameText>{sendErrorMessage}</Styles.ErrorRenameText>
          ) : null}
        </Styles.RenameForm>

        <Styles.RenameButton onClick={ontransfer}>
          <Styles.RenameButtonLabel>SEND</Styles.RenameButtonLabel>
        </Styles.RenameButton>
      </Box>
    </Flex>
  );

  return (
    <Styles.Wrapper id="pepe-card">
      <Styles.Container>
        <Styles.Row>
          <Styles.Heading>
            <Styles.HeadingTitle>
              Your Neural Pepe #{pepeNumber}
            </Styles.HeadingTitle>
            <Styles.WalletBlock>
              <Styles.WalletLogo />
              <Styles.WalletAddress>{account}</Styles.WalletAddress>
            </Styles.WalletBlock>
          </Styles.Heading>
          {currentState === "view" ? renderView() : null}
          {currentState === "rename" ? renderRename() : null}
          {currentState === "claim" ? renderClaim() : null}
          {currentState === "transfer" ? rendertransfer() : null}
          <Flex width="100%" flexWrap="wrap" pt={[36, 36, 28, 28]}>
            <Box width={[1, 1, 1 / 3, 1 / 3]} pr={[0, 0, 35, 35]}>
              <Button
                label={currentState === "rename" ? "back" : "rename"}
                onClick={() =>
                  setCurrentState(currentState === "rename" ? "view" : "rename")
                }
                isMobileBig
              />
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pl={[0, 0, 35, 35]}
              pr={[0, 0, 35, 35]}
              pt={[10, 10, 0, 0]}
            >
              <Button
                label={currentState === "claim" ? "back" : "claim $AI"}
                isMobileBig
                onClick={() =>
                  setCurrentState(currentState === "claim" ? "view" : "claim")
                }
              />
            </Box>
            <Box
              width={[1, 1, 1 / 3, 1 / 3]}
              pl={[0, 0, 35, 35]}
              pt={[10, 10, 0, 0]}
            >
              <Button
                label={currentState === "transfer" ? "back" : "transfer"}
                isMobileBig
                onClick={() =>
                  setCurrentState(
                    currentState === "transfer" ? "view" : "transfer"
                  )
                }
              />
            </Box>
          </Flex>
        </Styles.Row>
      </Styles.Container>
    </Styles.Wrapper>
  );
};

export default NeuralPepeCard;
