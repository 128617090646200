/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import * as React from "react";
import { NavLink } from "react-router-dom";
import { useWallet } from "bscuw";

// Blockchain
import { getPepeIndex, getPepeName } from "../../blockchain/utils/index";

// Utils
import { isPepePrisoner } from "../../utils/api";

// Styles
import Styles from "./styles";

interface Props {
    width: number;
    height: number;
    number?: number;
    isLoading?: boolean;
    disabled?: boolean;
    checkPepeIndex?: number;
    handleClick?: (index: number | null) => void;
    desktopSize?: number;
    onlyNumber?: boolean;
}

const Pepes: React.FC<Props> = (props) => {
    const {
        width,
        height,
        isLoading,
        number,
        disabled,
        checkPepeIndex,
        handleClick,
        desktopSize,
        onlyNumber,
    } = props;

    const { account, ethereum } = useWallet();

    const [fetchedPepeIndex, setPepeIndex] = React.useState<null | number>(
        null
    );
    const [pepeName, setPepeName] = React.useState<string>("");
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);
    const [isImageWaiting, setImageWaiting] = React.useState<boolean>(false);
    const [isPrisoner, setIsPrisoner] = React.useState<boolean>(false);

    const pepeNumber =
        typeof number !== "undefined" ? `${number}` : fetchedPepeIndex;

    React.useEffect(() => {
        if (typeof checkPepeIndex !== "undefined" && account?.length) {
            onGetPepeIndex();
        }
    }, [checkPepeIndex, account]);

    React.useEffect(() => {
        setImageUrl(`https://neuralpepe.com/api/pepe/avatar/${pepeNumber}`);
        checkIsPsisoner();
    }, [pepeNumber]);

    const checkIsPsisoner = async (): Promise<void> => {
        const data = await isPepePrisoner(Number(pepeNumber));
        setIsPrisoner(data);
    };

    const onGetPepeIndex = async (): Promise<void> => {
        const data = await getPepeIndex(checkPepeIndex, account, ethereum);
        if (data) {
            setPepeIndex(data);
        }
    };

    React.useEffect(() => {
        if (pepeNumber) {
            onGetPepeName();
        }
    }, [pepeNumber]);

    const onGetPepeName = async (): Promise<void> => {
        if (pepeNumber) {
            const data = await getPepeName(Number(pepeNumber));
            if (data !== `Neural pepe #${pepeNumber}`) {
                setPepeName(data);
            }
        }
    };

    const refetchPepeImage = () => {
        setImageWaiting(false);
        setImageUrl(
            `https://neuralpepe.com/api/pepe/avatar/${pepeNumber}?id=${Date.now()}`
        );
    };

    const onErrorLoadImage = (): void => {
        setImageWaiting(true);

        setTimeout(() => {
            refetchPepeImage();
        }, 30000);
    };

    if (disabled || handleClick || isImageWaiting) {
        return (
            <Styles.Container
                desktopSize={desktopSize}
                width={width}
                height={height}
                isLoading={isLoading}
                disabled={disabled || isImageWaiting}
                onClick={
                    handleClick
                        ? () => handleClick(Number(pepeNumber))
                        : undefined
                }
            >
                {isLoading || isImageWaiting ? (
                    <Styles.Spinner width={width} height={height} />
                ) : null}
                {imageUrl && !isLoading && !isImageWaiting ? (
                    <Styles.Avatar
                        src={imageUrl}
                        alt="pepe"
                        width={width}
                        height={height}
                        desktopSize={desktopSize}
                        onError={onErrorLoadImage}
                    />
                ) : null}
                {(pepeNumber || pepeName.length) && !onlyNumber ? (
                    <>
                        {pepeNumber && !pepeName.length ? (
                            <Styles.PepeNumber
                                length={`NEURAL PEPE#${pepeNumber}`.length}
                            >{`NEURAL PEPE#${pepeNumber}`}</Styles.PepeNumber>
                        ) : null}
                        {pepeName.length ? (
                            <Styles.PepeNumber length={pepeName.length}>
                                {pepeName} <br />
                                <span>#{pepeNumber}</span>
                            </Styles.PepeNumber>
                        ) : null}
                    </>
                ) : null}
                {onlyNumber && pepeNumber ? (
                    <Styles.PepeNumber
                        onlyNumber={onlyNumber}
                        length={`${pepeNumber}`.length}
                    >
                        {pepeNumber}
                    </Styles.PepeNumber>
                ) : null}
                {isPrisoner ? <Styles.JailGrid className="jail-grid" /> : null}
            </Styles.Container>
        );
    }

    return (
        <NavLink
            to={{
                pathname: `/pepe/${pepeNumber}${location.search}`,
                state: { from: "explore-all" },
            }}
            style={{ textDecoration: "none" }}
        >
            <Styles.Container
                width={width}
                height={height}
                isLoading={isLoading}
                desktopSize={desktopSize}
            >
                {isLoading || isImageWaiting ? (
                    <Styles.Spinner width={width} height={height} />
                ) : null}
                {imageUrl && !isLoading && !isImageWaiting ? (
                    <Styles.Avatar
                        src={imageUrl}
                        alt="pepe"
                        width={width}
                        height={height}
                        desktopSize={desktopSize}
                        onError={onErrorLoadImage}
                    />
                ) : null}
                {(pepeNumber || pepeName.length) && !onlyNumber ? (
                    <>
                        {pepeNumber && !pepeName.length ? (
                            <Styles.PepeNumber
                                length={`NEURAL PEPE#${pepeNumber}`.length}
                            >{`NEURAL PEPE#${pepeNumber}`}</Styles.PepeNumber>
                        ) : null}
                        {pepeName.length ? (
                            <Styles.PepeNumber length={pepeName.length}>
                                {pepeName} <br />
                                <span>#{pepeNumber}</span>
                            </Styles.PepeNumber>
                        ) : null}
                    </>
                ) : null}
                {onlyNumber && pepeNumber ? (
                    <Styles.PepeNumber
                        onlyNumber={onlyNumber}
                        length={`${pepeNumber}`.length}
                    >
                        {pepeNumber}
                    </Styles.PepeNumber>
                ) : null}
                {isPrisoner ? <Styles.JailGrid className="jail-grid" /> : null}
            </Styles.Container>
        </NavLink>
    );
};

export default Pepes;
