import styled from "styled-components";

type TContainerProps = {
  mt?: number;
  isActive?: boolean;
  isMobileBig?: boolean;
  desktopWidth?: number;
};

const Container = styled.button`
  width: 100%;
  height: ${({ isMobileBig }: TContainerProps) =>
    isMobileBig ? "62px" : "38px"};
  background-color: ${({ isActive }: TContainerProps) =>
    isActive ? "rgba(0, 255, 87, 0.42)" : "#1a2722"};
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: ${({ isActive }: TContainerProps) =>
    isActive
      ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
      : "0px 4px 4px rgb(0 0 0 / 25%)"};
  border-radius: 15px;
  margin-top: ${({ mt }: TContainerProps) => (mt ? `${mt}px` : "0")};
  outline: none;

  &:hover {
    cursor: ${({ isActive }: TContainerProps) =>
      isActive ? "default" : "pointer"};
  }

  @media (min-width: 1024px) {
    height: 62px;
    width: ${({ desktopWidth }: TContainerProps) =>
      desktopWidth ? `${desktopWidth}px` : "100%"};
    margin: ${({ desktopWidth, mt }: TContainerProps) =>
      desktopWidth ? "0 auto" : mt ? `${mt}px 0 0 0` : "0"};
  }
`;

const Label = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  font-family: "Righteous", cursive;

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 37px;
  }
`;

const Styles = {
  Container,
  Label,
};

export default Styles;
