import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// Assets
import pepe from "../../assets/pepes/11.svg";

// Styles
import Styles from "./styles";

const SomeSection: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Flex flexWrap="wrap" width="100%">
          <Box width={[1, 1, 1 / 3, 1 / 3]}>
            <Styles.PepesBlock>
              <Styles.Pepes src={pepe} alt="pepes" />
              <Styles.PepeNumber>NEURAL PEPE #1794</Styles.PepeNumber>
            </Styles.PepesBlock>
          </Box>
          <Box width={[1, 1, 2 / 3, 2 / 3]}>
            <Styles.Quote>
              Wearing a neural pepe on your avatar increases your chances to get
              a girlfriend
            </Styles.Quote>
            <Styles.QuoteRow>
              <Styles.QuoteIcon />
              <Styles.Text>(This claim is disputed)</Styles.Text>
            </Styles.QuoteRow>
          </Box>
        </Flex>
      </Container>
    </Styles.Wrapper>
  );
};

export default SomeSection;
