import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

import logo from "../../assets/logo.png";

type TConnectWalletButtonProps = {
  isActive: boolean;
};

const rotating = keyframes`
  from{
      transform: rotate(0deg);
  }
  to{
      transform: rotate(360deg);
  }
`;

const Wrapper = styled.header`
  padding: 30px 15px;
  display: flex;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 3;

  @media (min-width: 1024px) {
    padding: 35px 0 75px 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

const Name = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  color: #d5ffca;
  width: 69px;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    width: auto;
    font-size: 35px;
    line-height: 43px;
    text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    z-index: 2;
    margin: 0 0 0 40px;
    -webkit-text-stroke: 2px #000000;
  }
`;

const Hamburger = styled.div`
  display: flex;
  width: 47px;
  flex-direction: column;
  min-width: 47px;

  & span {
    width: 100%;
    height: 5px;
    background-color: #e9ffc5;

    &:not(:last-child) {
      margin: 0 0 3px 0;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

type TCollapseProps = {
  isActive: boolean;
};

const Collapse = styled.div`
  display: ${({ isActive }: TCollapseProps) => (isActive ? "flex" : "none")};
  flex-basis: 100%;
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  width: 100%;
  background: #505050;
  left: 0;
  top: 0;
  padding: 10px 30px 25px 30px;
  align-items: center;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: flex;
    flex-basis: auto;
    background-color: transparent;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
`;

const MobileMenuTitle = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const CloseIcon = styled.p`
  margin: 0;
  position: absolute;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  transform: rotate(-179.16deg);
  right: 20px;
  top: 15px;

  @media (min-width: 1024px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Links = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    margin: 0 0 0 40px;
    flex: 1;
    justify-content: center;
    flex-direction: row;
  }

  a {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    margin: 0 0 10px 0;
  }

  @media (min-width: 1024px) {
    a {
      margin: 0;
      font-size: 15px;

      &:not(:last-child) {
        margin: 0 20px 0 0;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Button = styled.div`
  background: #ff4752;
  border: 5px solid #ffffff;
  border-radius: 45px;
  margin: 0 0 0 20px;
  width: 167px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const ButtonLabel = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
`;

const WalletRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Socials = styled.div`
  margin: 0 0 10px 0;
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const SocialLink = styled.a`
  &:not(:last-child) {
    margin: 0 11px 0 0;
  }
`;

const SocialImage = styled.img`
  width: 35px;
  height: 35px;
  opacity: 0.8;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
`;

const ConnectWalletRow = styled.div`
  position: relative;
`;

const ConnectWalletButton = styled.div`
  border: 5px solid rgba(255, 255, 255, 0.74);
  border-radius: 45px;
  width: 167px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    border: ${({ isActive }: TConnectWalletButtonProps) =>
      `${isActive ? "5px solid #FF4752" : "5px solid #ffffff"}`};
  }

  @media (min-width: 1024px) {
    &:hover {
      div {
        background-color: ${({ isActive }: TConnectWalletButtonProps) =>
          `${isActive ? "#FF4752" : "#05E100"}`};
      }

      span {
        display: ${({ isActive }: TConnectWalletButtonProps) =>
          isActive ? "block" : "none"};
      }

      p {
        display: ${({ isActive }: TConnectWalletButtonProps) =>
          !isActive ? "block" : "none"};
      }
    }
  }
`;

const ConnectWalletButtonTitle = styled.p`
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
`;

const BalancesRow = styled.div`
  display: none;

  @media (min-width: 1024px) {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 0 0;
    width: 100%;
  }
`;

const BalanceLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  width: 70px;
`;

const Balances = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;

  @media (min-width: 1024px) {
    display: block;
    margin: 0;
  }
`;

const BalanceTitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

const ConnectWalletDot = styled.div`
  width: 8px;
  height: 8px;
  background: #05e100;
  margin: 0 10px 0 0;
  border-radius: 4px;
  transition: 0.3s;
`;

const LogoRow = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:hover {
    div {
      animation: ${rotating} 2s linear infinite;
    }
  }
`;

const Logo = styled.div`
  display: none;

  @media (min-width: 1024px) {
    width: 75px;
    height: 75px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    display: block;
  }
`;

const BNB = styled.span`
  color: #f1ff51;
`;

const AI = styled.span`
  color: #ffa0a0;
`;

const Link = styled(NavLink)`
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: #c4fcb6;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }

  &.active {
    color: #c4fcb6;
  }
`;

const DisabledLink = styled.a``;

const MobileBalances = styled.div`
  margin: 30px 0 0 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const BalancesLabel = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
`;

const DisconnectMobileButton = styled.div`
  margin: 14px auto 0 auto;
  height: 40px;
  width: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 45px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const DisconnectMobileButtonTitle = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
`;

const LogoutLabel = styled.span`
  display: none;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
`;

const FrensBlockRow = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const FrensBlockRowDesktop = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 90px;
  }
`;

const Styles = {
  Wrapper,
  Nav,
  Name,
  Hamburger,
  Collapse,
  MobileMenuTitle,
  CloseIcon,
  Links,
  Actions,
  Button,
  ButtonLabel,
  WalletRow,
  Socials,
  SocialLink,
  SocialImage,
  ConnectWalletRow,
  ConnectWalletButton,
  ConnectWalletButtonTitle,
  BalancesRow,
  BalanceLabel,
  Balances,
  BalanceTitle,
  ConnectWalletDot,
  LogoRow,
  Logo,
  BNB,
  AI,
  Link,
  DisabledLink,
  MobileBalances,
  BalancesLabel,
  DisconnectMobileButton,
  DisconnectMobileButtonTitle,
  LogoutLabel,
  FrensBlockRow,
  FrensBlockRowDesktop,
};

export default Styles;
