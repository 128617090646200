import styled from "styled-components";

import background from "../../assets/other/statsBlock.png";
import lockIcon from "../../assets/icons/lock.png";

const Container = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 208, 33, 0.13),
    rgba(0, 208, 33, 0.13)
  );
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 18px 20px 18px;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    padding: 30px 0 35px 0;
  }
`;

const LockIcon = styled.div`
  background-image: url(${lockIcon});
  background-repeat: no-repeat;
  background-size: cover;
  width: 71px;
  height: 71px;
`;

const Title = styled.h3`
  margin: 0 0 0 27px;
  font-weight: normal;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
`;

const Styles = {
  Container,
  LockIcon,
  Title,
};

export default Styles;
