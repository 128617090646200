import styled from "styled-components";

const Container = styled.div`
  margin: 80px 50px 0 50px;

  @media (min-width: 1024px) {
    margin: 50px 0 0 0;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 39px;
    line-height: 48px;
  }
`;

const MarketplaceLabel = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #9dea8a;

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 37px;
    text-align: left;
  }
`;

const Markets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 0 -130px;
  }
`;

const Market = styled.img`
  margin: 22px 0 0 0;
  height: 40px;
  width: 100%;
  object-fit: contain;

  @media (min-width: 1024px) {
    margin: 0 20px;
  }
`;

const JailRow = styled.div`
  margin: 80px 0 0 0;

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

const JailHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PepeJail = styled.img`
  margin: 0;
  width: 126px;
  height: 92px;
  margin-bottom: -30px;
  margin-left: -30px;

  @media (min-width: 1024px) {
    margin-left: 0;
  }
`;

const JailTitle = styled.p`
  margin: 0;
  white-space: nowrap;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #9dea8a;
`;

const SoonButton = styled.a`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff4752;
  border-radius: 45px;
  position: relative;
  text-decoration: none;
`;

const SoonButtonTitle = styled.p`
  margin: 0;
  font-size: 36px;
  line-height: 45px;
  color: #ffffff;
`;

const JailText = styled.p`
  margin: 20px 0 0 0;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const PepeJail2 = styled.img`
  width: 138px;
  height: 101px;
  margin-bottom: -20px;
  margin-left: -20px;
`;

const Link = styled.a`
  text-decoration: none;

  @media (min-width: 1024px) {
    width: 33%;

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4),
    &:nth-child(5) {
      margin-top: 20px;
    }

    &:nth-child(2) {
      img {
        width: 80%;
      }
    }
  }
`;

const Styles = {
  Container,
  Title,
  MarketplaceLabel,
  Markets,
  Market,
  JailRow,
  JailHeading,
  PepeJail,
  JailTitle,
  SoonButton,
  SoonButtonTitle,
  JailText,
  PepeJail2,
  Link,
};

export default Styles;
