import styled from "styled-components";

type TPreloaderProps = {
  isVisible: boolean;
};

const Wrapper = styled.div``;

const Preloader = styled.div`
  background-color: #00130d;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: ${({ isVisible }: TPreloaderProps) =>
    isVisible ? "block" : "none"};
`;

const Title = styled.h1`
  margin: 0;
  text-align: center;
  color: rgba(196, 252, 182, 0.05);
  padding-top: 10%;
  font-size: 40px;
  line-height: 50px;
  filter: blur(2px);

  @media (min-width: 1024px) {
    font-size: 180px;
    line-height: 43px;
    filter: blur(4px);
  }
`;

const Loader = styled.img`
  width: 213px;
  height: 163px;
  margin: 50px 0 0 0;

  @media (min-width: 1024px) {
    width: 426px;
    height: 326px;
    margin: 0;
  }
`;

const Styles = {
  Wrapper,
  Preloader,
  Title,
  Loader,
};

export default Styles;
