import * as React from "react";

// Components
import Container from "../Container";

// Assets
import ainft from "../../assets/other/ainft.svg";

// Styles
import Styles from "./styles";

const RickPortal: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Styles.Row>
          <Styles.DesktopMagic>
            <Styles.LevitateNFT />
            <Styles.LevitatePepe />
            <Styles.LevitateAi />
            <Styles.LevitateAiNft />
            <Styles.LevitateAiPepe2 />
            <Styles.LevitateAi2 />
            <Styles.LevitatePepe3 />
            <Styles.LevitateAi3 />
            <Styles.LevitateNFT2 />
            <Styles.LevitateNFT3 />
            <Styles.LevitateAiNft2 />
          </Styles.DesktopMagic>
          <Styles.MobileWord src={ainft} />
          <Styles.PortalRow>
            <Styles.Portal />
            <Styles.Live
              href="https://www.youtube.com/channel/UC0y5EukEdXiVzfrXp0TuMFA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Styles.LiveDot />
              <Styles.LiveText>Live on YouTube</Styles.LiveText>
            </Styles.Live>
          </Styles.PortalRow>
          <Styles.MobileWord src={ainft} />
        </Styles.Row>
      </Container>
    </Styles.Wrapper>
  );
};

export default RickPortal;
