import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// Styles
import Styles from "./styles";

const WhatToDo: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Styles.TitleRow>
          <Styles.Title>What to do?</Styles.Title>
          <Styles.BlurTitle>What to do?</Styles.BlurTitle>
        </Styles.TitleRow>
        <Flex flexWrap="wrap" width="100%" pt={[20, 20, 70, 70]}>
          <Box width={[1, 1, 1 / 3, 1 / 3]} pr={[0, 0, 40, 40]}>
            <Styles.List>
              <Styles.ListTitle>1</Styles.ListTitle>
              <Styles.ListText>
                Get PEPE from the Initial PEPE distribution or marketpalce
              </Styles.ListText>
            </Styles.List>
          </Box>
          <Box
            width={[1, 1, 1 / 3, 1 / 3]}
            pl={[0, 0, 40, 40]}
            pr={[0, 0, 40, 40]}
          >
            <Styles.List>
              <Styles.ListTitle>2</Styles.ListTitle>
              <Styles.ListText>
                HODL neural pepe NFT to farm $AI
              </Styles.ListText>
            </Styles.List>
          </Box>
          <Box width={[1, 1, 1 / 3, 1 / 3]} pl={[0, 0, 40, 40]}>
            <Styles.List>
              <Styles.ListTitle>3</Styles.ListTitle>
              <Styles.ListText>
                Use $AI to rename your PEPE and vote for the next neural
                collection
              </Styles.ListText>
            </Styles.List>
          </Box>
        </Flex>
        <Styles.Button to="/my-collection">
          <Styles.ButtonLabel>CLAIM YOUR $AI</Styles.ButtonLabel>
        </Styles.Button>
      </Container>
    </Styles.Wrapper>
  );
};

export default WhatToDo;
