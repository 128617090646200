/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useWallet } from "bscuw";
import { Flex, Box } from "reflexbox";
import Countdown from "react-countdown";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import ConnectWallet from "../../components/ConnectWallet";

// Assets
import preloaderImage from "../../assets/gif/preloader.gif";

// Modals
import ConnectWalletModal from "../../modals/ConnectWallet";

// Api
import {
  getChallenges,
  IChallengeSection,
  IChallenge,
  getPepeTraits,
  IPepeInfo,
  url,
  IPepeAttribute,
} from "../../utils/api";

// Blockchain
import { getPepeBalance, getPepeIndex } from "../../blockchain/utils";

// Styles
import Styles from "./styles";

const Challenges: React.FC = () => {
  const [activeModal, setActiveModal] = React.useState<null | "connectWallet">(
    null
  );
  const [challenges, setChallenges] = React.useState<
    IChallengeSection[] | null
  >(null);
  const [pepeAmount, setPepeAmount] = React.useState<null | number>(null);
  const [pepesIndex, setPepesIndex] = React.useState<number[]>([]);
  const [pepesTraits, setPepesTraits] = React.useState<IPepeInfo[]>([]);
  const [pepes, setPepes] = React.useState<string[]>([]);
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [totalSuccess, setTotalSuccess] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const { ethereum, status, account } = useWallet();
  const isWalletConnected = status === "connected";

  React.useEffect(() => {
    if (isWalletConnected && isWalletConnected) {
      setActiveModal(null);
    }
  }, [isWalletConnected]);

  React.useEffect(() => {
    if (account !== null && pepeAmount === null) {
      onGetPepeAmount();
    }
  }, [account, pepeAmount]);

  React.useEffect(() => {
    onGetChallenges();
  }, []);

  React.useEffect(() => {
    if (pepeAmount === 0 && !isNotFound) {
      setIsNotFound(true);
      setIsLoading(false);
    }
  }, [pepeAmount, isNotFound]);

  React.useEffect(() => {
    if (Number(pepeAmount) > 0 && pepesIndex.length === 0) {
      getAllPepesIndex();
    }
  }, [pepeAmount, pepesIndex]);

  React.useEffect(() => {
    if (Number(pepeAmount) > 0 && pepesIndex.length && !pepesTraits.length) {
      onGetPepesTraits();
    }
  }, [pepesIndex, pepesTraits, pepeAmount]);

  React.useEffect(() => {
    if (
      pepesTraits.length === pepeAmount &&
      Number(pepeAmount) > 0 &&
      pepes.length === 0
    ) {
      checkPepes();
    }
  }, [pepeAmount, pepesTraits]);

  const toLower = (text: string): string => {
    return text.toLowerCase();
  };

  const getPepesBySpecialType = (type: string): IPepeInfo[] => {
    return pepesTraits.filter((pepe: IPepeInfo) => {
      return pepe.attributes.find(
        (attribute: IPepeAttribute) =>
          toLower(attribute.trait_type) === "special" &&
          toLower(attribute.value) === type
      );
    });
  };

  const checkPepes = () => {
    const getActiveChallenge = challenges?.filter(
      (challenge: IChallengeSection) => challenge.type === "active"
    );

    if (getActiveChallenge?.length) {
      const getSuccessPepes = [];

      const getCutePepes = getPepesBySpecialType("cute");
      const getCursedPepes = getPepesBySpecialType("cursed");

      if (getCutePepes.length >= 2 && getCursedPepes.length >= 2) {
        getSuccessPepes.push([
          ...getCutePepes.slice(0, 2),
          ...getCursedPepes.slice(0, 2),
        ]);
      }

      setTotalSuccess(getCutePepes.length + getCursedPepes.length);

      const mapSuccessPepes = [
        ...getCutePepes.slice(0, 2),
        ...getCursedPepes.slice(0, 2),
      ].map((pepe: IPepeInfo) => pepe.image);
      setPepes(mapSuccessPepes);
      setIsLoading(false);

      if (mapSuccessPepes.length === 0 && mapSuccessPepes.length < Number(4)) {
        setIsNotFound(true);
      }
    }
  };

  const onGetPepesTraits = async () => {
    let data: IPepeInfo[] = [];

    for (const pepe of pepesIndex) {
      const pepeInfo = await getPepeTraits(pepe);

      if (pepeInfo) {
        data.push(pepeInfo);
      }
    }

    setPepesTraits(data);
  };

  const getAllPepesIndex = async () => {
    if (pepeAmount !== null && Number(pepeAmount) > 0) {
      let data: number[] = [];

      for (let i = 0; i < pepeAmount; i++) {
        const pepeIndex = await getPepeIndex(i, account, ethereum);
        data.push(pepeIndex);
      }
      setPepesIndex(data);
    }
  };

  const onGetPepeAmount = async () => {
    const data = await getPepeBalance(account, ethereum);

    setPepeAmount(Number(data));
  };

  const onGetChallenges = async (): Promise<void> => {
    setChallenges(await getChallenges(account));
  };

  const onConnectWallet = (): void => {
    setActiveModal("connectWallet");
  };

  const onViewAllUserPepes = () => {
    const getActiveChallenge = challenges?.filter(
      (challenge: IChallengeSection) => challenge.type === "active"
    );

    if (getActiveChallenge?.length && getActiveChallenge[0].challenges[0]) {
      const { attributes } = getActiveChallenge[0].challenges[0];

      if (attributes) {
        const mapAttributes = attributes
          .map(
            (i, index) =>
              `${i.reqType}=${i.reqValue}${
                index === attributes.length - 1 ? "" : "&"
              }`
          )
          .join("");
        const url = `${location.origin}/explore-all?address=${account}&${mapAttributes}`;

        window?.open(url, "_blank")?.focus();
      }
    }
  };

  const toProper = (data?: string): string | undefined => {
    if (data) {
      return data.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    return data;
  };

  const onGetIn = () => {
    const getActiveChallenge = challenges?.filter(
      (challenge: IChallengeSection) => challenge.type === "active"
    );

    const replaceType = (type: string): string => {
      return type.replace(/\_/g, "%20");
    };

    if (getActiveChallenge?.length && getActiveChallenge[0].challenges[0]) {
      const { attributes } = getActiveChallenge[0].challenges[0];

      if (attributes?.length) {
        const link = attributes
          .map(
            (i, index) =>
              `${toProper(replaceType(i.reqType))}%3A${toProper(i.reqValue)}${
                index === attributes.length - 1 ? "" : "%2C"
              }`
          )
          .join("");

        window
          ?.open(
            `https://nftkey.app/collections/neuralpepe/?filters=${link}`,
            "_blank"
          )
          ?.focus();
      }
    }
  };

  const onPepeClick = (isActive: any): void => {
    if (!pepes.length && !isNotFound) {
      return;
    } else {
      if (isActive) {
        onViewAllUserPepes();
      } else {
        onGetIn();
      }
    }
  };

  const renderPepes = (amount?: number) => {
    if (amount) {
      return (
        <Styles.PepesRow>
          {Array(Math.ceil(amount / 5))
            .fill("pepesRow")
            .map((item: string, index: number) => {
              const total = amount - index * 5 > 5 ? 5 : amount - index * 5;
              const amountPercent = 100 / total;

              const pepesIndex = Array(total)
                .fill("pepe")
                .map((i, pindex) => pindex + index * 5);

              const progressRowWidth = total * 20;
              const progressLineWidth =
                amountPercent *
                pepes.filter((i: string, pepeIndex: number) =>
                  pepesIndex.includes(pepeIndex)
                ).length;

              return (
                <Styles.PepesBlock key={`${item}/${index}`}>
                  <Styles.PepesList>
                    {Array(total)
                      .fill("pepecard")
                      .map((pepeItem: string, pepeIndex: number) => {
                        const getActivePepe = pepes?.[pepeIndex + index * 5]
                          ? pepes[pepeIndex + index * 5]
                          : null;

                        return (
                          <Styles.Pepes
                            key={`${pepeItem}/${pepeIndex}`}
                            isBroken={
                              (!getActivePepe && pepes.length > 0) || isNotFound
                            }
                            isLoading={!pepes.length && !isNotFound}
                          >
                            {getActivePepe ? (
                              <Styles.PepesImage
                                src={getActivePepe}
                                alt="pepes"
                              />
                            ) : null}
                            {(isNotFound || !getActivePepe) && !isLoading ? (
                              <>
                                <Styles.BrokenPepe />
                                <Styles.GetIt>Get it</Styles.GetIt>
                              </>
                            ) : null}
                            {isLoading ? <Styles.PepeLoading /> : null}
                            {getActivePepe && pepes.length ? (
                              <>
                                <Styles.GetIt>View all</Styles.GetIt>
                              </>
                            ) : null}
                          </Styles.Pepes>
                        );
                      })}
                  </Styles.PepesList>
                  {pepes.length ? (
                    <Styles.PepesProgressRow width={progressRowWidth}>
                      <Styles.PepesProgress width={progressLineWidth} />
                    </Styles.PepesProgressRow>
                  ) : null}
                </Styles.PepesBlock>
              );
            })}
        </Styles.PepesRow>
      );
    }
    return null;
  };

  // const parseReqType = (data: string): string => {
  //   if (data === "background_color") {
  //     return "background";
  //   }
  //   return data;
  // };

  return (
    <Styles.Wrapper>
      <Navbar />
      <Styles.Main>
        <Container>
          <Styles.Title>CHALLENGES</Styles.Title>

          <Styles.Description>
            It’s simple. Challenge is the way you can be rewarded with unique
            NFT. Collect a set of required Neural Pepes and complete a challenge
            by holding special ones until the timer expires. Then the ancient
            god of Kek will bestow an NFT upon you!
          </Styles.Description>

          {challenges === null ? (
            <Styles.Loader src={preloaderImage} alt="loader" />
          ) : null}

          {!isWalletConnected && challenges !== null ? (
            <ConnectWallet onConnect={onConnectWallet} />
          ) : null}

          {isWalletConnected && challenges !== null ? (
            <Styles.ChallengesList>
              {challenges.map((sections: IChallengeSection, index: number) => {
                const { type, challenges: challengesList } = sections;

                if (type === "active" && challengesList.length) {
                  return (
                    <Styles.ChallengeSection key={type}>
                      <Styles.ChallengeTitleRow>
                        <Styles.ChallengeSectionTitle>
                          Active
                        </Styles.ChallengeSectionTitle>
                        <Styles.ChallengeTitleLine color="#39F585" />
                      </Styles.ChallengeTitleRow>

                      {challengesList.map((active: IChallenge) => {
                        const {
                          title,
                          // pepesAmount,
                          timer,
                          detailsLink,
                          rewardAuthor,
                          rewardName,
                          // attributes,
                          file,
                        } = active;

                        return (
                          <Styles.ActiveChallenge key={title}>
                            <Flex width="100%" flexWrap="wrap">
                              <Box width={[1, 1, 1 / 2, 1 / 2]}>
                                <Styles.ChallengeInfo>
                                  {totalSuccess !== null ? (
                                    <Styles.CountdownRow>
                                      <Styles.ValidPepes>
                                        {totalSuccess} NPs valid
                                      </Styles.ValidPepes>
                                    </Styles.CountdownRow>
                                  ) : null}
                                  <Styles.ChallengeTitle>
                                    {title}
                                  </Styles.ChallengeTitle>

                                  {renderPepes(4)}

                                  {/* {attributes?.length ? (
                                    <Styles.Hint>
                                      You need to collect at least {pepesAmount}{" "}
                                      Neural Pepe with{" "}
                                      {attributes.map(
                                        (i, index) =>
                                          `${i.reqValue} ${parseReqType(
                                            i.reqType
                                          )}${
                                            index === attributes.length - 1
                                              ? ""
                                              : ", "
                                          }`
                                      )}
                                    </Styles.Hint>
                                  ) : null} */}
                                  <Styles.Hint>
                                    You need to collect 2 Neural Pepe with Cute
                                    special and 2 with Cursed special
                                  </Styles.Hint>

                                  <Styles.Hint>
                                    If you have them, no additional actions
                                    needed
                                  </Styles.Hint>
                                </Styles.ChallengeInfo>
                              </Box>
                              <Box width={[1, 1, 1 / 2, 1 / 2]}>
                                <Styles.Reward>
                                  {timer?.length ? (
                                    <Styles.CountdownRow>
                                      <Countdown date={new Date(timer)} />
                                    </Styles.CountdownRow>
                                  ) : null}

                                  <Styles.RewardRow>
                                    <Styles.RewardTitle>
                                      Reward
                                    </Styles.RewardTitle>
                                    <Styles.RewardInfo>
                                      {file ? (
                                        <Styles.RewardImage
                                          src={`${url}/challenges/image/${file.filename}`}
                                        />
                                      ) : null}
                                      <Styles.RewardContent>
                                        {rewardName?.length ? (
                                          <Styles.RewardName>
                                            {rewardName}
                                          </Styles.RewardName>
                                        ) : null}

                                        {rewardAuthor?.length ? (
                                          <Styles.Author>
                                            Author: {rewardAuthor}
                                          </Styles.Author>
                                        ) : null}

                                        {detailsLink?.length ? (
                                          <Styles.DetailsButton
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={detailsLink}
                                          >
                                            <Styles.DetailsButtonTitle>
                                              View details
                                            </Styles.DetailsButtonTitle>
                                          </Styles.DetailsButton>
                                        ) : null}
                                      </Styles.RewardContent>
                                    </Styles.RewardInfo>
                                  </Styles.RewardRow>
                                </Styles.Reward>
                              </Box>
                            </Flex>
                          </Styles.ActiveChallenge>
                        );
                      })}
                    </Styles.ChallengeSection>
                  );
                }

                if (type === "upcoming" && challengesList.length) {
                  return (
                    <Styles.ChallengeSection key={type}>
                      <Styles.ChallengeTitleRow>
                        <Styles.ChallengeSectionTitle>
                          Upcoming
                        </Styles.ChallengeSectionTitle>
                        <Styles.ChallengeTitleLine color="#0092C0" />
                      </Styles.ChallengeTitleRow>
                      {challengesList.map((item: IChallenge) => {
                        const { title } = item;

                        return (
                          <Styles.UpcomingChallenge key={title}>
                            <Styles.UpcomingChallengeTitle>
                              {title}
                            </Styles.UpcomingChallengeTitle>
                            <Styles.UpcomingChallengeSoon>
                              COMING SOON
                            </Styles.UpcomingChallengeSoon>
                          </Styles.UpcomingChallenge>
                        );
                      })}
                    </Styles.ChallengeSection>
                  );
                }

                if (type === "ended" && challenges.length) {
                  return (
                    <Styles.ChallengeSection key={type}>
                      <Styles.ChallengeTitleRow>
                        <Styles.ChallengeSectionTitle>
                          Ended
                        </Styles.ChallengeSectionTitle>
                        <Styles.ChallengeTitleLine color="#EC0000" />
                      </Styles.ChallengeTitleRow>
                      {challengesList.map((item: IChallenge) => {
                        const { title, nftLink, isWin = false } = item;

                        return (
                          <Styles.Link
                            href={nftLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Styles.UpcomingChallenge key={title}>
                              {isWin ? <Styles.Win /> : <Styles.Lose />}
                              <Styles.UpcomingChallengeTitle>
                                {title}
                              </Styles.UpcomingChallengeTitle>
                              <Styles.UpcomingChallengeSoon>
                                VIEW NFTs
                              </Styles.UpcomingChallengeSoon>
                            </Styles.UpcomingChallenge>
                          </Styles.Link>
                        );
                      })}
                    </Styles.ChallengeSection>
                  );
                }

                return null;
              })}
            </Styles.ChallengesList>
          ) : null}
        </Container>
      </Styles.Main>
      <Footer />

      <ConnectWalletModal
        isOpen={activeModal === "connectWallet"}
        onClose={() => setActiveModal(null)}
      />
    </Styles.Wrapper>
  );
};

export default Challenges;
