import * as React from "react";
import { NavLink } from "react-router-dom";

// Styles
import Styles from "./styles";

interface Props {
  label: string;
  mt?: number;
  isActive?: boolean;
  link?: string;
  onClick?: () => void;
  isMobileBig?: boolean;
  desktopWidth?: number;
}

const Button: React.FC<Props> = (props) => {
  const {
    label,
    mt,
    isActive,
    link,
    onClick,
    isMobileBig,
    desktopWidth,
  } = props;

  if (link) {
    return (
      <NavLink to={link}>
        <Styles.Container mt={mt} isActive={isActive} onClick={onClick}>
          <Styles.Label>{label}</Styles.Label>
        </Styles.Container>
      </NavLink>
    );
  }

  return (
    <Styles.Container
      mt={mt}
      isActive={isActive}
      onClick={onClick}
      isMobileBig={isMobileBig}
      desktopWidth={desktopWidth}
    >
      <Styles.Label>{label}</Styles.Label>
    </Styles.Container>
  );
};

export default Button;
