import * as React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useWallet } from "bscuw";

// Wallets logo
import metamaskLogo from "../../assets/wallets/metamask.svg";
import walletconnectLogo from "../../assets/wallets/walletconnect.svg";
import safepalLogo from "../../assets/wallets/safepal.svg";
import trustLogo from "../../assets/wallets/trust.svg";
import bscLogo from "../../assets/wallets/bsc.svg";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ConnectWalletModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const { connect, account } = useWallet();

  const [selectedConnector, setConnector] = React.useState<string>("");

  const ethereumProvider = (window as any).ethereum;

  const connectWallet = (
    connectorId: "injected" | "walletconnect" | "bsc"
  ): void => {
    setConnector(connectorId);
    connect(connectorId);
  };

  React.useEffect(() => {
    if (account && selectedConnector) {
      localStorage.setItem("connectorId", selectedConnector);
    }
  }, [account, onClose, selectedConnector]);

  return (
    <Modal open={isOpen} onClose={onClose} center showCloseIcon={false}>
      <Styles.Wrapper>
        <Styles.Heading>
          <Styles.Title>CONNECT WALLET</Styles.Title>
          <Styles.CloseIcon onClick={onClose}>X</Styles.CloseIcon>
        </Styles.Heading>

        {ethereumProvider?.isTrust ? (
          <Styles.VisitSite>
            Please try connecting using your regular mobile browser instead of
            in-built DAPP browser
          </Styles.VisitSite>
        ) : null}

        <Styles.WalletBlock onClick={() => connectWallet("injected")}>
          <Styles.WalletIcon
            src={metamaskLogo}
            width={58}
            height={53}
            alt="metamask logo"
          />
        </Styles.WalletBlock>
        <Styles.WalletBlock onClick={() => connectWallet("walletconnect")}>
          <Styles.WalletIcon
            src={safepalLogo}
            width={42}
            height={48}
            alt="safepal logo"
          />
          <Styles.WalletIcon
            src={walletconnectLogo}
            width={78}
            height={48}
            alt="wallet connect logo"
          />
          <Styles.WalletIcon
            src={trustLogo}
            width={43.5}
            height={54}
            alt="trust logo"
          />
        </Styles.WalletBlock>
        <Styles.WalletBlock onClick={() => connectWallet("bsc")}>
          <Styles.WalletIcon
            src={bscLogo}
            width={70}
            height={72}
            alt="bsc logo"
          />
        </Styles.WalletBlock>
      </Styles.Wrapper>
    </Modal>
  );
};

export default ConnectWalletModal;
