import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from{
      transform: rotate(0deg);
  }
  to{
      transform: rotate(360deg);
  }
`;

const Container = styled.div`
  padding: 20px 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffffff;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  margin: 0 0 20px 0;

  @media (min-width: 1024px) {
    padding: 15px 35px;
  }
`;

const PepeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PepeInfoRow = styled.div`
  margin: 0 0 0 25px;

  @media (min-width: 1024px) {
    margin: 0 0 0 30px;
  }
`;

const PepeNumber = styled.p`
  margin: 0;
  text-align: center;
  font-size: 15px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    text-align: left;
  }
`;

const Title = styled.p`
  margin: 8px 0 0 0;
  font-size: 15px;
  line-height: 13px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    margin: 12px 0 0 0;
  }
`;

const UpdateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1024px) {
    height: 100%;
  }
`;

const Date = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  color: rgba(213, 255, 202, 0.35);
  width: 68px;

  @media (min-width: 1024px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const UpdateCard = styled.div`
  min-width: 150px;
  padding: 9px 48px 9px 27px;
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: 1024px) {
    width: 100%;
    min-width: auto;
    margin: 0 0 0 23px;
  }
`;

const UpdateType = styled.p`
  margin: 0;
  font-size: 15px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const UpdateIcon = styled.img`
  width: 45px;
  height: 45px;
  position: absolute;
  right: -12px;

  @media (min-width: 1024px) {
    width: 51px;
    height: 51px;
  }

  &:hover {
    cursor: pointer;
    animation: ${rotating} 2s linear infinite;
  }
`;

const Link = styled.a`
  text-decoration: none; ;
`;

const Styles = {
  Container,
  PepeInfo,
  PepeInfoRow,
  PepeNumber,
  Title,
  UpdateRow,
  Date,
  UpdateCard,
  UpdateType,
  UpdateIcon,
  Link,
};

export default Styles;
