import * as React from "react";

// Styles
import Styles from "./styles";

interface Props {
  onConnect: () => void;
}

const ConnectWallet: React.FC<Props> = (props) => {
  const { onConnect } = props;

  return (
    <Styles.Container onClick={onConnect}>
      <Styles.LockIcon />
      <Styles.Title>connect wallet</Styles.Title>
    </Styles.Container>
  );
};

export default ConnectWallet;
