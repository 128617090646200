import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// Assets
import wallets from "../../assets/other/wallets.svg";
import nftkey from "../../assets/other/nftkey.svg";
import ai from "../../assets/other/ai.svg";
import ape from "../../assets/other/ape.svg";

// Styles
import Styles from "./styles";

const WhatNext: React.FC = () => {
    return (
        <Styles.Wrapper>
            <Container>
                <Styles.TitleRow>
                    <Styles.Title>What to do with your own pepe?</Styles.Title>
                    <Styles.TitleBlur>
                        What to do with your own pepe?
                    </Styles.TitleBlur>
                </Styles.TitleRow>
                <Flex width="100%" flexWrap="wrap" pt={[0, 0, 120, 120]}>
                    <Box
                        width={[1, 1, 1 / 4, 1 / 4]}
                        pt={[40, 40, 0, 0]}
                        pr={[0, 0, 50, 50]}
                    >
                        <Styles.List>
                            <Styles.ImageRow>
                                <Styles.ListImage
                                    src={wallets}
                                    alt="image"
                                    width={145}
                                    height={127}
                                />
                            </Styles.ImageRow>
                            <Styles.ListText>
                                HODL it in one of the BSC-compatible wallets
                            </Styles.ListText>
                        </Styles.List>
                    </Box>
                    <Box
                        width={[1, 1, 1 / 4, 1 / 4]}
                        pt={[40, 40, 0, 0]}
                        pl={[0, 0, 50, 50]}
                        pr={[0, 0, 50, 50]}
                    >
                        <Styles.List>
                            <Styles.ImageRow>
                                <Styles.ListImage
                                    src={nftkey}
                                    alt="image"
                                    width={111}
                                    height={111}
                                />
                            </Styles.ImageRow>
                            <Styles.Link
                                href="https://nftkey.app/collections/neuralpepe/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Styles.ListText>
                                    Buy and sell on NFTkey marketplace
                                </Styles.ListText>
                            </Styles.Link>
                        </Styles.List>
                    </Box>
                    <Box
                        width={[1, 1, 1 / 4, 1 / 4]}
                        pt={[40, 40, 0, 0]}
                        pl={[0, 0, 50, 50]}
                        pr={[0, 0, 50, 50]}
                    >
                        <Styles.List>
                            <Styles.ImageRow>
                                <Styles.ListImage
                                    src={ai}
                                    alt="image"
                                    width={167}
                                    height={71}
                                />
                            </Styles.ImageRow>
                            <Styles.ListText>
                                Earn $AI tokens to rename pepe or to vote for
                                next collections
                            </Styles.ListText>
                        </Styles.List>
                    </Box>
                    <Box
                        width={[1, 1, 1 / 4, 1 / 4]}
                        pt={[40, 40, 0, 0]}
                        pl={[0, 0, 50, 50]}
                    >
                        <Styles.List>
                            <Styles.ImageRow>
                                <Styles.ListImage
                                    src={ape}
                                    alt="image"
                                    width={114}
                                    height={112}
                                />
                            </Styles.ImageRow>
                            <Styles.Link
                                href="https://app.apeswap.finance/swap?inputCurrency=0x7b617145ECE988b864Cd12f67476C24903f605a9&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Styles.ListText>
                                    Trade earned $AI at the exchange
                                </Styles.ListText>
                            </Styles.Link>
                        </Styles.List>
                    </Box>
                </Flex>
            </Container>
        </Styles.Wrapper>
    );
};

export default WhatNext;
