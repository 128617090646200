import styled from "styled-components";

import pepeArrow from "../../assets/other/pepeArrow.svg";
import pepeArrow2 from "../../assets/other/pepeArrow2.svg";
import spinner from "../../assets/spinner.svg";

type TPepeArrowProps = {
  direction: "left" | "right";
};

type TListTitleProps = {
  length?: number;
};

const Wrapper = styled.div`
  background-color: #00130d;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Row = styled.div`
  padding: 25px 0 0 0;
  border-top: 1px solid #ffffff;
  flex: 1;

  @media (min-width: 1024px) {
    padding: 55px 0 10px 0;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PepeCardRow = styled.div`
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 900px;
  }
`;

const PepeCard = styled.div`
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 20px 45px 30px 45px;

  @media (min-width: 1024px) {
    padding: 30px 60px;
  }
`;

const NamingHistoryRow = styled.div`
  margin: 32px 0 0 0;
`;

const NamingHistoryLabel = styled.h3`
  margin: 0;
  font-size: 35px;
  line-height: 43px;
  text-align: center;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const NamingHistoryBlock = styled.div`
  margin: 32px auto 0 auto;
  width: 100%;
  border: 2px solid #868686;
  filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
  padding: 25px 0 36px 0;

  @media (min-width: 1024px) {
    width: 575px;
  }
`;

const ChangeList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &:not(:first-child) {
    margin: 22px 0 0 0;
  }
`;

const LatestName = styled.p`
  margin: 0;
  flex: 1;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const HistoryInfo = styled.div`
  flex: 1;
`;

const ChangeDate = styled.p`
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);
`;

const Owner = styled.p`
  word-break: break-word;
  margin: 10px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const PepesCoverRow = styled.div`
  margin: 116px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageHint = styled.p`
  margin: 20px 0 0 0;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const PepeNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 40px 0 0 0;
`;

const PepeNameLabel = styled.p`
  margin: 0 16px 0 0;
  font-size: 18px;
  line-height: 22px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const PepeName = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 45px;
  color: #ff9292;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const TraitsLabel = styled.h3`
  margin: 30px 0 0 0;
  font-size: 35px;
  line-height: 43px;
  text-align: center;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const TraitsList = styled.div`
  border: 2px solid #868686;
  filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
  padding: 17px 0 3px 0;
`;

const ListTitle = styled.p`
  margin: 0;
  font-size: ${({ length }: TListTitleProps) =>
    length && length > 7 ? "18px" : "24px"};
  line-height: 30px;
  text-align: center;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const ListText = styled.p`
  margin: 10px 0 0 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const NotFoundRow = styled.div`
  padding: 50px 0;

  @media (min-width: 1024px) {
    padding: 150px 0;
  }
`;

const NotFoundLabel = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #ff9292;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

const PepesLink = styled.a`
  margin: 20px 0 0 0;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const PepeArrow = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    background-image: ${({ direction }: TPepeArrowProps) =>
      `url(${direction === "left" ? pepeArrow : pepeArrow2})`};
    background-repeat: no-repeat;
    background-size: cover;
    width: 56px;
    height: 39px;
    position: absolute;
    left: ${({ direction }: TPepeArrowProps) =>
      direction === "left" ? "0" : "inherit"};
    right: ${({ direction }: TPepeArrowProps) =>
      direction === "right" ? "0" : "inherit"};
    transition: all 0.2s ease-in-out 0s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
`;

const Actions = styled.div`
  margin: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Button = styled.div`
  width: 100%;
  height: 62px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffffff;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:not(:first-child) {
    margin: 20px 0 0 0;
  }

  @media (min-width: 1024px) {
    &:not(:first-child) {
      margin: 0;
    }

    &:first-child {
      margin: 0 10px 0 0;
    }

    &:last-child {
      margin: 0 0 0 10px;
    }
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  font-size: 26px;
  line-height: 30px;
  color: #2ddf00;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 37px;
  }
`;

const Spinner = styled.div`
  width: 45px;
  height: 45px;
  background-image: url(${spinner});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: ${({ direction }: TPepeArrowProps) =>
    direction === "left" ? "0" : "inherit"};
  right: ${({ direction }: TPepeArrowProps) =>
    direction === "right" ? "0" : "inherit"};
  transition: all 0.2s ease-in-out 0s;
`;

const Styles = {
  Wrapper,
  Row,
  Content,
  PepeCardRow,
  PepeCard,
  NamingHistoryRow,
  NamingHistoryLabel,
  NamingHistoryBlock,
  ChangeList,
  LatestName,
  ChangeDate,
  Heading,
  Title,
  Owner,
  PepesCoverRow,
  ImageHint,
  PepeNameRow,
  PepeNameLabel,
  PepeName,
  TraitsLabel,
  TraitsList,
  ListTitle,
  ListText,
  HistoryInfo,
  NotFoundRow,
  NotFoundLabel,
  PepesLink,
  PepeArrow,
  Actions,
  Button,
  ButtonTitle,
  Spinner,
};

export default Styles;
