import styled from "styled-components";

import blur from "../../assets/other/wtfBlur.svg";
import arrow from "../../assets/other/arrow3.svg";
import arrow2 from "../../assets/other/arrow4.svg";
import binance from "../../assets/logo/binance.svg";
import magicBlur from "../../assets/other/magicBlur.png";

type TPepesRowProps = {
  isHideOnMobile?: boolean;
};

const Wrapper = styled.section`
  padding: 48px 0 52px 0;

  @media (min-width: 1024px) {
    padding: 63px 0 0 0;
  }
`;

const TitleRow = styled.div`
  background-image: url(${blur});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;

  @media (min-width: 1024px) {
    background-image: none;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #9dea8a;

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

const PepesBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

const PepesImage = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 112px;

  @media (min-width: 1024px) {
    width: 224px;
    height: 224px;
  }
`;

const PepesRow = styled.div`
  margin: 0 0 0 30px;
  display: ${({ isHideOnMobile }: TPepesRowProps) =>
    isHideOnMobile ? "none" : "block"};

  @media (min-width: 1024px) {
    margin: 0;
    display: block;
  }
`;

const PepesTitle = styled.p`
  margin: 30px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #d5ffca;
`;

const PepesText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #d5ffca;
`;

const Arrow = styled.div`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: contain;
  width: 128px;
  height: 117px;
  transform: rotate(95deg);

  @media (min-width: 1024px) {
    transform: none;
  }
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const Label = styled.p`
  margin: 20px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #d5ffca;
  opacity: 0.45;
`;

const Arrow2 = styled.div`
  background-image: url(${arrow2});
  background-repeat: no-repeat;
  background-size: contain;
  width: 163px;
  height: 75px;
  transform: rotate(90deg) scale(1, -1);
  margin: 0 0 0 -20px;

  @media (min-width: 1024px) {
    transform: none;
    margin: 0;
  }
`;

const BinanceLogo = styled.div`
  background-image: url(${binance});
  background-repeat: no-repeat;
  background-size: contain;
  width: 54px;
  height: 54px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const BinanceLogoDesktop = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    background-image: url(${binance});
    background-repeat: no-repeat;
    background-size: contain;
    width: 54px;
    height: 54px;
  }
`;

const Button = styled.a`
  padding: 15px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #fefdf9;
  border-radius: 45px;
  margin: 70px auto 0 auto;
  width: 350px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
`;

const PepesImageMirror = styled(PepesImage)`
  transform: scaleX(-1);
`;

const MagicPepeRow = styled.div`
  position: relative;
`;

const Magic = styled.div`
  @media (min-width: 1024px) {
    background: radial-gradient(
      43.72% 43.58% at 48.5% 59.67%,
      #fbff50 0%,
      rgba(250, 255, 0, 0.56) 21.35%,
      rgba(250, 255, 0, 0) 96.88%
    );
    width: 440px;
    height: 440px;
    position: absolute;
    top: -160px;
    left: -110px;
  }
`;

const MagicBlur = styled.div`
  background-image: url(${magicBlur});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 173px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 90px;

  @media (min-width: 1024px) {
    width: 140px;
    height: 59px;
    left: 140px;
  }
`;

const MagicBlurLabel = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  -webkit-text-stroke: 1px black;

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 27px;
  }
`;

const MagicBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 40px 0;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`;

const MagicBlockRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Styles = {
  Wrapper,
  Title,
  PepesBlock,
  PepesImage,
  PepesRow,
  PepesTitle,
  PepesText,
  Arrow,
  ContentRow,
  Label,
  Arrow2,
  BinanceLogo,
  Button,
  ButtonTitle,
  TitleRow,
  PepesImageMirror,
  MagicPepeRow,
  Magic,
  MagicBlur,
  MagicBlurLabel,
  MagicBlock,
  MagicBlockRow,
  BinanceLogoDesktop,
};

export default Styles;
