import styled from "styled-components";

const Wrapper = styled.div`
  background: linear-gradient(
      180deg,
      rgba(12, 131, 9, 0.1) 56.77%,
      #234422 100%
    ),
    rgba(0, 0, 0, 0.96);
  border: 8px solid #ffffff;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  padding: 15px 30px 30px 30px;
  width: calc(100% - 30px);
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 430px;
    margin: 0 auto;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CloseIcon = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 36px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  transform: rotate(-179.16deg);

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 48px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 37px;
  }
`;

const WalletBlock = styled.div`
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  margin: 40px 0 0 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  img {
    &:not(:last-child) {
      margin: 0 18px 0 0;
    }
  }

  @media (min-width: 1024px) {
    img {
      &:not(:last-child) {
        margin: 0 30px 0 0;
      }
    }
  }
`;

const WalletIcon = styled.img``;

const VisitSite = styled.p`
  color: #ff5c5c;
  margin: 20px 0 0 0;
`;

const Styles = {
  Wrapper,
  Heading,
  CloseIcon,
  Title,
  WalletBlock,
  WalletIcon,
  VisitSite,
};

export default Styles;
