import styled, { keyframes } from "styled-components";

import background from "../../assets/background/main.png";

import neural from "../../assets/neural.png";
import pepe from "../../assets/pepe.png";
import ainft from "../../assets/other/ainft3.png";

// pepes
import pepe1 from "../../assets/pepes/1.svg";
import pepe2 from "../../assets/pepes/2.svg";
import pepe3 from "../../assets/pepes/3.svg";
import pepe4 from "../../assets/pepes/4.svg";
import pepe14 from "../../assets/pepes/14.svg";
import pepe15 from "../../assets/pepes/15.svg";
import pepe16 from "../../assets/pepes/16.png";
import pepe17 from "../../assets/pepes/17.png";
import pepe18 from "../../assets/pepes/18.png";
import pepe19 from "../../assets/pepes/19.png";
import pepe20 from "../../assets/pepes/20.png";
import mainPepe1 from "../../assets/main/pepe1.png";
import mainPepe2 from "../../assets/main/pepe2.png";
import mainPepe3 from "../../assets/main/pepe3.png";
import mainPepe4 from "../../assets/main/pepe4.png";
import mainPepe5 from "../../assets/main/pepe5.png";
import mainPepe6 from "../../assets/main/pepe6.png";
import mainPepe7 from "../../assets/main/pepe7.png";
import seriousPepe from "../../assets/pepes/serious.png";

import jailPepe from "../../assets/jail.png";

const levitation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0.5em);
  }
`;

const levitation2 = keyframes`
  0% {
    transform: translate(0, 0.1em);
  }
  100% {
    transform: translate(0, 0.3em);
  }
`;

const Wrapper = styled.main`
  background-color: #00130d;
  background-image: url(${background});
  background-repeat: repeat-y;
  background-size: contain;
`;

const Row = styled.div`
  position: relative;
`;

const Words = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 1024px) {
    display: inline-flex;
    z-index: 2;
  }
`;

const NeuralWord = styled.div`
  background-image: url(${neural});
  background-repeat: no-repeat;
  background-size: contain;
  width: 330px;
  height: 102.5px;

  @media (min-width: 1024px) {
    width: 628px;
    height: 195px;
  }
`;

const PepeWord = styled.div`
  background-image: url(${pepe});
  background-repeat: no-repeat;
  background-size: contain;
  width: 125px;
  height: 74px;
  margin: -20px 5px 0 0;

  @media (min-width: 1024px) {
    width: 250px;
    height: 148px;
    margin: -35px 0 0 0;
  }
`;

const Pepes = styled.div`
  position: relative;
  padding: 115px 0;
  display: flex;
  flex-direction: row;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const PepeOne = styled.div`
  width: 66px;
  height: 66px;
  background-image: url(${pepe2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 60px;

  @media (min-width: 1024px) {
    left: 140px;
    width: 47px;
    height: 47px;
    top: 210px;
    animation: ${levitation2} 1.7s alternate ease-in-out infinite;
  }
`;

const PepeTwo = styled.div`
  width: 142px;
  height: 142px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin: 0;
    animation: ${levitation} 1.2s alternate ease-in-out infinite;
  }
`;

const PepeThree = styled.div`
  width: 78px;
  height: 78px;
  background-image: url(${pepe3});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 60px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const PepeFour = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${pepe4});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 10px;
  right: 20px;

  @media (min-width: 1024px) {
    animation: ${levitation2} 1.6s alternate ease-in-out infinite;
  }
`;

const DescriptionBlock = styled.div`
  background: rgba(0, 0, 0, 0.28);
  border: 3px dashed rgba(180, 255, 183, 0.4);
  border-radius: 30px;
  padding: 15px;

  @media (min-width: 1024px) {
    margin: 50px auto 0 auto;
    width: 595px;
    padding: 0;
    border: none;
    background: none;
  }
`;

const Description = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 5px 4px 4px rgb(0 0 0 / 25%);

  @media (min-width: 1024px) {
    font-size: 23px;
    line-height: 30px;
  }
`;

const MorePepes = styled.div`
  margin: 34px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MorePepeImage = styled.img`
  width: 73px;
  height: 73px;
  margin: 0 10px;
`;

const AINFT = styled.div`
  display: none;

  @media (min-width: 1024px) {
    background-image: url(${ainft});
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin: 35px auto 0 auto;
    width: 806px;
    height: 106px;
  }
`;

const Pepe14 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 78px;
    height: 78px;
    background-image: url(${pepe14});
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -80px;
    left: 30px;
    animation: ${levitation2} 1.5s alternate ease-in-out infinite;
  }
`;

const Pepe15 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    background-image: url(${pepe15});
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 270px;
    animation: ${levitation} 1.8s alternate ease-in-out infinite;
  }
`;

const Pepe16 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 73px;
    height: 73px;
    background-image: url(${pepe16});
    background-repeat: no-repeat;
    background-size: contain;
    right: 180px;
    top: 90px;
    animation: ${levitation2} 1.4s alternate ease-in-out infinite;
  }
`;

const Pepe17 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 61px;
    height: 61px;
    background-image: url(${pepe17});
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    top: 0;
    animation: ${levitation} 2s alternate ease-in-out infinite;
  }
`;

const Pepe18 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 112px;
    height: 112px;
    background-image: url(${pepe18});
    background-repeat: no-repeat;
    background-size: contain;
    top: 180px;
    right: -50px;
    animation: ${levitation2} 1.4s alternate ease-in-out infinite;
  }
`;

const Pepe19 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    background-image: url(${pepe19});
    background-repeat: no-repeat;
    background-size: contain;
    width: 78px;
    height: 78px;
    top: 360px;
    right: 110px;
    animation: ${levitation} 1.8s alternate ease-in-out infinite;
  }
`;

const Pepe20 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 112px;
    height: 112px;
    background-image: url(${pepe20});
    background-repeat: no-repeat;
    background-size: contain;
    top: 510px;
    right: 50px;
    animation: ${levitation2} 1.3s alternate ease-in-out infinite;
  }
`;

const DesktopPepes = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Pepe21 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 79px;
    height: 79px;
    background-image: url(${mainPepe1});
    background-repeat: no-repeat;
    background-size: contain;
    left: 270px;
    top: 30px;
    animation: ${levitation} 2s alternate ease-in-out infinite;
  }
`;

const Pepe22 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background-image: url(${mainPepe2});
    background-repeat: no-repeat;
    background-size: contain;
    left: 150px;
    animation: ${levitation2} 1.6s alternate ease-in-out infinite;
  }
`;

const Pepe23 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background-image: url(${mainPepe2});
    background-repeat: no-repeat;
    background-size: contain;
    top: 190px;
    left: 20px;
    animation: ${levitation} 2s alternate ease-in-out infinite;
  }
`;

const Pepe24 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background-image: url(${mainPepe3});
    background-repeat: no-repeat;
    background-size: contain;
    width: 53px;
    height: 53px;
    left: 510px;
    top: 30px;
    animation: ${levitation2} 1.3s alternate ease-in-out infinite;
  }
`;

const Pepe25 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background-image: url(${mainPepe2});
    background-repeat: no-repeat;
    background-size: contain;
    right: 420px;
    top: 20px;
    animation: ${levitation} 1.1s alternate ease-in-out infinite;
  }
`;

const Pepe26 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background-image: url(${mainPepe2});
    background-repeat: no-repeat;
    background-size: contain;
    right: 90px;
    top: 110px;
    animation: ${levitation2} 1s alternate ease-in-out infinite;
  }
`;

const Pepe27 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background-image: url(${mainPepe2});
    background-repeat: no-repeat;
    background-size: contain;
    top: 360px;
    right: -50px;
    animation: ${levitation} 1.5s alternate ease-in-out infinite;
  }
`;

const Pepe28 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 61px;
    height: 61px;
    background-image: url(${mainPepe4});
    background-repeat: no-repeat;
    background-size: contain;
    right: 220px;
    top: 330px;
    animation: ${levitation2} 2.2s alternate ease-in-out infinite;
  }
`;

const Pepe29 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
    background-image: url(${mainPepe5});
    background-repeat: no-repeat;
    background-size: contain;
    right: 260px;
    top: 430px;
    animation: ${levitation} 1.2s alternate ease-in-out infinite;
  }
`;

const Pepe30 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    background-image: url(${mainPepe6});
    background-repeat: no-repeat;
    background-size: contain;
    right: 200px;
    top: 530px;
    animation: ${levitation} 2.3s alternate ease-in-out infinite;
  }
`;

const Pepe31 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    background-image: url(${mainPepe7});
    background-repeat: no-repeat;
    background-size: contain;
    top: 330px;
    left: 480px;
    animation: ${levitation2} 1.6s alternate ease-in-out infinite;
  }
`;

const Seriously = styled.div`
  margin: 80px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    margin: 0;
    position: absolute;
    bottom: -80px;
    right: 210px;
    transition: all 0.2s ease-in-out 0s;

    :hover {
      transform: scale(1.3);
    }
  }
`;

const SeriouslyPepe = styled.div`
  width: 52px;
  height: 52px;
  border: 4px solid #d5ffca;
  border-radius: 26px;
  background-image: url(${seriousPepe});
  background-repeat: no-repeat;
  background-size: contain;
`;

const SeriouslyLabel = styled.p`
  margin: 0 0 0 11px;
  font-size: 20px;
  line-height: 25px;
  color: rgba(213, 255, 202, 0.48);
`;

const EndTitle = styled.p`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #d5ffca;
  width: 195px;
  margin: 30px auto 0 auto;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Countdown = styled.div`
  display: flex;
  justify-content: center;

  span {
    margin: 6px 0 0 0;
    font-size: 50px;
    line-height: 62px;
    color: #ff7171;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const JailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0 0 0;
  padding: 0 58px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const JailPepe = styled.div`
  width: 39.6px;
  height: 69.6px;
  background-image: url(${jailPepe});
  background-repeat: no-repeat;
  background-size: contain;
`;

const JailText = styled.p`
  margin: 0 0 0 16px;
  flex: 1;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Styles = {
  Wrapper,
  Row,
  Words,
  NeuralWord,
  PepeWord,
  Pepes,
  DescriptionBlock,
  Description,
  MorePepes,
  MorePepeImage,
  AINFT,
  PepeOne,
  PepeTwo,
  PepeThree,
  PepeFour,
  Pepe14,
  Pepe15,
  Pepe16,
  Pepe17,
  Pepe18,
  Pepe19,
  Pepe20,
  DesktopPepes,
  Pepe21,
  Pepe22,
  Pepe23,
  Pepe24,
  Pepe25,
  Pepe26,
  Pepe27,
  Pepe28,
  Pepe29,
  Pepe30,
  Pepe31,
  Seriously,
  SeriouslyPepe,
  SeriouslyLabel,
  EndTitle,
  Countdown,
  JailRow,
  JailPepe,
  JailText,
};

export default Styles;
