import styled, { keyframes } from "styled-components";

import portal from "../../assets/other/portal.svg";

import nft from "../../assets/portal/nft.png";
import pepeWord from "../../assets/portal/pepe.png";
import aiWord from "../../assets/portal/ai.png";
import aiNftWord from "../../assets/portal/ainft.png";
import pepeWord2 from "../../assets/portal/pepe2.png";
import aiWord2 from "../../assets/portal/ai2.png";
import pepeWord3 from "../../assets/portal/pepe3.png";
import aiWord3 from "../../assets/portal/ai3.png";
import nftWord2 from "../../assets/portal/nft2.png";
import nftWord3 from "../../assets/portal/nft3.png";
import aiNftWord2 from "../../assets/portal/aiNft2.png";

const rotating = keyframes`
  from{
    -webkit-transform: rotate(0deg);
  }
  to{
    -webkit-transform: rotate(360deg);
  }
`;

const levitation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0.5em);
  }
`;

const levitation2 = keyframes`
  0% {
    transform: translate(0, 0.1em);
  }
  100% {
    transform: translate(0, 0.3em);
  }
`;

const Wrapper = styled.section`
  margin: 50px 0 0 0;
  position: relative;

  @media (min-width: 1024px) {
    margin: 183px 0 0 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileWord = styled.img`
  width: 218px;
  height: 60px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Portal = styled.div`
  background-image: url(${portal});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 60px 0;
  width: 234px;
  height: 242px;
  animation: ${rotating} 2s linear infinite;
`;

const PortalRow = styled.div``;

const Live = styled.a`
  margin: -40px 0 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const LiveDot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #ff3434;
  border-radius: 3px;
`;

const LiveText = styled.p`
  margin: 0 0 0 8px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: rgba(213, 255, 202, 0.75);
`;

const DesktopMagic = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const LevitateNFT = styled.div`
  width: 102px;
  height: 63px;
  background-image: url(${nft});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -160px;
  left: 90px;
  transform: matrix(0.99, 0.12, -0.1, 0.99, 0, 0);
  animation: ${levitation} 1.2s alternate ease-in-out infinite;
`;

const LevitatePepe = styled.div`
  width: 195px;
  height: 135px;
  background-image: url(${pepeWord});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  animation: ${levitation2} 1.5s alternate ease-in-out infinite;
`;

const LevitateAi = styled.div`
  width: 57px;
  height: 46px;
  background-image: url(${aiWord});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 308px;
  top: 100px;
  animation: ${levitation2} 2.6s alternate ease-in-out infinite;
`;

const LevitateAiNft = styled.div`
  width: 169px;
  height: 60px;
  background-image: url(${aiNftWord});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 200px;
  top: 260px;
  animation: ${levitation} 1.3s alternate ease-in-out infinite;
`;

const LevitateAiPepe2 = styled.div`
  width: 70px;
  height: 57px;
  background-image: url(${pepeWord2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 550px;
  top: 10px;
  animation: ${levitation2} 1.8s alternate ease-in-out infinite;
`;

const LevitateAi2 = styled.div`
  width: 65px;
  height: 64px;
  background-image: url(${aiWord2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 523px;
  animation: ${levitation} 1.5s alternate ease-in-out infinite;
`;

const LevitatePepe3 = styled.div`
  width: 94px;
  height: 78px;
  background-image: url(${pepeWord3});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 410px;
  top: 90px;
  animation: ${levitation} 1.8s alternate ease-in-out infinite;
`;

const LevitateAi3 = styled.div`
  width: 68px;
  height: 56px;
  background-image: url(${aiWord3});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 230px;
  right: 400px;
  animation: ${levitation2} 1.5s alternate ease-in-out infinite;
`;

const LevitateNFT2 = styled.div`
  width: 82px;
  height: 44px;
  background-image: url(${nftWord2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 260px;
  top: 190px;
  animation: ${levitation2} 2.6s alternate ease-in-out infinite;
`;

const LevitateNFT3 = styled.div`
  width: 225px;
  height: 160px;
  background-image: url(${nftWord3});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 110px;
  top: -120px;
  animation: ${levitation2} 1.5s alternate ease-in-out infinite;
`;

const LevitateAiNft2 = styled.div`
  width: 124px;
  height: 172px;
  background-image: url(${aiNftWord2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 190px;
  animation: ${levitation} 1.5s alternate ease-in-out infinite;
`;

const Styles = {
  Wrapper,
  Row,
  MobileWord,
  Portal,
  PortalRow,
  Live,
  LiveDot,
  LiveText,
  DesktopMagic,
  LevitateNFT,
  LevitatePepe,
  LevitateAi,
  LevitateAiNft,
  LevitateAiPepe2,
  LevitateAi2,
  LevitatePepe3,
  LevitateAi3,
  LevitateNFT2,
  LevitateNFT3,
  LevitateAiNft2,
};

export default Styles;
