import * as React from "react";
import { Flex, Box } from "reflexbox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// Components
import Pepes from "../Pepes";

// Assets
import pepeIcon from "../../assets/updates/pepe.svg";
import nftkeyIcon from "../../assets/updates/nftkey.svg";

// Styles
import Styles from "./styles";

interface Props {
    pepeNumber: number;
    type: "generation" | "renaming" | "sales" | "listing";
    date: Date;
    value: string;
    tx: string;
}

const UpdatesCard: React.FC<Props> = (props) => {
    const { pepeNumber, type, date, value, tx } = props;

    dayjs.extend(utc);

    const getTitle = (): string => {
        if (type === "generation") {
            return `Generated on IPO at ${value}`;
        } else if (type === "renaming") {
            return `Renamed to “${value}”`;
        } else if (type === "sales") {
            return `Sold on marketplace for ${value}`;
        }
        return `Listed on marketplace for ${value}`;
    };

    const getLink = (): string => {
        if (type === "sales") {
            return tx;
        } else if (type === "listing") {
            return `https://nftkey.app/collections/neuralpepe/token-details/?tokenId=${pepeNumber}`;
        }

        return `https://neuralpepe.com/pepe/${pepeNumber}`;
    };

    return (
        <Styles.Link href={getLink()} target="_blank" rel="noopener noreferrer">
            <Styles.Container>
                <Flex width="100%" flexWrap="wrap">
                    <Box width={[1, 1, 1 / 2, 1 / 2]}>
                        <Styles.PepeInfo>
                            <Pepes
                                number={pepeNumber}
                                width={57}
                                height={57}
                                desktopSize={68}
                                onlyNumber
                            />
                            <Styles.PepeInfoRow>
                                <Styles.PepeNumber>
                                    NEURAL PEPE #{pepeNumber}
                                </Styles.PepeNumber>
                                <Styles.Title>{getTitle()}</Styles.Title>
                            </Styles.PepeInfoRow>
                        </Styles.PepeInfo>
                    </Box>
                    <Box width={[1, 1, 1 / 2, 1 / 2]} pt={[13, 13, 0, 0]}>
                        <Styles.UpdateRow>
                            <Styles.Date>
                                {dayjs(date).utc().format("HH:mm")} UTC{" "}
                                {dayjs(date).utc().format("DD:MM:YYYY")}
                            </Styles.Date>

                            <Styles.UpdateCard>
                                <Styles.UpdateType>{type}</Styles.UpdateType>
                                <Styles.UpdateIcon
                                    src={
                                        type === "generation" ||
                                        type === "renaming"
                                            ? pepeIcon
                                            : nftkeyIcon
                                    }
                                />
                            </Styles.UpdateCard>
                        </Styles.UpdateRow>
                    </Box>
                </Flex>
            </Styles.Container>
        </Styles.Link>
    );
};

export default UpdatesCard;
