import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// dappLogos
import dappRadarLogo from "../../assets/dapp/dappradar.png";
import dappLogo from "../../assets/dapp/dapp.png";
import bscprojectLogo from "../../assets/dapp/bscproject.png";

// Styles
import Styles from "./styles";

const ListedOn: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Flex flexWrap="wrap" width="100%">
          <Box
            width={[1, 1, 1 / 4, 1 / 4]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Styles.Title>Seen at:</Styles.Title>
          </Box>
          <Box width={[1, 1, 3 / 4, 3 / 4]} pt={[30, 30, 0, 0]}>
            <Flex flexWrap="wrap" width="100%">
              <Box
                width={[1, 1, 1 / 3, 1 / 3]}
                display="flex"
                justifyContent="center"
                pb={[20, 20, 0, 0]}
              >
                <Styles.Link
                  href="https://dappradar.com/binance-smart-chain/collectibles/neural-pepe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styles.LogoRow>
                    <Styles.Logo src={dappRadarLogo} width={264} height={43} />
                  </Styles.LogoRow>
                </Styles.Link>
              </Box>
              <Box
                width={[1, 1, 1 / 3, 1 / 3]}
                display="flex"
                justifyContent="center"
                pb={[20, 20, 0, 0]}
              >
                <Styles.Link
                  href="https://www.dapp.com/app/neural-pepe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styles.LogoRow>
                    <Styles.Logo src={dappLogo} width={185} height={67} />
                  </Styles.LogoRow>
                </Styles.Link>
              </Box>
              <Box
                width={[1, 1, 1 / 3, 1 / 3]}
                display="flex"
                justifyContent="center"
              >
                <Styles.Link
                  href="https://bscproject.org/#/project/614"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styles.LogoRow>
                    <Styles.Logo src={bscprojectLogo} width={188} height={45} />
                  </Styles.LogoRow>
                </Styles.Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Styles.Wrapper>
  );
};

export default ListedOn;
