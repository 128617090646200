/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useWallet } from "bscuw";
import { ethers } from "ethers";
import useSound from "use-sound";
import { useHistory } from "react-router-dom";

// Components
import Container from "../Container";
import FrensBlock from "../FrensBlock";

import telegramLogo from "../../assets/social/telegram.svg";
import twitterLogo from "../../assets/social/twitter.svg";

// Modals
import ConnectWalletModal from "../../modals/ConnectWallet";

// Blockchain
import { getAIBalance } from "../../blockchain/utils/index";

// Sounds
// @ts-ignore
import getPepeSound from "../../music/getPepe.mp3";
import connectWalletSound from "../../music/connectWallet.mp3";

// Styles
import Styles from "./styles";

interface Props {}

const Navbar: React.FC<Props> = (props) => {
  const {} = props;

  const [play] = useSound(getPepeSound);
  const [playConnectWallet] = useSound(connectWalletSound);
  const history = useHistory();

  const [isCollapsed, setCollapsed] = React.useState<boolean>(false);
  const [activeModal, setActiveModal] = React.useState<
    null | "connectWallet" | "getPepe"
  >(null);
  const [aiBalance, setAiBalance] = React.useState<null | number>(null);

  const { status, balance, account, ethereum, reset } = useWallet();

  React.useEffect(() => {
    if (account) {
      getAITokensBalance();

      if (activeModal === "connectWallet") {
        setActiveModal(null);
      }
    }
  }, [account]);

  const isWalletConnected = status === "connected";

  const getPepe = (): void => {
    setActiveModal("getPepe");
  };

  const getAITokensBalance = async () => {
    const aiTokenBalance = await getAIBalance(account, ethereum);
    setAiBalance(aiTokenBalance);
  };

  const onConnectWallet = (): void => {
    setActiveModal("connectWallet");
  };

  const onVisitCollection = () => {
    setActiveModal(null);
    history.push("/my-collection");
  };

  const signOut = () => {
    reset();
    localStorage.removeItem("connectorId");
  };

  return (
    <>
      <Styles.Wrapper>
        <Container>
          <Styles.Nav>
            <Styles.FrensBlockRowDesktop>
              <FrensBlock />
            </Styles.FrensBlockRowDesktop>
            <Styles.LogoRow to="/">
              <Styles.Logo />
              <Styles.Name>Neural pepe</Styles.Name>
            </Styles.LogoRow>
            <Styles.Hamburger onClick={() => setCollapsed(!isCollapsed)}>
              <span />
              <span />
              <span />
            </Styles.Hamburger>
            <Styles.Collapse isActive={isCollapsed}>
              <Styles.MobileMenuTitle>Menu</Styles.MobileMenuTitle>
              <Styles.CloseIcon onClick={() => setCollapsed(!isCollapsed)}>
                X
              </Styles.CloseIcon>
              <Styles.Links>
                <Styles.Link to="/my-collection" activeClassName="active">
                  My collection
                </Styles.Link>
                <Styles.Link to="/explore-all" activeClassName="active">
                  Explore all
                </Styles.Link>
                <Styles.Link to="/challenges" activeClassName="active">
                  Challenges
                </Styles.Link>
                <Styles.Link to="/updates" activeClassName="active">
                  Updates
                </Styles.Link>
              </Styles.Links>
              <Styles.WalletRow>
                <Styles.Socials>
                  <Styles.SocialLink
                    href="https://t.me/neuralpepe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.SocialImage
                      src={telegramLogo}
                      alt="telegram logo"
                    />
                  </Styles.SocialLink>
                  <Styles.SocialLink
                    href="https://twitter.com/NeuralPepe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styles.SocialImage src={twitterLogo} alt="twitter logo" />
                  </Styles.SocialLink>
                </Styles.Socials>
                <Styles.Actions>
                  <Styles.ConnectWalletRow>
                    <Styles.ConnectWalletButton
                      onClick={() =>
                        !isWalletConnected
                          ? setActiveModal("connectWallet")
                          : signOut()
                      }
                      isActive={isWalletConnected}
                      onMouseEnter={() =>
                        !isWalletConnected ? playConnectWallet() : null
                      }
                    >
                      {isWalletConnected ? <Styles.ConnectWalletDot /> : null}
                      <Styles.LogoutLabel>sign out</Styles.LogoutLabel>
                      <Styles.ConnectWalletButtonTitle>
                        {isWalletConnected
                          ? "wallet connected"
                          : "Connect wallet"}
                      </Styles.ConnectWalletButtonTitle>
                    </Styles.ConnectWalletButton>
                    {isWalletConnected ? (
                      <Styles.BalancesRow>
                        <Styles.BalanceLabel>
                          Wallet balance
                        </Styles.BalanceLabel>
                        <Styles.Balances>
                          <Styles.BalanceTitle>
                            {`${Number(
                              ethers.utils.formatEther(balance)
                            ).toFixed(4)}`}{" "}
                            <Styles.BNB>BNB</Styles.BNB>
                          </Styles.BalanceTitle>
                          <Styles.BalanceTitle>
                            {aiBalance} <Styles.AI>AI</Styles.AI>
                          </Styles.BalanceTitle>
                        </Styles.Balances>
                      </Styles.BalancesRow>
                    ) : null}
                  </Styles.ConnectWalletRow>
                </Styles.Actions>
                {isWalletConnected ? (
                  <Styles.MobileBalances>
                    <Styles.BalancesLabel>Wallet balance</Styles.BalancesLabel>
                    <Styles.Balances>
                      <Styles.BalanceTitle>
                        {`${Number(ethers.utils.formatEther(balance)).toFixed(
                          4
                        )}`}{" "}
                        <Styles.BNB>BNB</Styles.BNB>
                      </Styles.BalanceTitle>
                      <Styles.BalanceTitle>
                        {aiBalance} <Styles.AI>AI</Styles.AI>
                      </Styles.BalanceTitle>
                    </Styles.Balances>
                  </Styles.MobileBalances>
                ) : null}
                {isWalletConnected ? (
                  <Styles.DisconnectMobileButton onClick={reset}>
                    <Styles.DisconnectMobileButtonTitle>
                      Sign out
                    </Styles.DisconnectMobileButtonTitle>
                  </Styles.DisconnectMobileButton>
                ) : null}
              </Styles.WalletRow>

              <Styles.FrensBlockRow>
                <FrensBlock />
              </Styles.FrensBlockRow>
            </Styles.Collapse>
          </Styles.Nav>
        </Container>
      </Styles.Wrapper>
      <ConnectWalletModal
        isOpen={activeModal === "connectWallet"}
        onClose={() => setActiveModal(null)}
      />
    </>
  );
};

export default Navbar;
