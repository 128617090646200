import styled from "styled-components";

import background from "../../assets/other/statsBlock.png";
import spinner from "../../assets/spinner.svg";
import pepeArrow from "../../assets/other/pepeArrow.svg";
import pepeArrow2 from "../../assets/other/pepeArrow2.svg";

type TPepeArrowProps = {
  direction: "left" | "right";
};

const Wrapper = styled.main`
  background-color: #00130d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  border-top: 1px solid #ffffff;
  padding: 30px 0;
  flex: 1;
`;

const StatsBlock = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 208, 33, 0.13),
    rgba(0, 208, 33, 0.13)
  );
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 16px 18px 20px 18px;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 1024px) {
    padding: 30px 0 35px 0;
  }
`;

const StatsList = styled.div`
  &:not(:last-child) {
    margin: 0 18px 0 0;
  }

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

const StatsTitle = styled.h4`
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #ff5c5c;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 45px;
  }
`;

const StatsText = styled.p`
  margin: 8px 0 0 0;
  font-size: 15px;
  line-height: 19px;
  color: #d5ffca;
  text-shadow: 5px 5px 5px rgb(0 0 0 / 25%);
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 37px;
  }
`;

const CollectionSection = styled.div`
  margin: 40px 0 0 0;
`;

const CollectionTitle = styled.p`
  margin: 0;
  font-size: 25px;
  line-height: 31px;
  color: #fdfffe;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Spinner = styled.div`
  width: 22px;
  height: 22px;
  margin: 0 auto;
  background-image: url(${spinner});
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 1024px) {
    width: 45px;
    height: 45px;
  }
`;

const NeuralPepeCardRow = styled.div`
  display: block;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
  }
`;

const PepeArrow = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    background-image: ${({ direction }: TPepeArrowProps) =>
      `url(${direction === "left" ? pepeArrow : pepeArrow2})`};
    background-repeat: no-repeat;
    background-size: cover;
    width: 56px;
    height: 39px;
    position: absolute;
    left: ${({ direction }: TPepeArrowProps) =>
      direction === "left" ? "0" : "inherit"};
    right: ${({ direction }: TPepeArrowProps) =>
      direction === "right" ? "0" : "inherit"};
    transition: all 0.2s ease-in-out 0s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
`;

const Styles = {
  Wrapper,
  Row,
  StatsBlock,
  StatsList,
  StatsTitle,
  StatsText,
  CollectionSection,
  CollectionTitle,
  Spinner,
  NeuralPepeCardRow,
  PepeArrow,
};

export default Styles;
