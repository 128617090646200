import styled from "styled-components";

import filtersIcon from "../../assets/updates/filters.svg";
import telegramIcon from "../../assets/updates/telegram.svg";
import spinner from "../../assets/spinner.svg";

// Pepes
import pepe1 from "../../assets/other/updatepepes/1.png";
import pepe2 from "../../assets/other/updatepepes/2.png";
import pepe3 from "../../assets/other/updatepepes/3.png";
import pepe4 from "../../assets/other/updatepepes/4.png";
import pepe5 from "../../assets/other/updatepepes/5.png";
import pepe6 from "../../assets/other/updatepepes/6.png";
import pepe7 from "../../assets/other/updatepepes/7.png";
import pepe8 from "../../assets/other/updatepepes/8.png";
import pepe9 from "../../assets/other/updatepepes/9.png";
import pepe10 from "../../assets/other/updatepepes/10.png";
import pepe11 from "../../assets/other/updatepepes/11.png";

type TMobileFiltersProps = {
  isVisible: boolean;
};

type TCategoryCardProps = {
  isActive: boolean;
};

const Wrapper = styled.div`
  background-color: #00130d;
`;

const Main = styled.main`
  border-top: 1px solid #ffffff;
  position: relative;

  @media (min-width: 1024px) {
    padding: 0 0 50px 0;
  }
`;

const Heading = styled.div`
  padding: 40px 0;
  border-bottom: 1px solid #ffffff;

  @media (min-width: 1024px) {
    padding: 60px 0;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 38px;
  line-height: 43px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 59px;
    line-height: 43px;
  }
`;

const Group = styled.div``;

const GroupTitle = styled.h3`
  display: none;

  @media (min-width: 1024px) {
    margin: 0 0 34px 0;
    display: block;
    font-size: 34px;
    line-height: 43px;
    color: #d5ffca;
  }
`;

const UpdatesList = styled.div`
  @media (min-width: 1024px) {
    max-height: 900px;
    overflow: scroll;
  }
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FiltersIcon = styled.div`
  width: 49px;
  height: 49px;
  background-image: url(${filtersIcon});
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MobileFilters = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #505050;
  z-index: 9999;
  height: 100vh;
  top: 0;
  display: ${({ isVisible }: TMobileFiltersProps) =>
    isVisible ? "blockc" : "none"};
  padding: 0 24px 0 24px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MobileFiltersHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  position: relative;
`;

const MobileFiltersTitle = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  -webkit-text-stroke: 1px #000000;
`;

const MobileFiltersClose = styled.p`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #d5ffca;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  transform: rotate(-179.16deg);
  position: absolute;
  right: 0;

  &:hover {
    cursor: pointer;
  }
`;

const CategoryCard = styled.div`
  width: 100%;
  padding: 10px 0;
  background: ${({ isActive }: TCategoryCardProps) =>
    isActive
      ? `linear-gradient(0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2))`
      : "#002419"};
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: ${({ isActive }: TCategoryCardProps) =>
      isActive ? "default" : "pointer"};
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #002419;
  }

  @media (min-width: 1024px) {
    padding: 15px 0;

    &:not(:last-child) {
      margin: 0 0 20px 0;
    }
  }
`;

const Category = styled.p`
  margin: 0;
  font-size: 15px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

const TelegramBlock = styled.div`
  margin: 70px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TelegramBlockRow = styled.a`
  text-decoration: none;
  width: 170px;
  padding: 20px 15px 15px 15px;
  background: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #002419;
    border: 3px dashed rgba(255, 255, 255, 0.48);
  }
`;

const TelegramBlockIcon = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${telegramIcon});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20px;
`;

const TelegramBlockText = styled.p`
  margin: 25px 0 0 0;
  font-size: 15px;
  line-height: 20px;
  color: #d5ffca;
  text-align: center;
`;

const ShowMore = styled.p`
  font-size: 15px;
  line-height: 40px;
  color: #d5ffca;
  text-align: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

const Loader = styled.img`
  width: 40%;
  height: 40%;
`;

const MobileTgRow = styled.div`
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const DesktopFilters = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    margin: 30px 0 0 0;
  }
`;

const DesktopTgRow = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const ResetFilters = styled.p`
  margin: 0;
  font-size: 22px;
  text-align: center;
  line-height: 22px;
  color: #ff9595;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Spinner = styled.div`
  width: 22px;
  height: 22px;
  margin: 0 auto;
  background-image: url(${spinner});
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 1024px) {
    width: 45px;
    height: 45px;
    margin: 20px auto 0 auto;
  }
`;

const Pepe1 = styled.div`
  width: 26px;
  height: 26px;
  filter: blur(2px);
  position: absolute;
  top: 40px;
  left: 40px;
  background-image: url(${pepe1});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe2 = styled.div`
  width: 50px;
  height: 50px;
  filter: blur(2px);
  transform: matrix(-0.73, 0.68, 0.68, 0.73, 0, 0);
  position: absolute;
  top: 30px;
  left: 34%;
  background-image: url(${pepe2});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe3 = styled.div`
  width: 35px;
  height: 35px;
  filter: blur(2px);
  position: absolute;
  left: 45%;
  top: 90px;
  background-image: url(${pepe3});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe4 = styled.div`
  width: 50px;
  height: 50px;
  filter: blur(2px);
  position: absolute;
  left: 60%;
  top: 32px;
  background-image: url(${pepe4});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe5 = styled.div`
  width: 35px;
  height: 35px;
  filter: blur(2px);
  position: absolute;
  left: 73%;
  top: 100px;
  background-image: url(${pepe5});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe6 = styled.div`
  width: 25px;
  height: 25px;
  filter: blur(2px);
  position: absolute;
  left: 80%;
  top: 48px;
  background-image: url(${pepe6});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe7 = styled.div`
  width: 35px;
  height: 35px;
  filter: blur(2px);
  position: absolute;
  left: 90%;
  top: 54px;
  background-image: url(${pepe7});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Pepe8 = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${pepe8});
  background-repeat: no-repeat;
  background-size: contain;
  filter: blur(2px);
  position: absolute;
  top: 235px;
  right: 40px;
`;

const Pepe9 = styled.div`
  background-image: url(${pepe9});
  background-repeat: no-repeat;
  background-size: contain;
  filter: blur(2px);
  position: absolute;
  top: 66%;
  width: 35px;
  height: 35px;
  right: 440px;
`;

const Pepe10 = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${pepe10});
  background-repeat: no-repeat;
  background-size: contain;
  filter: blur(2px);
  position: absolute;
  right: 40px;
  top: 70%;
`;

const Pepe11 = styled.div`
  width: 35px;
  height: 35px;
  background-image: url(${pepe11});
  background-repeat: no-repeat;
  background-size: contain;
  filter: blur(2px);
  position: absolute;
  left: 20px;
  top: 60%;
`;

const Styles = {
  Wrapper,
  Main,
  Heading,
  Title,
  Group,
  GroupTitle,
  UpdatesList,
  HeadingRow,
  FiltersIcon,
  MobileFilters,
  MobileFiltersHeading,
  MobileFiltersTitle,
  MobileFiltersClose,
  CategoryCard,
  Category,
  TelegramBlock,
  TelegramBlockRow,
  TelegramBlockIcon,
  TelegramBlockText,
  ShowMore,
  Loader,
  MobileTgRow,
  DesktopFilters,
  DesktopTgRow,
  ResetFilters,
  Spinner,
  Pepe1,
  Pepe2,
  Pepe3,
  Pepe4,
  Pepe5,
  Pepe6,
  Pepe7,
  Pepe8,
  Pepe9,
  Pepe10,
  Pepe11,
};

export default Styles;
