import mediumLogo from "../assets/social/medium.svg";
import discordLogo from "../assets/social/discord.svg";
import telegramLogo from "../assets/social/telegram.svg";
import twitterLogo from "../assets/social/twitter.svg";

export interface ISocial {
  logo: string;
  url: string;
}

const socials: ISocial[] = [
  {
    logo: mediumLogo,
    url: "https://medium.com/@neuralpepe",
  },
  {
    logo: discordLogo,
    url: "https://discord.gg/XxgHsE2TD4",
  },
  {
    logo: telegramLogo,
    url: "https://t.me/neuralpepe",
  },
  {
    logo: twitterLogo,
    url: "https://twitter.com/NeuralPepe",
  },
];

export default socials;
