import * as React from "react";

// Styles
import { Wrapper } from "./styles";

interface Props {
  children: React.ReactNode;
}

const Container: React.FC<Props> = (props) => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default Container;
