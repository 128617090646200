import styled from "styled-components";

import noPepe from "../../assets/other/noPepe.png";
import spinner from "../../assets/spinner.svg";

import winIcon from "../../assets/status/win.png";
import loseIcon from "../../assets/status/lose.png";

type TChallengeTitleLineProps = {
  color: string;
};

type TPepesProgressProps = {
  width: number;
};

type TPepesProps = {
  isBroken?: boolean;
  isLoading: boolean;
};

const Wrapper = styled.div`
  background-color: #00130d;
`;

const Main = styled.main`
  padding: 15px 0 50px 0;

  @media (min-width: 1024px) {
    border-top: 1px solid #ffffff;
    padding: 50px 0 80px 0;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 38px;
  line-height: 43px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 59px;
    line-height: 43px;
    -webkit-text-stroke: 1px black;
  }
`;

const Description = styled.p`
  margin: 30px 0;
  font-size: 14px;
  line-height: 16px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const ChallengesList = styled.div`
  margin: 40px 0 0 0;

  @media (min-width: 1024px) {
    margin: 60px 0 0 0;
  }
`;

const ChallengeSection = styled.section`
  margin: 20px 0 0 0;
`;

const ChallengeTitleRow = styled.div``;

const ChallengeSectionTitle = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 43px;
  color: #d5ffca;
`;

const ChallengeTitleLine = styled.div`
  width: 144px;
  height: 4px;
  background-color: ${({ color }: TChallengeTitleLineProps) => color};
`;

const ActiveChallenge = styled.div`
  background-color: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  margin: 50px 0 70px 0;
`;

const ChallengeInfo = styled.div`
  padding: 20px 0 40px 0;
  position: relative;

  @media (min-width: 1024px) {
    padding: 40px 50px 20px 50px;
  }
`;

const ChallengeTitle = styled.p`
  font-size: 20px;
  line-height: 43px;
  text-align: center;
  color: #d5ffca;
  margin: 15px 0 0 0;
  text-align: center;

  @media (min-width: 1024px) {
    margin: 0;
    font-size: 36px;
    line-height: 43px;
  }
`;

const PepesRow = styled.div`
  padding: 30px;

  @media (min-width: 1024px) {
    padding: 30px 0 0 0;
  }
`;

const PepesBlock = styled.div`
  &:not(:last-child) {
    margin: 0 0 20px 0;
  }
`;

const PepesList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Pepes = styled.div`
  width: 18%;
  height: 0px;
  padding-bottom: calc(18% - 6px);
  border-radius: 20px;
  border: ${({ isBroken }: TPepesProps) =>
    `3px solid ${isBroken ? "#EC0000" : "#00ca08"}`};
  background: linear-gradient(0deg, #000d01, #000d01);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    display: none;
  }

  &:not(:last-child) {
    margin: 0 2% 0 0;
  }

  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  /* &:hover {
    cursor: ${({ isLoading }: TPepesProps) =>
      isLoading ? "default" : "pointer"};

    p {
      display: ${({ isLoading }: TPepesProps) =>
        isLoading ? "none" : "block"};
    }

    div,
    img {
      display: ${({ isLoading }: TPepesProps) =>
        isLoading ? "block" : "none"};
    }
  } */
`;

const PepesProgressRow = styled.div`
  width: 100%;
  height: 4px;
  background-color: #ec0000;
  width: ${({ width }: TPepesProgressProps) => `${width}%`};
  margin: 15px auto 0 auto;
`;

const PepesProgress = styled.div`
  width: ${({ width }: TPepesProgressProps) => `${width}%`};
  height: 4px;
  background-color: #39f585;
`;

const Hint = styled.p`
  margin: 0 30px 10px 30px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #d5ffca;

  @media (min-width: 1024px) {
    margin: 10px 0 0 0;
  }
`;

const Reward = styled.div`
  border-top: 3px dashed rgba(255, 255, 255, 0.48);
  border-radius: 15px;
  position: relative;
  padding: 40px 0 0 0;

  @media (min-width: 1024px) {
    border-top: none;
    border-left: 3px dashed rgba(255, 255, 255, 0.48);
    height: 100%;
    padding: 20px 0 0 0;
  }
`;

const RewardRow = styled.div`
  padding: 0 30px 30px 30px;
`;

const CountdownRow = styled.div`
  background: #4d0000;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  width: calc(100% - 40px);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -25px;
  display: flex;
  justify-content: center;

  span {
    font-size: 22px;
    line-height: 43px;
    text-align: center;
    color: #d5ffca;
  }

  @media (min-width: 1024px) {
    padding: 4px 50px;
    width: 300px;
    left: inherit;
    width: auto;
    right: 20px;

    span {
      font-size: 36px;
      line-height: 43px;
    }
  }
`;

const RewardTitle = styled.p`
  font-size: 29px;
  line-height: 43px;
  color: #66ff40;
  margin: 0;

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 43px;
  }
`;

const RewardImage = styled.img`
  width: 100%;
  height: 150px;
  margin: 20px 0 0 0;
  object-fit: contain;
  border: 3px solid #00ca08;
  border-radius: 20px;

  @media (min-width: 1024px) {
    margin: 0;
    width: 302px;
    height: 180px;
  }
`;

const RewardName = styled.p`
  margin: 30px 0 0 0;
  font-size: 25px;
  line-height: 30px;
  color: #d5ffca;
  text-align: center;
`;

const Author = styled.p`
  margin: 20px 0 0 0;
  font-size: 14px;
  line-height: 43px;
  color: #d5ffca;
  text-align: center;
`;

const DetailsButton = styled.a`
  width: 220px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const DetailsButtonTitle = styled.p`
  font-size: 22px;
  line-height: 43px;
  text-align: center;
  color: #d5ffca;
  margin: 0;
`;

const PepesImage = styled.img``;

const BrokenPepe = styled.div`
  width: 30px;
  height: 30px;
  background-image: url(${noPepe});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 55px 0 0 0;

  @media (min-width: 1024px) {
    width: 50px;
    height: 50px;
    margin: 80px 0 0 0;
  }
`;

const UpcomingChallenge = styled.div`
  padding: 25px;
  margin: 50px 0 0 0;
  background-color: #002419;
  border: 3px dashed rgba(255, 255, 255, 0.48);
  box-shadow: 5px 4px 4px rgb(0 0 0 / 50%);
  border-radius: 15px;
  position: relative;

  @media (min-width: 1024px) {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px 50px;
  }
`;

const UpcomingChallengeTitle = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 43px;
  text-align: center;
  color: #d5ffca;
  -webkit-text-stroke: 1px black;

  @media (min-width: 1024px) {
    flex: 1;
    font-size: 36px;
    line-height: 43px;
    text-align: left;
  }
`;

const UpcomingChallengeSoon = styled.p`
  margin: 15px 0 0 0;
  font-size: 24px;
  line-height: 43px;
  letter-spacing: 0.5em;
  color: #224a18;
  opacity: 0.59;
  text-align: center;
  -webkit-text-stroke: 2px black;

  @media (min-width: 1024px) {
    flex: 1;
    margin: 0;
    font-size: 36px;
    line-height: 43px;
  }
`;

const PepeLoading = styled.div`
  width: 30px;
  height: 30px;
  background-image: url(${spinner});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 50px 0 0 0;

  @media (min-width: 1024px) {
    width: 50px;
    height: 50px;
    margin: 80px 0 0 0;
  }
`;

const RewardInfo = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const RewardContent = styled.div`
  @media (min-width: 1024px) {
    margin: 0 30px 0 0;
  }
`;

const Loader = styled.img`
  width: 40%;
  height: 40%;
`;

const GetIt = styled.p`
  margin: 55px 0 0 0;
  font-size: 16px;
  line-height: 20px;
  color: #d5ffca;

  @media (min-width: 1024px) {
    margin: 80px 0 0 0;
    font-size: 20px;
    line-height: 43px;
  }
`;

const Link = styled.a`
  text-decoration: none; ;
`;

const Win = styled.div`
  width: 70px;
  height: 70px;
  background-image: url(${winIcon});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -14px;
  left: -35px;
`;

const Lose = styled(Win)`
  background-image: url(${loseIcon});
`;

const ValidPepes = styled.p`
  margin: 0;
  font-size: 22px;
  line-height: 43px;
  text-align: center;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 43px;
  }
`;

const Styles = {
  Wrapper,
  Main,
  Title,
  Description,
  ChallengesList,
  ChallengeSection,
  ChallengeTitleRow,
  ChallengeSectionTitle,
  ChallengeTitleLine,
  ActiveChallenge,
  ChallengeInfo,
  ChallengeTitle,
  PepesRow,
  PepesBlock,
  PepesList,
  Pepes,
  PepesProgressRow,
  PepesProgress,
  Hint,
  Reward,
  RewardRow,
  CountdownRow,
  RewardTitle,
  RewardImage,
  RewardName,
  Author,
  DetailsButton,
  DetailsButtonTitle,
  PepesImage,
  BrokenPepe,
  UpcomingChallenge,
  UpcomingChallengeTitle,
  UpcomingChallengeSoon,
  PepeLoading,
  RewardInfo,
  RewardContent,
  Loader,
  GetIt,
  Link,
  Win,
  Lose,
  ValidPepes,
};

export default Styles;
