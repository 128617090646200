import * as React from "react";

// Styles
import Styles from "./styles";

interface ITab {
  title: string;
}

interface Props {
  tabs: ITab[];
  activeTab: string;
  onSelectedTab: (tabTitle: string) => void;
}

const Tabs: React.FC<Props> = (props) => {
  const { tabs, activeTab, onSelectedTab } = props;

  const findActiveTab = tabs.find((tab: ITab) => tab.title === activeTab);

  const onPrev = (): void => {
    const getIndex = tabs.findIndex((tab: ITab) => tab.title === activeTab);

    if (getIndex === 0) {
      onSelectedTab(tabs[tabs.length - 1].title);
    } else {
      onSelectedTab(tabs[getIndex - 1].title);
    }
  };

  const onNext = (): void => {
    const getIndex = tabs.findIndex((tab: ITab) => tab.title === activeTab);

    if (tabs[getIndex + 1]?.title) {
      onSelectedTab(tabs[getIndex + 1].title);
    } else {
      onSelectedTab(tabs[0].title);
    }
  };

  return (
    <Styles.Container>
      <Styles.MobileRow>
        {findActiveTab ? (
          <Styles.MobileTab>
            <Styles.TabTitle>{findActiveTab.title}</Styles.TabTitle>
          </Styles.MobileTab>
        ) : null}
        <Styles.PrevArrow onClick={onPrev} />
        <Styles.NextArrow onClick={onNext} />
      </Styles.MobileRow>
      <Styles.DesktopRow>
        {tabs.map((tab: ITab) => {
          const { title } = tab;

          const isActive = activeTab === title;

          return (
            <Styles.Tab
              isActive={isActive}
              key={title}
              onClick={() => onSelectedTab(title)}
            >
              <Styles.TabTitle>{title}</Styles.TabTitle>
            </Styles.Tab>
          );
        })}
      </Styles.DesktopRow>
    </Styles.Container>
  );
};

export default Tabs;
