import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
		box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-family: 'Righteous', cursive;
  }

  .pagination {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    li {
      list-style: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:first-child {
        a {
          margin-left: 0;
          border-top-left-radius: .25rem;
          border-bottom-left-radius: .25rem;
        }
      }

      &:last-child {
        a {
          border-top-right-radius: .25rem;
          border-bottom-right-radius: .25rem;
        }
      }

      a {
        position: relative;
        display: block;
        padding: .5rem .75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: rgba(213,255,202,0.8);
        background-color: #1A2722;
        border: 1px solid #C4FCB6;

        &:hover {
          cursor: pointer;
          color: #000000;
          text-decoration: none;
          background-color: #C4FCB6;
          border-color: #dee2e6;
        }
      }

      &.active {
        a {
          color: #000000;
          text-decoration: none;
          background-color: #C4FCB6;
          border-color: #dee2e6;

          &:hover {
            cursor: default;
          }
        }
      }
    }
  }

  .react-responsive-modal-modal {
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: transparent;

    &.getPepeModal {
      max-width: 1200px;

      .getPepeModal_info {
        @media (min-width: 1024px) {
          order: 0;
          position: relative;
        }
      }

      .getPepeModal_avatar {
        @media (min-width: 1024px) {
          order: 1;
        }
      }

      .getPepeModal_form {
        @media (min-width: 1024px) {
          order: 2;
        }
      }
    }
  }
`;

export default GlobalStyle;
