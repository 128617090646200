import axios, { AxiosResponse } from "axios";

import { isTestNet } from "../blockchain/utils/index";

export const url = isTestNet
  ? "http://localhost:5000"
  : "https://neuralpepe.com/api";

export interface IPepeNamingHistory {
  name: string;
  is_minted: boolean;
  created: Date;
}

export type TTrait =
  | "format"
  | "background_color"
  | "color"
  | "eyes"
  | "iteration"
  | "kek"
  | "special";

export interface IFilter {
  trait_type: TTrait;
  value: string[];
}

export interface IPepeInfo {
  name: string;
  index: number;
  description: string;
  image: string;
  image_ipfs: string;
  attributes: IPepeAttribute[];
}

export interface IPepeAttribute {
  trait_type: string;
  value: string;
}

export interface IChallengeSection {
  type: "active" | "upcoming" | "ended";
  challenges: IChallenge[];
}

export interface IChallengeAttribute {
  reqValue: string;
  reqType: string;
}

export interface IChallenge {
  title: string;
  pepesAmount?: number;
  timer?: string;
  detailsLink?: string;
  rewardAuthor?: string;
  rewardName?: string;
  file?: {
    filename: string;
  };
  nftLink?: string;
  isWin?: boolean;
  attributes?: IChallengeAttribute[];
}

export interface IUpdate {
  pepeNumber: number;
  type: "generation" | "renaming" | "sales" | "listing";
  date: Date;
  value: string;
  tx: string;
}

interface IUpdateResponse {
  list: IUpdate[];
  total: number;
}

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

export const getPepeNamingHistory = async (
  index: number
): Promise<IPepeNamingHistory[]> => {
  try {
    const { data }: AxiosResponse = await axios({
      url: "https://neuralpepe.com/api/pepe/history",
      method: "GET",
      params: {
        index,
      },
    });

    return data.data.reverse();
  } catch {
    return [];
  }
};

export const setNewPepeName = async (
  index: number,
  name: string,
  transactionHash: string
): Promise<void | null> => {
  try {
    await axios({
      url: `${url}/pepe/update-name`,
      method: "POST",
      data: {
        index,
        name,
        transactionHash,
      },
    });
  } catch {
    return null;
  }
};

export const getPepeInfo = async (index: string | number) => {
  try {
    const { data } = await axios(`${url}/pepe/info/${index}`);
    return data.data;
  } catch {
    return null;
  }
};

export const getChallenges = async (
  address: string | null
): Promise<IChallengeSection[] | null> => {
  try {
    const { data } = await axios(`${url}/challenges`, {
      params: {
        address,
      },
    });

    const sortData = data.data.sort(
      (a: any, b: any) => b.isActive - a.isActive
    );

    return sortData;
  } catch {
    return null;
  }
};

export const getFilters = async (): Promise<IFilter[] | null> => {
  try {
    const { data } = await axios(`${url}/pepe/filters`);
    return data.data;
  } catch {
    return null;
  }
};

export interface IPepeParams {
  limit: number;
  offset: number;
  format?: string;
  background_color?: string;
  color?: string;
  eyes?: string;
  iteration?: string;
  kek?: string;
  special?: string;
  address?: string;
  isPrisoner?: boolean;
}

interface IGetPepesResponse {
  pepes: IPepeInfo[];
  total: number;
}

export const getPepes = async (
  params: IPepeParams
): Promise<IGetPepesResponse | null> => {
  try {
    const { data } = await axios(`${url}/pepe`, {
      params,
    });
    return data.data;
  } catch {
    return null;
  }
};

export const getPepeTraits = async (
  index: number
): Promise<IPepeInfo | null> => {
  try {
    const { data } = await axios(
      `https://neuralpepe.com/api/pepe/info/${index}`
    );

    return data.data;
  } catch {
    return null;
  }
};

export const getSupply = async (): Promise<number> => {
  try {
    const { data } = await axios(`${url}/pepe/supply`);
    return data.data;
  } catch {
    return 0;
  }
};

export const getNextPepe = async (
  current: string,
  params: Object
): Promise<number | null> => {
  try {
    const { data } = await axios(`${url}/pepe/next`, {
      params: {
        ...params,
        current,
      },
    });
    return data.data;
  } catch {
    return null;
  }
};

export const getPrevPepe = async (
  current: string,
  params: Object
): Promise<number | null> => {
  try {
    const { data } = await axios(`${url}/pepe/prev`, {
      params: {
        ...params,
        current,
      },
    });
    return data.data;
  } catch {
    return null;
  }
};

export const getUpdates = async (
  offset: number,
  type: string | null
): Promise<IUpdateResponse | null> => {
  try {
    await sleep(1000);

    const { data } = await axios(`${url}/updates`, {
      params: {
        offset,
        type,
      },
    });
    return data.data;
  } catch {
    return null;
  }
};

export type TStats = {
  liquidity: number;
  aiPrice: number;
  marketCap: number;
  supply: number;
};

export const getStats = async (): Promise<TStats | null> => {
  try {
    const { data } = await axios(`${url}/stats`);

    return data.data;
  } catch {
    return null;
  }
};

export const isPepePrisoner = async (tokenId: number): Promise<boolean> => {
  try {
    const { data } = await axios(`${url}/pepe/is-prisoner`, {
      params: {
        tokenId,
      },
    });

    return data.data;
  } catch {
    return false;
  }
};
