/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import * as React from "react";
import { Flex, Box } from "reflexbox";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useWallet } from "bscuw";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import Pepes from "../../components/Pepes";
import Filters from "../../components/Filters";
import Tabs from "../../components/Tabs";

// Hooks
import usePrevious from "../../hooks/usePrevious";

// Assets
import preloaderImage from "../../assets/gif/preloader.gif";

// Utils
import {
  getFilters,
  IFilter,
  IPepeInfo,
  getPepes,
  getSupply,
  IPepeParams,
} from "../../utils/api";

// Styles
import Styles from "./styles";

const initialFilters = {
  iteration: "",
  eyes: "",
  color: "",
  background_color: "",
  format: "",
  kek: "",
  special: "",
  limit: 40,
  offset: 0,
};

const tabs = [
  {
    title: "Neural Pepe",
  },
  {
    title: "SPECIAL",
  },
  {
    title: "H-NP",
  },
];

const ExploreAll: React.FC = () => {
  const [pepes, setPepes] = React.useState<IPepeInfo[]>([]);
  const [isPepesNotFound, setPepesNotFound] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<IFilter[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [supply, setSupply] = React.useState<number>(0);
  const [isCanLoadMore, setCanLoadMore] = React.useState<boolean>(true);
  const [filtersState, setFiltersState] =
    React.useState<IPepeParams>(initialFilters);
  const [total, setTotal] = React.useState<number>(0);
  const [activeTab, setActiveTab] = React.useState<string>("Neural Pepe");
  const [isPepeLoading, setPepeLoading] = React.useState<boolean>(false);
  const [isInit, setIsInit] = React.useState<boolean>(false);

  const { account } = useWallet();

  const prevCurrentPage = usePrevious(currentPage);

  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    checkLocation();
  }, [location.search]);

  React.useEffect(() => {
    getPepeByFilters();
  }, [filtersState]);

  React.useEffect(() => {
    onGetSupply();
    onGetFilters();
  }, []);

  React.useEffect(() => {
    if (prevCurrentPage && prevCurrentPage !== currentPage) {
      onPaginateChange();
    }
  }, [currentPage, prevCurrentPage]);

  const onPaginateChange = (): void => {
    const parseLocation = queryString.parse(location.search);

    const newLocation = queryString.stringify({
      ...parseLocation,
      page: currentPage,
    });

    history.push({
      pathname: "/explore-all",
      search: `?${newLocation}`,
    });
  };

  const checkLocation = async (): Promise<void> => {
    const parseLocation = queryString.parse(location.search);

    if (!Object.keys(parseLocation).length) {
      setFiltersState(initialFilters);
    } else {
      setFiltersState({
        ...initialFilters,
        ...parseLocation,
        offset: parseLocation.page ? (Number(parseLocation.page) - 1) * 40 : 0,
      });
    }
  };

  const getPepeByFilters = async () => {
    if (isPepesNotFound) {
      setPepesNotFound(false);
    }

    if (!isCanLoadMore) {
      setCanLoadMore(true);
    }

    if (isPepeLoading) {
      return;
    }

    if (!isInit) {
      setIsInit(true);
    }

    setPepeLoading(true);

    const parseLocation = queryString.parse(location.search);
    const filters = Object.assign(isInit ? filtersState : parseLocation);

    Object.keys(filters).forEach((item: string) => {
      // @ts-ignore-file
      if (filters[item] === "") {
        // @ts-ignore-file
        delete filters[item];
      }
    });

    const data = await getPepes(filters);

    setPepeLoading(false);

    if (data?.pepes.length) {
      setPepes(data.pepes);
      setTotal(data.total);
    } else {
      setPepesNotFound(true);
    }
  };

  const onGetFilters = async () => {
    const data = await getFilters();

    if (data) {
      setFilters(data);
    }
  };

  const onGetSupply = async () => {
    const data = await getSupply();
    setSupply(data);
  };

  const resetFilters = (): void => {
    if (activeTab !== "Neural Pepe") {
      setActiveTab("Neural Pepe");
    }

    setFiltersState(initialFilters);
    updateQuery("");
  };

  const updateQuery = (query: string) => {
    if (activeTab !== "Neural Pepe") {
      setActiveTab("Neural Pepe");
    }
    history.push({
      pathname: "/explore-all",
      search: query.length ? `?${query}` : "",
    });
  };

  const onShowMore = async () => {
    const newFilters = {
      ...filtersState,
      offset: pepes.length,
    };

    const data = await getPepes(newFilters);

    if (data?.pepes.length) {
      setPepes([...pepes, ...data.pepes]);
      setTotal(data.total);
    } else {
      setCanLoadMore(false);
    }
  };

  const onPageChange = ({ selected }: { selected: number }) => {
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
    }
  };

  const renderPepes = (
    <>
      {isPepesNotFound ? (
        <Styles.NotFound>
          No pepe found for the specified filters
        </Styles.NotFound>
      ) : null}
      {pepes.length > 0 && !isPepesNotFound ? (
        <Flex width="100%" flexWrap="wrap" pt={[40, 40, 0, 0]}>
          {pepes.map((pepeInfo: IPepeInfo) => {
            const { index, name } = pepeInfo;

            return (
              <Box
                key={`${name}/${index}`}
                width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]}
                pb={50}
                display="flex"
                justifyContent="center"
              >
                <Pepes width={131} height={131} number={index} />
              </Box>
            );
          })}
        </Flex>
      ) : null}
    </>
  );

  const renderSpecial = (
    <Styles.Tab>
      <Styles.TabText>
        will be added
        <br /> soon
      </Styles.TabText>

      <Styles.TabButton
        href="https://www.featured.market/nft/0xdaf916d1be04889f526d670586003ebedc8483d0"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Styles.TabButtonText>check on Featured</Styles.TabButtonText>
      </Styles.TabButton>
    </Styles.Tab>
  );

  const renderH = (
    <Styles.Tab>
      <Styles.TabText>
        will be
        <br /> available
        <br /> soon
      </Styles.TabText>
    </Styles.Tab>
  );

  return (
    <Styles.Wrapper>
      <Navbar />
      <Styles.Row>
        <Container>
          <Flex width="100%" flexWrap="wrap">
            <Box width={[1, 1, 1 / 3, 1 / 3]} pr={[0, 0, 54, 54]}>
              <Button label="My collection" link="/my-collection" />
              <Button label="Explore all" mt={30} isActive />

              {filters.length ? (
                <Filters
                  data={filters}
                  resetFilters={resetFilters}
                  filtersState={filtersState}
                  updateQuery={updateQuery}
                  address={account}
                />
              ) : null}
            </Box>
            <Box width={[1, 1, 2 / 3, 2 / 3]} pl={[0, 0, 54, 54]}>
              <Tabs
                tabs={tabs}
                onSelectedTab={setActiveTab}
                activeTab={activeTab}
              />
              {activeTab === "Neural Pepe" ? renderPepes : null}
              {activeTab === "SPECIAL" ? renderSpecial : null}
              {activeTab === "H-NP" ? renderH : null}

              {supply > 0 && !isPepesNotFound && activeTab === "Neural Pepe" ? (
                <>
                  {isCanLoadMore && pepes.length >= 40 ? (
                    <Styles.LoadMore onClick={onShowMore}>
                      Show more...
                    </Styles.LoadMore>
                  ) : null}
                  {total > 40 ? (
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      pageCount={total / 40}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={onPageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      disableInitialCallback
                      initialPage={
                        queryString.parse(location.search)?.page
                          ? Number(queryString.parse(location.search)?.page) - 1
                          : 0
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </Box>
            {!pepes.length ? (
              <Styles.Loader src={preloaderImage} alt="loader" />
            ) : null}
          </Flex>
        </Container>
      </Styles.Row>
      <Footer />
    </Styles.Wrapper>
  );
};

export default ExploreAll;
