import styled from "styled-components";
import { NavLink } from "react-router-dom";

import binanceLogo from "../../assets/logo/binance.svg";
import voiLogo from "../../assets/logo/voivoivoi.png";

const Wrapper = styled.footer`
  padding: 114px 0 25px 0;

  @media (min-width: 1024px) {
    padding: 44px 0 13px 0;
  }
`;

const NameRow = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Name = styled.h3`
  margin: 0;
  font-size: 40px;
  line-height: 43px;
  color: #d5ffca;
  text-shadow: 5px 5px 4px rgb(0 0 0 / 50%);
  z-index: 2;

  @media (min-width: 1024px) {
    font-size: 50px;
  }
`;

const BinanceLogo = styled.div`
  background-image: url(${binanceLogo});
  background-repeat: no-repeat;
  background-size: cover;
  width: 46px;
  height: 52px;
  position: absolute;
  right: 145px;
`;

const DividerLine = styled.div`
  margin: 19px auto 7px auto;
  width: 85%;
  height: 5px;
  background-color: #d5ffca;
  box-shadow: 5px 5px 4px rgb(0 0 0 / 50%);
`;

const Description = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: rgba(213, 255, 202, 0.8);
  text-shadow: 5px 5px 4px rgb(0 0 0 / 50%);
`;

const Nav = styled.div`
  margin: 30px 0 0 0;

  @media (min-width: 1024px) {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`;

const NavRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex: 1;

    &:nth-child(even) {
      align-items: flex-start;
    }
  }
`;

const Link = styled.a`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin: 0 0 14px 0;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: #c4fcb6;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }
`;

const RouterLink = styled(NavLink)`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin: 0 0 14px 0;
  text-decoration: none;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: #c4fcb6;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }

  &.active {
    color: #c4fcb6;
  }
`;

const Socials = styled.div`
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    margin: 0;
    justify-content: flex-end;
  }
`;

const SocialLink = styled.a`
  margin: 0 5.5px;
`;

const SocialLogo = styled.img``;

const VoiLogoRow = styled.div`
  margin: 63px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    margin: 10px 0 0 0;
  }
`;

const VoiLogo = styled.div`
  background-image: url(${voiLogo});
  background-repeat: no-repeat;
  background-size: cover;
  width: 161px;
  height: 24px;
`;

const Styles = {
  Wrapper,
  NameRow,
  Name,
  DividerLine,
  Description,
  BinanceLogo,
  Nav,
  NavRow,
  Link,
  Socials,
  SocialLink,
  SocialLogo,
  VoiLogoRow,
  VoiLogo,
  RouterLink,
};

export default Styles;
