import styled from "styled-components";

import logo from "../../assets/logo.png";
import frensArrowIcon from "../../assets/icons/frensArrow.png";

const Container = styled.a`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  border: 2px solid #20462a;
  border-radius: 15px;
  margin: 20px 0 0 0;
  padding: 12px;

  &:hover {
    border: 2px solid #ffffff;
  }
`;

const Logo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.p`
  margin: 0 0 0 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  flex: 1;
`;

const TextGreen = styled.span`
  color: #67d555;
`;

const Arrow = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${frensArrowIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Styles = {
  Container,
  Logo,
  Text,
  TextGreen,
  Arrow,
};

export default Styles;
