import styled from "styled-components";

const Wrapper = styled.section`
  margin: 55px 0 0 0;

  @media (min-width: 1024px) {
    margin: 80px 0 0 0;
  }
`;

const TitleRow = styled.div`
  position: relative;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #d5ffca;
  opacity: 0.88;

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 60px;
    opacity: 1;
    color: #9dea8a;
  }
`;

const List = styled.div`
  margin: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

const ListImage = styled.img``;

const ListText = styled.p`
  margin: 15px 0 0 0;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #d5ffca;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

const TitleBlur = styled(Title)`
  display: none;

  @media (min-width: 1024px) {
    font-size: 45px;
    line-height: 56px;
    letter-spacing: 0.2em;
    color: rgba(157, 234, 138, 0.4);
    opacity: 0.5;
    filter: blur(3px);
    display: block;
  }
`;

const Link = styled.a`
  color: #d5ffca;
`;

const ImageRow = styled.div`
  width: auto;
  height: 127px;
  display: flex;
  align-items: center;
`;

const Styles = {
  Wrapper,
  TitleRow,
  Title,
  List,
  ListImage,
  ListText,
  TitleBlur,
  Link,
  ImageRow,
};

export default Styles;
