import * as React from "react";

// Styles
import Styles from "./styles";

const FrensBlock: React.FC = () => {
  return (
    <Styles.Container
      href="https://neuralfrens.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Styles.Logo />
      <Styles.Text>
        Belongs to <Styles.TextGreen>NeuralFrens </Styles.TextGreen>
      </Styles.Text>
      <Styles.Arrow />
    </Styles.Container>
  );
};

export default FrensBlock;
