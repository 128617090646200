/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import queryString from "query-string";

// Utils
import { IFilter, TTrait, IPepeParams } from "../../utils/api";

// Styles
import Styles from "./styles";

interface IFiltersList {
    label: string;
    key: TTrait;
    value?: string;
    onChange: (e: React.FormEvent<HTMLSelectElement>) => void;
}

interface Props {
    data: IFilter[];
    resetFilters: () => void;
    filtersState: IPepeParams;
    updateQuery: (query: string) => void;
    address?: string | null;
}

const initialFilters = {
    iteration: "",
    eyes: "",
    color: "",
    background_color: "",
    format: "",
    kek: "",
    special: "",
    limit: 40,
    offset: 0,
    address: undefined,
    isPrisoner: false,
};

const Filters: React.FC<Props> = (props) => {
    const { data, resetFilters, filtersState, updateQuery, address } = props;

    const [filters, setFilters] = React.useState<IPepeParams>(filtersState);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (JSON.stringify(filtersState) !== JSON.stringify(filters)) {
            setFilters(filtersState);
        }
    }, [filtersState]);

    const updateState = (field: string, value: string | boolean) => {
        setFilters((prevState: IPepeParams) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const filtersList: IFiltersList[] = [
        {
            label: "Iteration",
            key: "iteration",
            value: filters.iteration,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("iteration", e.currentTarget.value),
        },
        {
            label: "# of eyes",
            key: "eyes",
            value: filters.eyes,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("eyes", e.currentTarget.value),
        },
        {
            label: "Color",
            key: "color",
            value: filters.color,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("color", e.currentTarget.value),
        },
        {
            label: "Background",
            key: "background_color",
            value: filtersState.background_color,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("background_color", e.currentTarget.value),
        },
        {
            label: "Format",
            key: "format",
            value: filters.format,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("format", e.currentTarget.value),
        },
        {
            label: "Kek",
            key: "kek",
            value: filters.kek,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("kek", e.currentTarget.value),
        },
        {
            label: "Special",
            key: "special",
            value: filters.special,
            onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                updateState("special", e.currentTarget.value),
        },
    ];

    const onApply = () => {
        Object.keys(filters).forEach((item: string) => {
            if (
                item === "limit" ||
                item === "offset" ||
                // @ts-ignore-file
                filters[item] === "-1" ||
                // @ts-ignore-file
                filters[item] === ""
            ) {
                // @ts-ignore-file
                delete filters[item];
            }

            if (item === "page") {
                // @ts-ignore-file
                filters.page = "1";
            }
        });
        const parseFilters = queryString.stringify(filters);
        updateQuery(parseFilters);
    };

    const toggleFilters = () => {
        setIsVisible(!isVisible);
    };

    const onReset = () => {
        setFilters(initialFilters);
        resetFilters();
    };

    const toggleCheckBox = (): void => {
        if (address) {
            updateState("address", address);
        }
    };

    const togglePrisoner = (): void => {
        updateState("isPrisoner", !filters.isPrisoner);
    };

    return (
        <Styles.Container>
            <Styles.MobileTitle>FILTERS</Styles.MobileTitle>
            <Styles.MobileAction onClick={toggleFilters}>
                {!isVisible ? "show" : "hide"} filters
            </Styles.MobileAction>
            <Styles.Row isVisible={isVisible}>
                {filtersList.map((listItem: IFiltersList) => {
                    const { label, value, onChange, key } = listItem;

                    return (
                        <Styles.Group key={key}>
                            <Styles.GroupLabel>{label}</Styles.GroupLabel>
                            <Styles.SelectRow>
                                <Styles.Select
                                    value={value}
                                    onChange={onChange}
                                >
                                    <option value="-1"></option>
                                    {data
                                        .find(
                                            (value: IFilter) =>
                                                value.trait_type === key
                                        )
                                        ?.value.map((item: string) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                </Styles.Select>
                            </Styles.SelectRow>
                        </Styles.Group>
                    );
                })}

                {address ? (
                    <Styles.OnlyMePepes>
                        <Styles.CheckBox onClick={toggleCheckBox}>
                            {filters.address?.length ? (
                                <Styles.CheckBoxX />
                            ) : null}
                        </Styles.CheckBox>
                        <Styles.OnlyMyPepesText>
                            Search through my NPs only
                        </Styles.OnlyMyPepesText>
                    </Styles.OnlyMePepes>
                ) : null}

                <Styles.OnlyMePepes>
                    <Styles.CheckBox onClick={togglePrisoner}>
                        {filters.isPrisoner ? <Styles.CheckBoxX /> : null}
                    </Styles.CheckBox>
                    <Styles.OnlyMyPepesText>
                        Show Jailed NPs only
                    </Styles.OnlyMyPepesText>
                </Styles.OnlyMePepes>

                <Styles.Button onClick={onApply}>
                    <Styles.ButtonLabel>Apply</Styles.ButtonLabel>
                </Styles.Button>

                {Object.values(filtersList).filter((i) => i).length ? (
                    <Styles.ResetAll onClick={onReset}>
                        reset all
                    </Styles.ResetAll>
                ) : null}
            </Styles.Row>
        </Styles.Container>
    );
};

export default Filters;
