import * as React from "react";
import { Flex, Box } from "reflexbox";

// Components
import Container from "../Container";

// Pepes
import pepe1 from "../../assets/pepes/13.svg";
import pepe2 from "../../assets/pepes/9.svg";

// Styles
import Styles from "./styles";

const WhatTheFuck: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Container>
        <Styles.TitleRow>
          <Styles.Title>WTF IS THIS?!</Styles.Title>
        </Styles.TitleRow>

        <Flex width="100%" flexWrap="wrap" pt={[40, 40, 90, 90]}>
          <Box
            width={[1, 1, 2 / 8, 2 / 8]}
            pr={[0, 0, 30, 30]}
            pb={[50, 50, 0, 0]}
          >
            <Styles.PepesBlock>
              <Styles.PepesImage src={pepe1} alt="pepe" />
              <Styles.PepesRow>
                <Styles.PepesTitle>ORIGINAL PEPE</Styles.PepesTitle>
                <Styles.PepesText>kool</Styles.PepesText>
              </Styles.PepesRow>
            </Styles.PepesBlock>
          </Box>
          <Box
            width={[1, 1, 1 / 8, 1 / 8]}
            pl={[0, 0, 30, 30]}
            pr={[0, 0, 30, 30]}
            pb={[50, 50, 0, 0]}
          >
            <Styles.ContentRow>
              <Styles.Arrow />
              <Styles.Label>
                AI <br /> MAGIC
              </Styles.Label>
            </Styles.ContentRow>
          </Box>
          <Box
            width={[1, 1, 2 / 8, 2 / 8]}
            pl={[0, 0, 30, 30]}
            pr={[0, 0, 30, 30]}
            pb={[50, 50, 0, 0]}
          >
            <Styles.PepesBlock>
              <Styles.PepesImageMirror src={pepe2} alt="pepe" />
              <Styles.PepesRow>
                <Styles.PepesTitle>NEURAL PEPE</Styles.PepesTitle>
                <Styles.PepesText>very kool</Styles.PepesText>
              </Styles.PepesRow>
            </Styles.PepesBlock>
          </Box>
          <Box
            width={[1, 1, 1 / 8, 1 / 8]}
            pl={[0, 0, 30, 30]}
            pr={[0, 0, 30, 30]}
          >
            <Styles.MagicBlock>
              <Styles.BinanceLogoDesktop />
              <Styles.Arrow2 />
              <Styles.MagicBlockRow>
                <Styles.BinanceLogo />
                <Styles.Label>
                  BLOCKCHAIN
                  <br /> MAGIC
                </Styles.Label>
              </Styles.MagicBlockRow>
            </Styles.MagicBlock>
          </Box>
          <Box width={[1, 1, 2 / 8, 2 / 8]} pl={[0, 0, 30, 30]}>
            <Styles.PepesBlock>
              <Styles.MagicPepeRow>
                <Styles.Magic />
                <Styles.PepesImageMirror src={pepe2} alt="pepe" />
                <Styles.MagicBlur>
                  <Styles.MagicBlurLabel>#18/7777</Styles.MagicBlurLabel>
                </Styles.MagicBlur>
              </Styles.MagicPepeRow>
              <Styles.PepesRow isHideOnMobile>
                <Styles.PepesTitle>NEURAL PEPE NFT</Styles.PepesTitle>
                <Styles.PepesText>incredible</Styles.PepesText>
              </Styles.PepesRow>
            </Styles.PepesBlock>
          </Box>
        </Flex>

        <Styles.Button
          href="https://docs.neuralpepe.com/how-it-works"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Styles.ButtonTitle>how does it work?</Styles.ButtonTitle>
        </Styles.Button>
      </Container>
    </Styles.Wrapper>
  );
};

export default WhatTheFuck;
