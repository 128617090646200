import styled from "styled-components";

import line1 from "../../assets/other/line1.png";
import line2 from "../../assets/other/line2.png";
import line3 from "../../assets/other/line3.png";
import line4 from "../../assets/other/line4.png";
import line5 from "../../assets/other/line5.png";

type TRoadmapBlockProps = {
  position: number;
};

const Wrapper = styled.section`
  margin: 63px 0 0 0;
`;

const TitleRow = styled.div`
  position: relative;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #9dea8a;
  text-transform: uppercase;
`;

const BlurTitle = styled(Title)`
  display: none;

  @media (min-width: 1024px) {
    position: absolute;
    font-size: 85px;
    line-height: 106px;
    letter-spacing: 0.1em;
    color: rgba(157, 234, 138, 0.4);
    filter: blur(8px);
    right: 100px;
  }
`;

const Roadmap = styled.div`
  display: none;

  @media (min-width: 1024px) {
    position: relative;
    display: block;
  }
`;

const getMargin = (position: number): string => {
  if (position === 1) {
    return "50px 0 0 0";
  }
  if (position === 2) {
    return "-220px 0 0 560px";
  }
  if (position === 3) {
    return "-50px 0px 0 auto";
  }
  if (position === 4) {
    return "-140px 580px 0 auto";
  }
  if (position === 6) {
    return "-80px auto auto 570px";
  }
  return "0";
};

const RoadmapBlock = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ position }: TRoadmapBlockProps) => getMargin(position)};
`;

const RoadmapDate = styled.h3`
  margin: 0;
  font-size: 96px;
  line-height: 119px;
  color: #d5ffca;
`;

const RoadmapText = styled.p`
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #d5ffca;
  margin: 10px 0 0 0;
`;

const Link = styled.a`
  margin: 10px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #d5ffca;
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Line1 = styled.div`
  background-image: url(${line1});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 288.9px;
  height: 129px;
  top: 20px;
  left: 250px;
`;

const Line2 = styled.div`
  background-image: url(${line2});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 261.6px;
  height: 181px;
  right: 100px;
  top: -10px;
`;

const Line3 = styled.div`
  background-image: url(${line3});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 335px;
  height: 210px;
  right: 250px;
  top: 190px;
`;

const Line4 = styled.div`
  background-image: url(${line4});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 237px;
  height: 238px;
  top: 190px;
  left: 130px;
`;

const Line5 = styled.div`
  background-image: url(${line5});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 295px;
  height: 115px;
  left: 250px;
  bottom: 60px;
`;

const Styles = {
  Wrapper,
  TitleRow,
  Title,
  BlurTitle,
  Roadmap,
  RoadmapBlock,
  RoadmapDate,
  RoadmapText,
  Link,
  Line1,
  Line2,
  Line3,
  Line4,
  Line5,
};

export default Styles;
