/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Flex, Box } from "reflexbox";
import { useWallet } from "bscuw";
import { useHistory } from "react-router-dom";

// Components
import Navbar from "../../components/Navbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import WhatTheFuck from "../../components/WhatTheFuck";
import WhatToDo from "../../components/WhatToDo";
import Roadmap from "../../components/Roadmap";
import WhatNext from "../../components/WhatNext";
import SomeSection from "../../components/SomeSection";
import RickPortal from "../../components/RickPortal";
import ListedOn from "../../components/ListedOn";
import StatsSection from "../../components/StatsSection";
import DistributionEnd from "../../components/DistributionEnd";

// Modals
import ConnectWalletModal from "../../modals/ConnectWallet";

// Pepes
import pepe5 from "../../assets/pepes/5.svg";
import pepe6 from "../../assets/pepes/6.svg";
import pepe7 from "../../assets/pepes/7.svg";

// Api
import { getStats, TStats } from "../../utils/api";

// Styles
import Styles from "./styles";

const MainPage: React.FC = () => {
  const [activeModal, setActiveModal] = React.useState<null | "connectWallet">(
    null
  );
  const [stats, setStats] = React.useState<TStats | null>(null);

  React.useEffect(() => {
    onGetStats();
  }, []);

  const onGetStats = async (): Promise<void> => {
    const data = await getStats();

    if (data) {
      setStats(data);
    }
  };

  return (
    <Styles.Wrapper>
      <Navbar />
      <Container>
        <Styles.Row>
          <Styles.DesktopPepes>
            <Styles.PepeOne />
            <Styles.PepeTwo />
            <Styles.PepeThree />
            <Styles.PepeFour />
            <Styles.Pepe14 />
            <Styles.Pepe15 />
            <Styles.Pepe16 />
            <Styles.Pepe17 />
            <Styles.Pepe18 />
            <Styles.Pepe19 />
            <Styles.Pepe20 />
            <Styles.Pepe21 />
            <Styles.Pepe22 />
            <Styles.Pepe23 />
            <Styles.Pepe24 />
            <Styles.Pepe25 />
            <Styles.Pepe26 />
            <Styles.Pepe27 />
            <Styles.Pepe28 />
            <Styles.Pepe29 />
            <Styles.Pepe30 />
            <Styles.Pepe31 />
          </Styles.DesktopPepes>
          <Flex pt={[0, 0, 80, 80]}>
            <Box width={1} display="flex" justifyContent="center">
              <Styles.Words>
                <Styles.NeuralWord />
                <Styles.PepeWord />
              </Styles.Words>
            </Box>
          </Flex>
          <Styles.Pepes>
            <Styles.PepeOne />
            <Styles.PepeTwo />
            <Styles.PepeThree />
            <Styles.PepeFour />
          </Styles.Pepes>
          <Styles.DescriptionBlock>
            <Styles.Description>
              The first AI-(de)generated collectibles on BSC. Only 7777 neural
              pepes will ever exist.
            </Styles.Description>
          </Styles.DescriptionBlock>

          <Styles.AINFT />

          <Styles.MorePepes>
            <Styles.MorePepeImage src={pepe5} alt="pepe" />
            <Styles.MorePepeImage src={pepe6} alt="pepe" />
            <Styles.MorePepeImage src={pepe7} alt="pepe" />
          </Styles.MorePepes>

          <DistributionEnd />

          <Styles.Seriously>
            <Styles.SeriouslyPepe />
            <Styles.SeriouslyLabel>-Jail, seriously?</Styles.SeriouslyLabel>
          </Styles.Seriously>
        </Styles.Row>
      </Container>
      <WhatTheFuck />
      <WhatToDo />
      <Roadmap />
      <WhatNext />
      <SomeSection />
      {stats ? <StatsSection stats={stats} /> : null}
      <RickPortal />
      <ListedOn />
      <Footer />
      <ConnectWalletModal
        isOpen={activeModal === "connectWallet"}
        onClose={() => setActiveModal(null)}
      />
    </Styles.Wrapper>
  );
};

export default MainPage;
