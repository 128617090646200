import styled from "styled-components";

const Wrapper = styled.section`
  padding: 50px 0 0 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 31px;
  line-height: 38px;
  text-align: center;
  color: #9dea8a;
`;

const Link = styled.a``;

const LogoRow = styled.div`
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
`;

const Logo = styled.img``;

const Styles = {
  Wrapper,
  Title,
  Link,
  LogoRow,
  Logo,
};

export default Styles;
