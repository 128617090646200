import * as React from "react";

import preloaderImage from "../../assets/gif/preloader.gif";

// Styles
import Styles from "./styles";

interface Props {
  children: React.ReactElement<any, any> | null;
}

const Preloader: React.FC<Props> = (props) => {
  const { children } = props;

  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    document.body.style.overflow = "hidden";

    setTimeout(() => {
      document.body.style.overflow = "scroll";
      setIsLoaded(true);
    }, 2000);
  }, []);

  return (
    <Styles.Wrapper>
      <Styles.Preloader isVisible={!isLoaded}>
        <Styles.Title>NEURAL PEPE</Styles.Title>
        <Styles.Loader src={preloaderImage} alt="preloader" />
      </Styles.Preloader>

      {children}
    </Styles.Wrapper>
  );
};

export default Preloader;
