import styled from "styled-components";

import prevArrow from "../../assets/tabs/arrow.svg";
import nextArrow from "../../assets/tabs/arrow2.svg";

type TTabProps = {
  isActive: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
`;

const Tab = styled.div`
  flex: 1;
  border-bottom: ${({ isActive }: TTabProps) =>
    `4px solid ${isActive ? "#37f585" : "#10502d"}`};

  &:hover {
    cursor: ${({ isActive }: TTabProps) => (isActive ? "default" : "pointer")};
    border-bottom: 4px solid #37f585;
  }
`;

const TabTitle = styled.p`
  margin: 0;
  text-align: center;
  color: #d5ffca;
  font-size: 20px;
  line-height: 25px;

  @media (min-width: 1024px) {
    font-size: 27px;
    line-height: 43px;
  }
`;

const DesktopRow = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    width: 100%;
  }
`;

const MobileRow = styled.div`
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MobileTab = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;

  &:after {
    content: "";
    width: 135px;
    height: 3px;
    position: absolute;
    background-color: #d5ffca;
    bottom: -14px;
    box-shadow: 0px 2px 5px rgba(235, 255, 0, 0.74);
  }
`;

const PrevArrow = styled.div`
  width: 46px;
  height: 33px;
  background-image: url(${prevArrow});
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(176.99deg);
  position: absolute;
  left: 15px;

  &:hover {
    cursor: pointer;
  }
`;

const NextArrow = styled.div`
  width: 46px;
  height: 33px;
  background-image: url(${nextArrow});
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(-6.31deg);
  position: absolute;
  right: 15px;

  &:hover {
    cursor: pointer;
  }
`;

const Styles = {
  Container,
  Tab,
  TabTitle,
  DesktopRow,
  MobileRow,
  MobileTab,
  PrevArrow,
  NextArrow,
};

export default Styles;
